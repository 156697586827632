import Sidebar from "../../components/SidebarNew/Sidebar";
import Navbar from "../../components/NavbarProfile/NavbarProfile";
import PRedit from "../../components/PRmanagerLayout/PRedit";
import "../PRmanager/PR.css";

export default function PRupdate() {
  const activeSide = 2;
  return (
    <>
      <div className="row gx-0 pr_manager_layout">
        <Sidebar activeSide={activeSide} />
        <div className={`main col`}>
          <Navbar name={"PR Manager"} />
          <PRedit />
        </div>
      </div>
    </>
  );
}
