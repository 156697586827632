import axios, { AxiosError, AxiosResponse } from "axios";
import { showToast } from './index'
// import { errorMessage } from "./Constants";

const Instance = axios.create({
    baseURL: "https://pr.webandapi.com/",
    timeout: "10000"
});

Instance.interceptors.request.use(
    config => {
        console.log("Request Interceptors", config)
        config.headers['Content-Type'] = 'application/json';
        return config;
    },
    (error) => {
        console.log("Error", error);
        return Promise.reject(error);
    }
)
Instance.interceptors.request.use(
    (response) => {
        console.log("Respone", response);
        return response;
    },
    (error) => {
        console.log("Error", error);
        return Promise.reject(error);
    }
)

Instance.interceptors.response.use(
    async function (response: AxiosResponse) {
        console.log(response);
        // alert('hi');
        const data: any = response;
        const statusCode = response?.status;
        const detail = data?.status?.detail;
        console.log('data-data -')
        console.log(data.data)

        if (data.data.resCode == "100") {
            showToast(data.data.resMessage, "error", "error")
            return Promise.reject(response.data);
        } else {
            return data;
        }

        // if (
        //     (statusCode === 401) ||
        //     (statusCode === 403)
        // ) {
        //     //  removeAllItemFromStore();
        //     window.location.reload();
        // }

        // else if (statusCode === 403) {
        //     if (detail) {
        //         for (var key in detail) {
        //             if (detail.hasOwnProperty(key)) {
        //                 if (!detail[0]) {
        //                     showToast(detail[key][0], "error", detail[key][0]);
        //                 } else {
        //                     showToast(detail[0], "error", detail[0]);
        //                 }
        //             }
        //         }
        //     } else {
        //         const message = data?.status?.message;
        //         showToast(message, "error", message);
        //     }
        // }
    },
    async function (error: AxiosError) {
        const response: any = error?.response;
        if (response) {
            const errorData = response?.data?.errors;
            const status: any = response?.status;
            if (
                (status === 401) ||
                (status === 403)
            ) {
                //removeAllItemFromStore();
                window.location.reload();
            } else if (status === 400) {
                if (errorData) {
                    for (const key in errorData) {
                        if (errorData.hasOwnProperty(key)) {
                            if (!errorData[0]) {
                                showToast(errorData[key][0], "error", errorData[key][0]);
                            } else {
                                showToast(errorData[0], "error", errorData[0]);
                            }
                        }
                    }
                }
            } else if (status >= 500) {
                showToast(
                    "Server Error. Please try after some time.",
                    "error",
                    "Server Error. Please try after some time."
                );
            }
        }
        return Promise.reject(error);
    }
);
export default Instance;