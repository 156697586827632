import Sidebar from "../../components/SidebarNew/Sidebar";
import Navbar from "../../components/NavbarProfile/NavbarProfile";
import PRlayout from "../../components/PRmanagerLayout/PRlayout";
import PRpreview from "../../components/PRmanagerLayout/PRpreview";
import "./SubmitPr.css";
import { useState } from "react";

export default function SubmitPR() {
  const [showPRpreview, setShowPRpreview] = useState(true);

  const handleShowMyProfile = () => {
    setShowPRpreview(true);
  };

  const handleShowPRLayout = () => {
    setShowPRpreview(false);
  };

  const activeSide = 2;
  return (
    <>
      <div className="row gx-0 submit_pr_layout">
        <Sidebar activeSide={activeSide} />
        <div className={`main col`}>
          {showPRpreview ? (
            <Navbar name={"Press release"} />
          ) : (
            <Navbar name={"PR Manager"} />
          )}
          {showPRpreview ? (
            <PRpreview onShowPRLayout={handleShowPRLayout} />
          ) : (
            <PRlayout onShowMyProfile={handleShowMyProfile} />
          )}
        </div>
      </div>
    </>
  );
}
