import PricingHeader from "../../components/Layouts/PricingHeader";
import Header from "../../components/Layouts/Header";
import PaymentMethodPage from "../../components/BuyCredit/PaymentMethod";
import BottomBar from "../../components/Layouts/BottomBar";
import PricingFooter from "../../components/Layouts/PricingFooter";
import "../CreditPayment/Credit.css";

const PaymentMethod = () => {
  return (
    <>
      <div className="billing_page_layout">
        <Header />
        <PaymentMethodPage />
        <BottomBar />
        <PricingFooter />
      </div>
    </>
  );
};

export default PaymentMethod;
