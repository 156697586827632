import "./SupportLayout.css";
import { useState, useEffect, useRef } from "react";
import {
  Table,
  Button,
  Pagination,
  Dropdown,
  Modal,
  Accordion,
  Offcanvas,
  Form,
} from "react-bootstrap";
import { DateRange, DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import Instance from "../../Utils/Axios";
import { showToast } from "../../Utils/index";
import { Link } from "react-router-dom";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

export default function SupportLayout() {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [filtersApplied, setFiltersApplied] = useState(0);
  const [packagesApplied, setPackagesApplied] = useState(0);
  const [readApplied, setReadApplied] = useState(0);
  const [show, setShow] = useState(false);
  const [filters, setFilters] = useState({
    status: "",
    createdOn: "",
  });
  const [showResolve, setshowResolve] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [ticketDetail, setTicketDetail] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [chatContent, setChatContent] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const chatContainerRef = useRef(null);
  const [isRotated, setIsRotated] = useState(false);
  const [sortDirection, setSortDirection] = useState("asc");
  const [selectionRange, setSelectionRange] = useState({
    startDate: null,
    endDate: null,
    key: "selection",
  });

  const handleSelect = (ranges) => {
    setSelectionRange(ranges.selection);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // const handleDateChange = (date) => {
  //   setSelectedDate(date);
  // };

  const fetchTickets = async () => {
    try {
      const token = localStorage.getItem("tokenId");
      const response = await Instance.post("user/support/getList", {
        tokenId: token,
      });
      if (response.data.resCode === "200") {
        setTickets(response.data.message.tickets);
      } else {
        console.error("Failed to fetch tickets:", response.data.resMessage);
      }
    } catch (error) {
      console.error("Error fetching tickets:", error);
    }
  };

  const fetchDetails = (ticket) => {
    const token = localStorage.getItem("tokenId");

    Instance.post("/user/support/details", {
      tokenId: token,
      supportId: ticket?.supportId,
    })
      .then((response) => {
        setTicketDetail(response.data.message);
        console.log("ticket detail", response.data.message);
        setChatContent(response.data.message.chat || []);
      })
      .catch((error) => {
        console.error("error fetch ticket detail", error);
      });
  };

  useEffect(() => {
    fetchTickets();
  }, []);

  const handleResolve = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("tokenId");
      const response = await Instance.post(`/customer/support/update`, {
        tokenId: token,
        supportId: selectedTicket.supportId,
        status: "Resolved",
      });
      if (response.data.resCode === "200") {
        showToast("Ticket Resolved!", "success", "success");
        setshowResolve(false);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        console.log(response.data.resMessage);
      }
    } catch (error) {
      console.error("Error updating the ticket status:", error);
    }
  };

  const handleSendMessage = () => {
    if (inputValue.trim() === "") return;

    if (!selectedTicket || !selectedTicket.supportId) {
      console.error("No ticket ID selected");
      showToast("Please Select a Ticket !", "error");
      return;
    }
    const token = localStorage.getItem("tokenId");
    Instance.post("/user/support/message", {
      tokenId: token,
      supportId: selectedTicket.supportId,
      comment: inputValue,
    })
      .then((response) => {
        if (response.data.resCode === "200") {
          setChatContent((prevMessages) => [
            ...prevMessages,
            { id: response.data.id, comment: inputValue },
          ]);
          setInputValue("");
          console.log(response.data.resMessage);
        } else {
          console.error(response.data.resMessage);
        }
      })
      .catch((error) => {
        console.error("Error sending the message:", error);
      });
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handlePackageChange = (newPackage, isChecked) => {
    setSelectedPackages(isChecked ? [newPackage] : []);
  };

  const calculateFiltersApplied = () => {
    let count = 0;
    if (selectionRange) count++;
    count += selectedPackages.length;
    setFiltersApplied(count);
  };
  const calculatePackagesApplied = () => {
    setPackagesApplied(selectedPackages.length);
  };
  const handleApplyFilters = async () => {
    calculateFiltersApplied();
    calculatePackagesApplied();
    handleClose();

    const token = localStorage.getItem("tokenId");

    const filterData = {
      tokenId: token,
      createdOn:
        selectionRange.startDate && selectionRange.endDate
          ? {
            startDate: selectionRange.startDate.toISOString().split("T")[0],
            endDate: selectionRange.endDate.toISOString().split("T")[0],
          }
          : null,
      status: selectedPackages.length ? selectedPackages[0] : "",
    };

    try {
      const response = await Instance.post("user/support/getList", filterData);
      if (response.data.resCode === "200") {
        const filteredTickets = response.data.message.tickets.filter(
          (ticket) => {
            if (filterData.createdOn) {
              const ticketDate = ticket.createdOn.split(" ")[0];
              return (
                ticketDate >= filterData.createdOn.startDate &&
                ticketDate <= filterData.createdOn.endDate
              );
            }
            return true;
          }
        );
        setTickets(filteredTickets);
      } else {
        console.error(
          "Failed to fetch filtered tickets:",
          response.data.resMessage
        );
      }
    } catch (error) {
      console.error("Error fetching filtered tickets:", error);
    }
  };

  const handleClearFilters = async () => {
    setFiltersApplied(0);
    setSelectionRange({
      startDate: null,
      endDate: null,
      key: "selection",
    });
    setSelectedPackages([]);
    setPackagesApplied(0);

    fetchTickets();
  };

  const handleTicketSelect = (ticket) => {
    fetchDetails(ticket);
    setSelectedTicket(ticket);
  };

  useEffect(() => {
    if (tickets.length > 0) {
      fetchDetails(tickets[0]);
      setSelectedTicket(tickets[0]);
    }
  }, [tickets]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [chatContent]);

  const handleShowResolve = () => {
    setshowResolve(true);
  };
  const handleNotShowResolve = () => {
    setshowResolve(false);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleRowsPerPageChange = (rows) => {
    setRowsPerPage(rows);
    setCurrentPage(1);
  };

  const indexOfLastTicket = currentPage * rowsPerPage;
  const indexOfFirstTicket = indexOfLastTicket - rowsPerPage;
  const currentTickets = Array.isArray(tickets)
    ? tickets.slice(indexOfFirstTicket, indexOfLastTicket)
    : [];

  const totalPages = Math.ceil((tickets?.length || 0) / rowsPerPage);

  const handleSort = (column) => {
    const sortedTickets = [...tickets].sort((a, b) => {
      if (sortDirection === "asc") {
        return a[column] > b[column] ? 1 : -1;
      } else {
        return a[column] < b[column] ? 1 : -1;
      }
    });
    setTickets(sortedTickets);
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setIsRotated(!isRotated);
  };

  // const sortTickets = (column) => {
  //   let sortedTickets = [...tickets];
  //   if (sortColumn === column) {
  //     // Toggle sort direction if the same column is clicked
  //     setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  //   } else {
  //     // Set to ascending if a new column is clicked
  //     setSortColumn(column);
  //     setSortDirection("asc");
  //   }

  //   sortedTickets.sort((a, b) => {
  //     if (sortDirection === "asc") {
  //       return a[column] > b[column] ? 1 : -1;
  //     } else {
  //       return a[column] < b[column] ? 1 : -1;
  //     }
  //   });

  //   setTickets(sortedTickets);
  // };

  return (
    <>
      <div className="support_layout">
        <div className="ticket_list">
          <div className="ticket_list_btns">
            <Link to="/customer/support/add">
              <button className="add_new_query_btn">
                <img
                  className="me-2"
                  src="/build/img/Icons/Vector.png"
                  alt="zex pr"
                  height={15}
                  width={15}
                />
                Add New Query
              </button>
            </Link>
            <button className="Support_filter_btn" onClick={handleShow}>
              Filter
              <img
                className="filter_img"
                src="/build/img/Icons/down_vector.svg"
                alt="zex pr wire Logo"
                width={16}
                height={16}
                priority
              />
            </button>
            <Offcanvas show={show} onHide={handleClose}>
              <Offcanvas.Header closeButton className="filter_offcanvas_header">
                <Offcanvas.Title className="filter_offcanvas_title">
                  {filtersApplied} filters applied
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className="filter_offcanavas_body">
                <Accordion alwaysOpen flush>
                  <Accordion.Item eventKey="0" className="accordian_item">
                    <Accordion.Header className="filter_accordian">
                      Date Range
                    </Accordion.Header>
                    <Accordion.Body style={{ backgroundColor: "#FAFBFB" }}>
                      <div>
                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateCalendar
                            value={selectedDate}
                            onChange={handleDateChange}
                          />
                        </LocalizationProvider> */}

                        <DateRangePicker
                          ranges={[selectionRange]}
                          onChange={handleSelect}
                          months={1}
                          direction="horizontal"
                          value={filters.createdOn}
                        />
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1" className="accordian_item">
                    <Accordion.Header className="filter_accordian ">
                      <span>Status</span>
                      <span className="filter_accordian_text">
                        {packagesApplied} selected{" "}
                      </span>
                    </Accordion.Header>
                    <Accordion.Body
                      style={{ backgroundColor: "#FAFBFB" }}
                      className="filter_package_body pb-3"
                    >
                      <Form
                        value={filters.status}
                        onChange={handleFilterChange}
                      >
                        {/* {["radio"].map((type) => ( */}
                        <div key={`inline-radio`} className="">
                          <Form.Check
                            label="All"
                            name="status"
                            type="radio"
                            id={`default-radio-1`}
                            checked={selectedPackages.includes("")}
                            onChange={(e) =>
                              handlePackageChange("", e.target.checked)
                            }
                          />
                          <Form.Check
                            label="Open"
                            name="status"
                            type="radio"
                            id={`default-radio-2`}
                            checked={selectedPackages.includes("Open")}
                            onChange={(e) =>
                              handlePackageChange("Open", e.target.checked)
                            }
                          />
                          <Form.Check
                            label="Closed"
                            name="status"
                            type="radio"
                            id={`default-radio-3`}
                            checked={selectedPackages.includes("Resolved")}
                            onChange={(e) =>
                              handlePackageChange("Resolved", e.target.checked)
                            }
                          />
                        </div>
                        {/* ))} */}
                      </Form>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <div className="mt-4 mb-4 filter_btns">
                  <button
                    className="filter_clear_btn"
                    onClick={handleClearFilters}
                  >
                    Clear
                  </button>
                  <button
                    className="filter_apply_btn"
                    onClick={handleApplyFilters}
                  >
                    Apply
                  </button>
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          </div>
          <div className="ticket_list_table">
            <Table hover className="mb-0">
              <thead className="support_tables_head">
                <tr className="support_tr">
                  <th></th>
                  <th onClick={handleSort} style={{ cursor: "pointer" }}>
                    TICKET NO
                    <img
                      className={`mb-0 sort-icon ${isRotated ? "rotated" : ""}`}
                      src="/build/img/down_arrow.svg"
                      alt="zex pr wire Logo"
                      width={26}
                      height={18}
                    />
                  </th>
                  <th>
                    QUERY SUB
                    {/* <img
                      className={"mb-0"}
                      src="/build/img/down_arrow.svg"
                      alt="zex pr wire Logo"
                      width={26}
                      height={18}
                      priority
                    /> */}
                  </th>
                  <th>TICKET RAISED ON</th>
                  <th>
                    STATUS
                    {/* <img
                      className={"mb-0"}
                      src="/build/img/down_arrow.svg"
                      alt="zex pr wire Logo"
                      width={26}
                      height={18}
                      priority
                    /> */}
                  </th>
                </tr>
              </thead>
              <tbody className="support_table_body">
                {currentTickets.length === 0 ? (
                  <tr>
                    <td colSpan={5} className="text-center">
                      NO DATA AVAILABLE
                    </td>
                  </tr>
                ) : (
                  currentTickets.map((ticket, index) => (
                    <tr
                      key={ticket.supportId}
                      onClick={() => handleTicketSelect(ticket)}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <td>
                        <input
                          type="checkbox"
                          className="ticket_checkbox"
                          checked={
                            selectedTicket?.supportId === ticket.supportId
                          }
                          onChange={() => handleTicketSelect(ticket)}
                        />
                      </td>
                      <td>{ticket.supportId}</td>
                      <td>{ticket.subject}</td>
                      <td>{ticket.createdOn}</td>
                      <td>
                        {" "}
                        <div
                          className={
                            ticket.status === "OPEN"
                              ? "status_open"
                              : "status_close"
                          }
                        >
                          {ticket.status}
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
              <caption>
                <div className="support_table_footer">
                  <div className="support_page_no">
                    <h4>Rows per page</h4>
                    <Dropdown className="pr_rows_dropdown">
                      <Dropdown.Toggle variant="light" id="dropdown-basic">
                        {rowsPerPage}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {[5, 10, 15, 20].map((rows) => (
                          <Dropdown.Item
                            key={rows}
                            onClick={() => handleRowsPerPageChange(rows)}
                          >
                            {rows}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="support_pagination">
                    <Pagination className="mb-0">
                      <Pagination.First onClick={() => handlePageChange(1)} />
                      <Pagination.Prev
                        onClick={() =>
                          handlePageChange(Math.max(1, currentPage - 1))
                        }
                      />
                      {[...Array(totalPages).keys()].map((page) => (
                        <Pagination.Item
                          key={page + 1}
                          active={page + 1 === currentPage}
                          onClick={() => handlePageChange(page + 1)}
                        >
                          {page + 1}
                        </Pagination.Item>
                      ))}
                      <Pagination.Next
                        onClick={() =>
                          handlePageChange(
                            Math.min(totalPages, currentPage + 1)
                          )
                        }
                      />
                      <Pagination.Last
                        onClick={() => handlePageChange(totalPages)}
                      />
                    </Pagination>
                  </div>
                  {/* <div className="support_pages">
                    <h4>Go to page</h4>
                    <img
                      className={"mb-0 payment_select_img"}
                      src="/build/img/Select.svg"
                      alt="zex pr wire Logo"
                      width={50}
                      height={32}
                      priority
                    />
                  </div> */}
                </div>
              </caption>
            </Table>
          </div>
        </div>
        <div className="ticket_chatbox">
          <div className="chatbox_title mb-2">
            <h4>Ticket No: {ticketDetail?.supportId}</h4>
            {ticketDetail?.status === "OPEN" ? (
              <p onClick={handleShowResolve} style={{ cursor: "pointer" }}>
                Resolve
              </p>
            ) : (
              ""
            )}
            <Modal
              show={showResolve}
              onHide={handleNotShowResolve}
              centered
              className="custom_modal"
            >
              <Modal.Body>
                <div className="submit_modal_img">
                  <img
                    className={"submit_done_img"}
                    src="/build/Done.svg"
                    alt="zex pr wire Logo"
                    width={140}
                    height={140}
                    priority
                  />
                </div>
                <div>
                  <h5 className="submit_modal_head">Resolve Ticket</h5>
                  <p className="submit_modal_content">
                    Are your sure you want to resolve this ticket?
                  </p>
                </div>
                <div className="pr_modal_btn">
                  <Button
                    className="submit_cancel_btn"
                    variant="secondary"
                    onClick={handleNotShowResolve}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="submit_save_btn"
                    variant="primary"
                    onClick={handleResolve}
                  >
                    Resolve
                  </Button>
                </div>
              </Modal.Body>
            </Modal>
          </div>
          <p className="mb-2 ticket_date">{ticketDetail?.createdOn}</p>
          <p className="mb-3 ticket_description">{ticketDetail?.subject}</p>
          <div className="chatbox_chat">
            <div className="chat_content" ref={chatContainerRef}>
              {/* <p className="admin_chat">Hello, How can we help you?</p> */}
              {/* <p className="user_chat">
                Yo Reddit! What’s a small thing that anyone can do at nearly
                anytime to improve their mood and make them happier?
              </p>
              <p className="user_chat">
                The More Important the Work, the More Important the Rest
              </p>
              <p className="admin_chat">
                How to design a product that can grow itself 10x in year:
                <p>
                  Understanding color theory: the color wheel and finding
                  complementary colors
                </p>
              </p>
              <p className="user_chat">
                Any mechanical keyboard enthusiasts in design?
              </p>
              <p className="admin_chat">
                How to design a product that can grow itself 10x in year:
              </p>
              <p className="user_chat">
                Yo Reddit! What’s a small thing that anyone can do at nearly
                anytime to improve their mood and make them happier?
              </p> */}
              {chatContent.map((message) => (
                <p
                  className={
                    message.userType === "Admin" ? "admin_chat" : "user_chat"
                  }
                  key={message.chatId}
                >
                  {message.comment}
                </p>
              ))}
            </div>
          </div>
          {selectedTicket?.status === "OPEN" ? (
            <div className="send_messages mt-3">
              <div className="support_chat_add">
                <img
                  className={"support_attach_img"}
                  src="/build/img/Icons/Paperclip.svg"
                  alt="zex pr wire Logo"
                  width={24}
                  height={24}
                  priority
                />
                <img
                  className={"support_add_img"}
                  src="/build/img/Icons/Add image.svg"
                  alt="zex pr wire Logo"
                  width={24}
                  height={24}
                  priority
                />
              </div>
              <input
                className="support_chat_input"
                type="text"
                placeholder="Type Here"
                name="comment"
                value={inputValue}
                onChange={handleInputChange}
              />
              <button className="support_chat_send" onClick={handleSendMessage}>
                <img
                  className={"support_send_img"}
                  src="/build/img/Icons/Send.png"
                  alt="zex pr wire Logo"
                  width={24}
                  height={24}
                  priority
                />
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}
