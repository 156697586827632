import Sidebar from "../../components/SidebarNew/Sidebar";
import Navbar from "../../components/NavbarProfile/NavbarProfile";
import NewTicket from "../../components/SupportLayout/NewTicket";
import "../Support/Support.css";

export default function SupportAdd() {
  const activeSide = 9;

  return (
    <>
      <div className="row gx-0 support_content">
        <Sidebar activeSide={activeSide} />
        <div className={`main col`}>
          <Navbar name={"Support"} />
          <NewTicket />
        </div>
      </div>
    </>
  );
}
