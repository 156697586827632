import React from "react";
import { Table, Dropdown, Pagination } from "react-bootstrap";
import { useState, useEffect } from "react";
import "./GalleryLayout.css";

export default function ListView({
  setSelectedPhoto,
  updateSelectedCheckboxes,
  images,
  checkedState,
  onPhotoClick,
  onSelectionCountChange,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });

  const handleCheckboxClick = (index) => {
    const actualIndex = indexOfFirstImage + index;

    const clickedGalleryId = images[actualIndex].galleryId;

    const updatedCheckedState = checkedState.map((checked, i) =>
      images[i].galleryId === clickedGalleryId ? !checked : false
    );
    updateSelectedCheckboxes(updatedCheckedState);

    const selectedImages = images.filter((_, i) => updatedCheckedState[i]);
    onSelectionCountChange(selectedImages.length);

    if (selectedImages.length === 1) {
      setSelectedPhoto(selectedImages[0].documentUrl);
      onPhotoClick(images[actualIndex].galleryId);
    } else if (selectedImages.length > 1) {
      setSelectedPhoto(selectedImages[0].documentUrl);
    } else {
      setSelectedPhoto("");
    }
  };

  const handlePhotoClick = (imageUrl) => {
    setSelectedPhoto(imageUrl);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleRowsPerPageChange = (rows) => {
    setRowsPerPage(rows);
    setCurrentPage(1);
  };

  const indexOfLastImage = currentPage * rowsPerPage;
  const indexOfFirstImage = indexOfLastImage - rowsPerPage;
  const currentImage = Array.isArray(images)
    ? images.slice(indexOfFirstImage, indexOfLastImage)
    : [];

  const totalPages = Math.ceil((images?.length || 0) / rowsPerPage);

  const sortedImage = [...images].sort((a, b) => {
    if (sortConfig.key) {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
    }
    return 0;
  });

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const getSortIconClass = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "ascending" ? "rotate-180" : "rotate-0";
    }
    return "rotate-0";
  };

  const currentSortedImages = sortedImage.slice(
    indexOfFirstImage,
    indexOfLastImage
  );

  useEffect(() => {
    if (images.length > 0 && checkedState.every((checked) => !checked)) {
      const firstImage = images[0];
      const updatedCheckedState = checkedState.map((_, i) =>
        i === 0 ? true : false
      );

      updateSelectedCheckboxes(updatedCheckedState);
      setSelectedPhoto(firstImage.documentUrl);
      onPhotoClick(firstImage.galleryId);
      onSelectionCountChange(1);
    }
  }, [images]);

  return (
    <div className="gallery_table">
      <Table hover className="mb-0">
        <thead className="gallery_tables_head">
          <tr className="gallery_tr">
            <th className="gallery_table_th1">SR NO</th>
            <th
              className="gallery_table_th2"
              onClick={() => {
                handleSort("name");
              }}
              style={{ cursor: "pointer" }}
            >
              IMAGE NAME
              <img
                className={` mb-0 ${getSortIconClass("name")}`}
                src="/build/img/down_arrow.svg"
                alt="Sort"
                width={26}
                height={18}
                priority
              />
            </th>
            <th
              className="gallery_table_th3"
              onClick={() => {
                handleSort("createdOn");
              }}
              style={{ cursor: "pointer" }}
            >
              DATE UPLOADED
              <img
                className={` mb-0 ${getSortIconClass("createdOn")}`}
                src="/build/img/down_arrow.svg"
                alt="Sort"
                width={26}
                height={18}
                priority
              />
            </th>
          </tr>
        </thead>
        <tbody className="gallery_table_body">
          {currentSortedImages.length === 0 ? (
            <tr>
              <td colSpan="7" className="text-center">
                NO IMAGE AVAILABLE
              </td>
            </tr>
          ) : (
            currentSortedImages.map((image, index) => {
              const actualIndex = indexOfFirstImage + index;
              return (
                <tr
                  key={image.galleryId}
                  onClick={() => handleCheckboxClick(index)}
                >
                  <td className="gallery_table_td1">
                    <input
                      type="checkbox"
                      className="gallery_sr_checkbox"
                      checked={checkedState[actualIndex]}
                      readOnly
                    />
                    {actualIndex + 1}
                  </td>
                  <td onClick={() => handlePhotoClick(image.documentUrl)}>
                    {image.name}
                  </td>
                  <td>{image.createdOn}</td>
                </tr>
              );
            })
          )}
        </tbody>
        <caption>
          <div className="payment_table_footer">
            <div className="payment_page_no">
              <h4>Rows per page</h4>
              <Dropdown className="pr_rows_dropdown">
                <Dropdown.Toggle variant="light" id="dropdown-basic">
                  {rowsPerPage}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {[5, 10, 15, 20].map((rows) => (
                    <Dropdown.Item
                      key={rows}
                      onClick={() => handleRowsPerPageChange(rows)}
                    >
                      {rows}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="payment_pagination">
              <Pagination className="mb-0">
                <Pagination.Prev
                  onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
                />
                {[...Array(totalPages).keys()].map((page) => (
                  <Pagination.Item
                    key={page + 1}
                    active={page + 1 === currentPage}
                    onClick={() => handlePageChange(page + 1)}
                  >
                    {page + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() =>
                    handlePageChange(Math.min(totalPages, currentPage + 1))
                  }
                />
              </Pagination>
            </div>
          </div>
        </caption>
      </Table>
    </div>
  );
}
