import "./PRmanager.css";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Table } from "react-bootstrap";
import { useState, useEffect, useRef } from "react";
import Instance from "../../Utils/Axios";
import { Link, useNavigate } from "react-router-dom";
import { showToast } from "../../Utils/index";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useParams } from "react-router-dom";
import axios from "axios";
import Select from "react-select";

const PRedit = () => {
  const [prDetails, setPrDetails] = useState([]);
  const [editorData, setEditorData] = useState("");
  const [packageList, setPackageList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [imagesList, setImagesList] = useState([]);
  const [imageInfo, setImageInfo] = useState([]);
  const [error, setError] = useState("");
  const [fileName, setFileName] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImagesData, setSelectedImagesData] = useState([]);
  const [selectedFilePath, setSelectedFilePath] = useState("");
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const { id } = useParams();

  const getOptions = (imagesList) => {
    return imagesList.map((img) => ({
      value: img.galleryId,
      label: img.name,
    }));
  };

  const handleImageSelectionChange = (selectedOptions) => {
    const selectedIds = selectedOptions.map((option) => option.value);
    setSelectedImages(selectedIds);
    setPrDetails({
      ...prDetails,
      image: selectedIds.join("|"),
    });
    const selectedData = selectedOptions.map((option) => {
      const imgData = imagesList.find((img) => img.galleryId === option.value);
      return imgData;
    });
    setSelectedImagesData(selectedData);
  };

  useEffect(() => {
    if (selectedImages.length > 0) {
      const initialSelectedImages = selectedImages
        .map((imageId) => {
          return imagesList.find((img) => img.galleryId === imageId);
        })
        .filter((imgData) => imgData !== undefined);
      setSelectedImagesData(initialSelectedImages);
    }
  }, [selectedImages, imagesList]);

  useEffect(() => {
    const fetchDetails = () => {
      const token = localStorage.getItem("tokenId");
      Instance.post("/user/pressrelease/details", {
        tokenId: token,
        id: id,
      }).then((response) => {
        const details = response.data.message;
        setPrDetails(details);
        setEditorData(details.description);

        if (details.image) {
          const initialSelectedImages = details.image.split("|");
          setSelectedImages(initialSelectedImages);

          // const selectedData = initialSelectedImages.map((imageId) => {
          //   return imagesList.find((img) => img.galleryId === imageId);
          // }).filter((imgData) => imgData !== undefined); // filter out undefined values
          // setSelectedImagesData(selectedData);
        }
        fetchImagesDetails(response.data.message.image);
      });
    };
    fetchDetails();
  }, []);

  // const handleDropdownChange = (e) => {
  //   if (e.target.value === "select-gallery") {
  //     if (fileInputRef.current) {
  //       fileInputRef.current.click();
  //     }
  //   } else {
  //     handleChange(e);
  //   }
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPrDetails({
      ...prDetails,
      [name]: value,
    });
    if (name === "image") {
      fetchImagesDetails(value);
    }
  };

  const handleFileChange = async (event) => {
    const token = localStorage.getItem("tokenId");
    const file = event.target.files[0];

    if (!file) {
      console.error("No file selected");
      return;
    }

    const formDataToSubmit = new FormData();
    formDataToSubmit.append("tokenId", token);
    formDataToSubmit.append("fileUpload", file);

    try {
      const uploadResponse = await axios.post(
        "https://pr.webandapi.com/site/upload/documents",
        formDataToSubmit,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      );

      if (uploadResponse?.data?.resCode === "200") {
        setFileName(file.name);
        setSelectedFilePath(uploadResponse.data.documentUrl);
        console.log("Upload image API response:", uploadResponse.data);
        handleUploadImage(uploadResponse.data.documentUrl, file.name);
      } else {
        console.log("Upload image API failed:", uploadResponse.data);
        showToast(uploadResponse.data.resMessage, "error", "error");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleUploadImage = async (documentUrl, fileName) => {
    const token = localStorage.getItem("tokenId");

    try {
      const createGalleryResponse = await Instance.post(
        "/customer/gallery/create",
        {
          tokenId: token,
          name: fileName,
          documentUrl: documentUrl,
          alternateText: "text",
        }
      );

      if (createGalleryResponse.data.resCode === "200") {
        showToast("Image Uploaded Successfully", "success", "success");
        const newImage = {
          galleryId: createGalleryResponse.data.id,
          name: fileName,
          documentUrl: documentUrl,
        };
        setImagesList((prevImagesList) => [...prevImagesList, newImage]);
        const newSelectedImages = [
          ...selectedImages,
          createGalleryResponse.data.id,
        ];
        setSelectedImages(newSelectedImages);
        setSelectedImagesData((prevSelectedImagesData) => [
          ...prevSelectedImagesData,
          newImage,
        ]);
        setPrDetails((prevDetails) => ({
          ...prevDetails,
          image: newSelectedImages.join("|"),
        }));
        setFileName("");
        setSelectedFilePath("");
      } else {
        showToast("Failed to create gallery entry.", "danger", "error");
      }
    } catch (error) {
      console.error("Error creating gallery entry:", error);
      showToast("Error creating gallery entry.", "danger", "error");
    }
  };

  const handleAddNewClick = (e) => {
    e.preventDefault();
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("tokenId");
      const response = await Instance.post(`/customer/pressrelease/update`, {
        tokenId: token,
        id: id,
        packageId: prDetails.packageId,
        title: prDetails.title,
        description: editorData,
        image: prDetails.image,
        categoryId: prDetails.categoryId,
        companyId: prDetails.companyId,
        addNote: prDetails.addNote,
      });
      console.log("Response from updateStaff API:", response.data);
      if (response.data.resCode === "200") {
        showToast("Updated Succesfully!", "success", "success");
        setTimeout(() => {
          navigate("/customer/pr-manager");
        }, 1000);
      } else {
        setError(response.data.resMessage);
      }
    } catch (error) {
      setError("Error updating the company details");
      console.error("Error updating the company details:", error);
    }
  };

  const fetchPackageList = () => {
    const token = localStorage.getItem("tokenId");
    Instance.post("/user/credit/list", {
      tokenId: token,
      status: "",
    })
      .then((response) => {
        if (response.data.resCode === "200") {
          setPackageList(response.data.message);
          setError("");
        } else {
          setError("Failed to fetch package list");
          console.error(
            "Error fetching package list:",
            response.data.resMessage
          );
        }
      })
      .catch((error) => {
        setError("Failed to fetch countries list");
        console.error("Error fetching countries list:", error);
      });
  };

  const fetchCountryList = () => {
    const token = localStorage.getItem("tokenId");
    Instance.post("/user/common/countryList", {
      tokenId: token,
      status: "",
    })
      .then((response) => {
        if (response.data.resCode === "200") {
          const filteredCountries = response.data.message.filter(
            (country) => country.countryName.trim() !== ""
          );
          setCountryList(filteredCountries);
          setError("");
        } else {
          setError("Failed to fetch countries list");
          console.error(
            "Error fetching countries list:",
            response.data.resMessage
          );
        }
      })
      .catch((error) => {
        setError("Failed to fetch countries list");
        console.error("Error fetching countries list:", error);
      });
  };

  const fetchCompanyList = () => {
    const token = localStorage.getItem("tokenId");
    Instance.post("/user/usercompany/getList", { tokenId: token })
      .then((response) => {
        if (response.data.resCode === "200") {
          setCompanyList(response.data.message);
          setError("");
        } else {
          setError("Failed to fetch company list");
          console.error(
            "Error fetching company list:",
            response.data.resMessage
          );
        }
      })
      .catch((error) => {
        setError("Failed to fetch company list");
        console.error("Error fetching company list:", error);
      });
  };

  const fetchCategoryList = () => {
    const token = localStorage.getItem("tokenId");
    Instance.post("/user/category/getList", {
      tokenId: token,
      status: "ACTIVE",
    })
      .then((response) => {
        if (response.data.resCode === "200") {
          setCategoryList(response.data.message);
          setError("");
        } else {
          setError("Failed to fetch company list");
          console.error(
            "Error fetching company list:",
            response.data.resMessage
          );
        }
      })
      .catch((error) => {
        setError("Failed to fetch company list");
        console.error("Error fetching company list:", error);
      });
  };

  const fetchImages = () => {
    const token = localStorage.getItem("tokenId");
    Instance.post("/user/gallery/getList", {
      tokenId: token,
    })
      .then((response) => {
        if (response.data.resCode === "200") {
          setImagesList(response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching images:", error);
      });
  };

  const fetchImagesDetails = (imageId) => {
    const token = localStorage.getItem("tokenId");
    axios
      .post("https://pr.webandapi.com/user/gallery/details", {
        tokenId: token,
        galleryId: imageId,
      })
      .then((response) => {
        if (response.data.resCode === "200") {
          setImageInfo([response.data.message]);
          console.log("Image details id fetched successfully");
        } else {
          console.error(
            "Failed to fetch image details:",
            response.data.resMessage
          );
          setImageInfo([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching image details:", error);
        setImageInfo([]);
      });
  };

  useEffect(() => {
    fetchPackageList();
    fetchCompanyList();
    fetchCountryList();
    fetchCategoryList();
    fetchImages();
  }, []);

  const copyURL = (url) => {
    navigator.clipboard
      .writeText(url)
      .then(() => showToast("URL copied!", "success", "success"));
  };

  return (
    <>
      <div className="pr_form_preview">
        <Form className="pr_form_text">
          <Form.Group className="mb-4">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter text here"
              className="submit_form_control"
              name="title"
              value={prDetails.title}
              onChange={handleChange}
            />
          </Form.Group>
          <Row>
            {/* <Col className="custom_col_submit mb-4">
              <Form.Label>Time of Release</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter text here"
                className="submit_form_control"
              />
            </Col> */}
            <Col className="custom_col_submit mb-4">
              <Form.Label>Package</Form.Label>
              <Form.Select
                className="submit_form_select"
                name="packageId"
                value={prDetails.packageId}
                onChange={handleChange}
              >
                <option value="">Select from dropdown</option>
                {packageList.map((list) => (
                  <option key={list.packageId} value={list.packageId}>
                    {list.packageName}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Row>
          <Form.Group className="mb-4">
            <Form.Label>PR Body</Form.Label>
            <CKEditor
              editor={ClassicEditor}
              data={editorData}
              onChange={(event, editor) => {
                setEditorData(editor.getData());
              }}
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>Insert Image</Form.Label>
            <button
              className="pr_form_next_btn m-2"
              onClick={handleAddNewClick}
            >
              Add New
            </button>
            {console.log("selected iamge values : ", selectedImages)}
            <Select
              isMulti
              options={getOptions(imagesList)}
              onChange={handleImageSelectionChange}
              value={getOptions(imagesList).filter((option) =>
                selectedImages.includes(option.value)
              )}
            />
            {/* <Form.Select
              className="submit_form_select"
              name="image"
              value={prDetails.image}
              onChange={handleChange}
            >
              <option value="">Select Gallery</option>
              {imagesList.map((img, index) => (
                <option value={img.galleryId}>{img.name}</option>
              ))}
            </Form.Select> */}
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </Form.Group>
          {prDetails.image && (
            <Row className="mb-4 mx-1">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Image Name</th>
                    <th>Image</th>
                    <th>URL</th>
                    <th>Size</th>
                    <th>Copy</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedImagesData.map((image) => (
                    <tr key={image.id}>
                      <td>{image.name}</td>
                      <td>
                        <img src={image.documentUrl} width={100} />
                      </td>
                      <td>{image.documentUrl}</td>
                      <td>{image.size}</td>
                      <td>
                        <button
                          className="pr_form_next_btn"
                          onClick={(e) => {
                            e.preventDefault();
                            copyURL(image.documentUrl);
                          }}
                        >
                          Copy
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Row>
          )}
          <Form.Group className="mb-4">
            <Form.Label>Select Company</Form.Label>
            <Form.Select
              className="submit_form_select"
              name="companyId"
              value={prDetails.companyId}
              onChange={handleChange}
            >
              <option value="">Select Company</option>
              {companyList.map((company) => (
                <option key={company.companyId} value={company.companyId}>
                  {company.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          {/* <h4 className="pr_or">OR</h4>
          <Row>
            <Col className="custom_col_submit mb-4">
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                placeholder=" Enter Company Name"
                className="submit_form_control"
                name="companyName"
                value={prDetails.companyName}
                onChange={handleChange}
                disabled={!!prDetails.companyId}
              />
            </Col>
            <Col className="custom_col_submit mb-4">
              <Form.Label>Contact Person Name</Form.Label>
              <Form.Control
                placeholder="Enter Person Name"
                className="submit_form_control"
                name="contactPerson"
                value={prDetails.contactPerson}
                onChange={handleChange}
                disabled={!!prDetails.companyId}
              />
            </Col>
          </Row>
          <Row>
            <Col className="custom_col_submit mb-4">
              <Form.Label>Email</Form.Label>
              <Form.Control
                placeholder="Enter Email"
                className="submit_form_control"
                name="email"
                value={prDetails.email}
                onChange={handleChange}
                disabled={!!prDetails.companyId}
              />
            </Col>
            <Col className="custom_col_submit mb-4">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                placeholder="Enter Phone Number"
                className="submit_form_control"
                name="mobile"
                value={prDetails.mobile}
                onChange={handleChange}
                disabled={!!prDetails.companyId}
              />
            </Col>
          </Row>
          <Row>
            <Col className="custom_col_submit mb-4">
              <Form.Label>Company Website</Form.Label>
              <Form.Control
                placeholder="Enter Website"
                className="submit_form_control"
                name="website"
                value={prDetails.website}
                onChange={handleChange}
                disabled={!!prDetails.companyId}
              />
            </Col>
            <Col className="custom_col_submit mb-4">
              <Form.Label>Company Address</Form.Label>
              <Form.Control
                placeholder="Enter Address"
                className="submit_form_control"
                name="address"
                value={prDetails.address}
                onChange={handleChange}
                disabled={!!prDetails.companyId}
              />
            </Col>
          </Row>
          <Row>
            <Col xs="3" className="custom_col_submit mb-4">
              <Form.Label>City</Form.Label>
              <Form.Control
                placeholder="Enter City"
                className="submit_form_control"
                name="city"
                value={prDetails.city}
                onChange={handleChange}
                disabled={!!prDetails.companyId}
              />
            </Col>
            <Col xs="3" className="custom_col_submit mb-4">
              <Form.Label>State</Form.Label>
              <Form.Control
                placeholder="Enter State"
                className="submit_form_control"
                name="state"
                value={prDetails.state}
                onChange={handleChange}
                disabled={!!prDetails.companyId}
              />
            </Col>
            <Col xs="6" className="custom_col_submit mb-4">
              <Form.Label>Country</Form.Label>
              <Form.Select
                className="submit_form_select"
                name="country"
                value={prDetails.country}
                onChange={handleChange}
                disabled={!!prDetails.companyId}
              >
                <option value="">Select from dropdown</option>
                {countryList.map((country) => (
                  <option key={country.countryId} value={country.countryCode}>
                    {country.countryName}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Row> */}
          <Form.Group className="mb-4">
            <Form.Label>Select Category</Form.Label>
            <Form.Select
              className="submit_form_select"
              name="categoryId"
              value={prDetails.categoryId}
              onChange={handleChange}
            >
              <option value="">Select from dropdown</option>
              {categoryList.map((list) => (
                <option key={list.categoryId} value={list.categoryId}>
                  {list.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Row>
            <Col xs="6">
              {" "}
              <Form.Group className="mb-4">
                <Form.Label>Write note for editor ?</Form.Label>
                <Form.Control
                  className="submit_form_select"
                  name="addNote"
                  placeholder="Enter note for editor"
                  value={prDetails.addNote}
                  onChange={handleChange}
                  as="textarea"
                  rows={4}
                />
              </Form.Group>
            </Col>
            {/* {prDetails.status === "DRAFT" && (
              <Col xs="6">
                <Form.Group className="mb-4">
                  <Form.Label>Status</Form.Label>
                  <Form.Select
                    className="submit_form_select"
                    name="status"
                    value={prDetails.status}
                    onChange={handleChange}
                  >
                    <option value="DRAFT">Draft</option>
                    <option value="PUBLISH">Publish</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            )} */}
          </Row>
        </Form>
        <button
          className="pr_form_submit_btn mb-4"
          style={{ float: "right", width: "160px" }}
          // onClick={(e) => {
          //   if (prDetails.status === "PUBLISH") {
          //     if (prDetails.addNote && prDetails.addNote.trim() !== "") {
          //       handleUpdate(e, "OPEN");
          //     } else {
          //       handleUpdate(e, "PUBLISH");
          //     }
          //   } else {
          //     handleUpdate(e, prDetails.status);
          //   }
          // }}
          onClick={handleUpdate}
        >
          Save Changes
        </button>
      </div>
    </>
  );
};

export default PRedit;
