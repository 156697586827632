import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "../Utils/ScrollTop";
import Login from "../Pages/Login/Page";
import Signup from "../Pages/SignUp/Page";
import Forgot from "../Pages/ForgotPassword/page";
import NewPassword from "../Pages/NewPassword/Page";
import Dashboard from "../Pages/Dashboard/Page";
import Profile from "../Pages/Profile/Page";
import PRmanager from "../Pages/PRmanager/Page";
import PRaction from "../Pages/PRaction/Page";
import PRpending from "../Pages/PRpending/Page";
import PRpublish from "../Pages/PRpublish/Page";
import PRdraft from "../Pages/PRdraft/Page";
import PRreject from "../Pages/PRreject/Page";
import PRreport from "../Pages/PRreport/Page";
import SubmitPR from "../Pages/SubmitPr/Page";
import SubAccount from "../Pages/SubAccount/Page";
import SubAccountCreate from "../Pages/SubAccountAdd/Page";
import SubAccountUpdate from "../Pages/SubAccountUpdate/Page";
import Gallery from "../Pages/Gallery/Page";
import Payment from "../Pages/Payment/Page";
import AffiliateCenter from "../Pages/AffiliateCenter/Page";
import Support from "../Pages/Support/Page";
import SupportAdd from "../Pages/SupportAdd/Page";
import Contact from "../Pages/Contact/Page";
import Notification from "../Pages/Notification/Page";
import BuyCredits from "../Pages/BuyCredits/Page";
import CreditPayment from "../Pages/CreditPayment/Page";
import PaymentMethod from "../Pages/PaymentMethod/Page";
import NewsRoom from "../Pages/Newsroom/Page";
import PRupdate from "../Pages/PRUpdate/Page";

export default function AllRoutes() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/newpassword" element={<NewPassword />} />
        <Route path="/customer/dashboard" element={<Dashboard />} />
        <Route path="/customer/profile" element={<Profile />} />
        <Route path="/customer/pr-manager" element={<PRmanager />} />
        <Route path="/customer/pr-manager/edit/:id" element={<PRupdate />} />
        <Route path="/customer/pr-manager/report" element={<PRreport />} />
        <Route path="/customer/pr-manager/action" element={<PRaction />} />
        <Route path="/customer/pr-manager/pending" element={<PRpending />} />
        <Route path="/customer/pr-manager/publish" element={<PRpublish />} />
        <Route path="/customer/pr-manager/draft" element={<PRdraft />} />
        <Route path="/customer/pr-manager/reject" element={<PRreject />} />
        <Route path="/customer/submit-pr" element={<SubmitPR />} />
        <Route path="/customer/sub-account" element={<SubAccount />} />
        <Route path="/customer/sub-account/add" element={<SubAccountCreate />} />
        <Route path="/customer/sub-account/edit/:subID" element={<SubAccountUpdate />} />
        <Route path="/customer/gallery" element={<Gallery />} />
        <Route path="/customer/payment" element={<Payment />} />
        <Route path="/customer/affiliate-center" element={<AffiliateCenter />} />
        <Route path="/customer/support" element={<Support />} />
        <Route path="/customer/support/add" element={<SupportAdd />} />
        <Route path="/customer/contact" element={<Contact />} />
        <Route path="/customer/notification" element={<Notification />} />
        <Route path="/customer/pricing" element={<BuyCredits />} />
        <Route path="/customer/pricing/:id" element={<CreditPayment />} />
        <Route path="/customer/payment_method/:id" element={<PaymentMethod />} />
        <Route path="/customer/newsroom" element={<NewsRoom />} />
      </Routes>
    </Router>
  );
}
