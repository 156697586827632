import React, { useEffect, useState } from "react";
import Instance from "../../Utils/Axios";
import "../../Pages/Login/Login.css";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Footer() {
  const [footerData, setFooterData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const notify = () => toast("Wow so easy!");
  useEffect(() => {
    Instance.get("/site/footer")
      .then((response) => {
        console.log(response);
        // alert('hi');

        setFooterData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setError("Error fetching footer data");
        setLoading(false);
        console.error("Error fetching footer data:", error);
      });
  }, []);

  if (loading) return <div style={{ margin: "auto" }}>Loading footer</div>;
  if (error) return <div style={{ margin: "auto" }}>{error}</div>;

  if (!footerData) {
    return <div>No footer data found</div>;
  }

  return (
    <>
      <footer className={`footer`}>
        <div className={`footer_container`}>
          <div className="footer_copyright">{footerData.copyRight}</div>
          <div className={`footer_item_container`}>
            <p className={`mb-0`}>
              <Link
                to={footerData.privacyPolicy.rUrl}
                target={footerData.privacyPolicy.target}
                className="custom_link"
              >
                {footerData.privacyPolicy.label}
              </Link>
            </p>
            <p className={`mb-0`}>
              <Link
                to={footerData.editorialGuidelines.rUrl}
                target={footerData.editorialGuidelines.target}
                className="custom_link"
              >
                {footerData.editorialGuidelines.label}
              </Link>
            </p>
            <p className={`mb-0`}>{footerData.address}</p>
          </div>
        </div>
        <div>
          {/* <button onClick={notify}>Notify!</button> */}
          <ToastContainer theme="colored" />
        </div>
      </footer>
    </>
  );
}
