import Sidebar from "../../components/SidebarNew/Sidebar";
import Navbar from "../../components/NavbarProfile/NavbarProfile";
import SubLayout from "../../components/SubAccountLayout/SubLayout";
import "./SubAccount.css";

export default function SubAccount() {
  const activeSide = 3;
  return (
    <>
      <div className="row gx-0 sub_account_layout">
        <Sidebar activeSide={activeSide} />
        <div className={`main col`}>
          <Navbar name={"Sub-Account"} />
          <SubLayout />
        </div>
      </div>
    </>
  );
}
