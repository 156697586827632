import "./Pricing.css";
import PricingCard from "./PricingCards";
import { useEffect, useState } from "react";

const Pricing = () => {
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       // Simulate an API call or data fetching process
  //       // Replace this with your actual API call
  //       await new Promise((resolve) => setTimeout(resolve, 0)); // Simulate fetch delay
  //     } catch (error) {
  //       console.error("Error fetching pricing data:", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };
  //   fetchData();
  // }, []);
  return (
    <>
      <div className="buy_credit_layout">
        <h3 className="pricing_title">
          Packages and Pricing for PR Distribution
        </h3>
        <div>
          <PricingCard />
        </div>
      </div>
    </>
  );
};

export default Pricing;
