import { Table, Pagination, Dropdown, Modal, Button } from "react-bootstrap";
import "../PRmanagerLayout/PRmanager.css";
import "./SubAccount.css";
import { useState, useEffect } from "react";
import Instance from "../../Utils/Axios";
import { Link } from "react-router-dom";
import { showToast } from "../../Utils/index";

export default function SubLayout() {
  const [companies, setCompanies] = useState([]);
  const [error, setError] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [showDelete, setShowDelete] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });

  useEffect(() => {
    const fetchCompanies = () => {
      const token = localStorage.getItem("tokenId");
      Instance.post("/user/usercompany/getList", {
        tokenId: token,
        status: "",
      })
        .then((response) => {
          if (response.data.resCode === "200") {
            setCompanies(response.data.message);
            setError("");
          } else {
            setError("Failed to fetch companies");
            console.error(
              "Error fetching companies data:",
              response.data.resMessage
            );
          }
        })
        .catch((error) => {
          setError("Failed to fetch companies");
          console.error("Error fetching companies:", error);
        });
    };

    fetchCompanies();
  }, []);

  const handleShowDelete = () => {
    setShowDelete(true);
  };
  const handleNotShowDelete = () => {
    setShowDelete(false);
  };

  const handleDelete = async (companyId) => {
    try {
      const token = localStorage.getItem("tokenId");
      const response = await Instance.post(`/customer/usercompany/update`, {
        tokenId: token,
        companyId: companyId,
        status: "INACTIVE",
      });
      if (response.data.resCode === "200") {
        showToast("Company Deleted successfully!", "success", "succes");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        setError(response.data.resMessage);
      }
    } catch (error) {
      console.error("Error deleting the company details:", error);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleRowsPerPageChange = (rows) => {
    setRowsPerPage(rows);
    setCurrentPage(1);
  };

  const indexOfLastCompany = currentPage * rowsPerPage;
  const indexOfFirstCompany = indexOfLastCompany - rowsPerPage;
  const currentCompanies = companies.slice(
    indexOfFirstCompany,
    indexOfLastCompany
  );

  const totalPages = Math.ceil(companies.length / rowsPerPage);

  const sortedCompanies = [...companies].sort((a, b) => {
    if (sortConfig.key) {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
    }
    return 0;
  });

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const getSortIconClass = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "ascending" ? "rotate-180" : "rotate-0";
    }
    return "rotate-0";
  };

  const currentSortedCompanies = sortedCompanies.slice(
    indexOfFirstCompany,
    indexOfLastCompany
  );

  return (
    <>
      <div className={`pr_secondary_nav`}>
        <Link to="/customer/sub-account/add">
          <button className="sub_edit_btn">
            <img
              className={"sub_add_img mb-0"}
              src="/build/img/Icons/Vector.png"
              alt="zex pr wire Logo"
              width={13}
              height={13}
              priority
            />
            Add New
          </button>
        </Link>
        <img
          className={"pr_group_img"}
          src="/build/img/Group.png"
          alt="zex pr wire Logo"
          width={620}
          height={60}
          priority
        />
      </div>
      <div className="sub_layout">
        <div className="my_pr_tables">
          <Table hover className="mb-0">
            <thead className="pr_tables_head">
              <tr>
                <th
                  onClick={() => handleSort("name")}
                  style={{ cursor: "pointer" }}
                >
                  COMPANY NAME
                  <img
                    className={`pr_add_img mb-0 ${getSortIconClass("name")}`}
                    src="/build/img/down_arrow.svg"
                    alt="zex pr wire Logo"
                    width={26}
                    height={18}
                    priority
                  />
                </th>
                <th
                  onClick={() => handleSort("contactPerson")}
                  style={{ cursor: "pointer" }}
                >
                  Contact PERSON
                  <img
                    className={`pr_add_img mb-0 ${getSortIconClass(
                      "contactPerson"
                    )}`}
                    src="/build/img/down_arrow.svg"
                    alt="zex pr wire Logo"
                    width={26}
                    height={18}
                    priority
                  />
                </th>
                <th
                  onClick={() => handleSort("mobile")}
                  style={{ cursor: "pointer" }}
                >
                  PHONE NUMBER
                  <img
                    className={`pr_add_img mb-0 ${getSortIconClass("mobile")}`}
                    src="/build/img/down_arrow.svg"
                    alt="zex pr wire Logo"
                    width={26}
                    height={18}
                    priority
                  />
                </th>
                <th
                  onClick={() => handleSort("email")}
                  style={{ cursor: "pointer" }}
                >
                  EMAIL
                  <img
                    className={`pr_add_img mb-0 ${getSortIconClass("email")}`}
                    src="/build/img/down_arrow.svg"
                    alt="zex pr wire Logo"
                    width={26}
                    height={18}
                    priority
                  />
                </th>
                <th>CREATED BY</th>
                <th>ACTIONS</th>
                <th></th>
              </tr>
            </thead>

            <tbody className="pr_tables_body">
              {currentSortedCompanies.length === 0 ? (
                <tr>
                  <td colSpan="7" className="text-center">
                    NO DATA AVAILABLE
                  </td>
                </tr>
              ) : (
                currentSortedCompanies.map((company, index) => (
                  <tr key={index}>
                    <td>{company.name}</td>
                    <td>{company.contactPerson}</td>
                    <td>{company.mobile}</td>
                    <td>{company.email}</td>
                    <td>{company.createdBy}</td>
                    <td>
                      <a
                        href={`/customer/sub-account/edit/${company.companyId} `}
                        target="_blank"
                      >
                        <img
                          className={"pr_add_img mb-0"}
                          src="/build/img/Icons/Edit.svg"
                          alt="zex pr wire Logo"
                          width={24}
                          height={24}
                          priority
                        />
                      </a>
                      <img
                        className={"pr_add_img mb-0"}
                        src="/build/img/Icons/Delete.svg"
                        alt="zex pr wire Logo"
                        width={24}
                        height={24}
                        onClick={handleShowDelete}
                        style={{ cursor: "pointer" }}
                      />
                      <Modal
                        show={showDelete}
                        onHide={handleNotShowDelete}
                        centered
                        className="custom_modal company_modal"
                      >
                        <Modal.Body>
                          <div className="submit_modal_img">
                            <img
                              className={"submit_done_img"}
                              src="/build/imageDelete.svg"
                              alt="zex pr wire Logo"
                              width={140}
                              height={140}
                              priority
                            />
                          </div>
                          <div>
                            <h5 className="submit_modal_head">
                              Delete Company
                            </h5>
                            <p className="submit_modal_content">
                              Are you sure you want to delete this company?
                            </p>
                          </div>
                          <div className="pr_modal_btn">
                            <Button
                              className="modal_gallery_btn1"
                              onClick={handleNotShowDelete}
                            >
                              Cancel
                            </Button>
                            <Button
                              className="image_delete_btn"
                              onClick={() => {
                                handleDelete(company.companyId);
                              }}
                            >
                              <img
                                className={"image_delete_img"}
                                src="/build/img/Icons/Delete.png"
                                alt="zex pr wire Logo"
                                width={16}
                                height={16}
                                priority
                              />
                              Delete
                            </Button>
                          </div>
                        </Modal.Body>
                      </Modal>
                    </td>
                    <td>
                      <div
                        className={
                          company.status === "ACTIVE"
                            ? "sub_enabled"
                            : "sub_disabled"
                        }
                      >
                        {company.status}
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
            {error && <div>{error}</div>}

            <caption>
              <div className="pr_rows_table">
                <div className="pr_dropdown">
                  <h4 className="mb-0">Rows per page</h4>
                  <Dropdown className="pr_rows_dropdown">
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                      {rowsPerPage}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {[5, 10, 15, 20].map((rows) => (
                        <Dropdown.Item
                          key={rows}
                          onClick={() => handleRowsPerPageChange(rows)}
                        >
                          {rows}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="pr_pagination">
                  <Pagination className="mb-0">
                    {/* <Pagination.Item>{1}</Pagination.Item>
                    <Pagination.Item>{2}</Pagination.Item>
                    <Pagination.Item>{3}</Pagination.Item>
                    <Pagination.Ellipsis />
                    <Pagination.Item>{10}</Pagination.Item>
                    <Pagination.Item>{11}</Pagination.Item>
                    <Pagination.Item>{12}</Pagination.Item> */}

                    <Pagination.First onClick={() => handlePageChange(1)} />
                    <Pagination.Prev
                      onClick={() =>
                        handlePageChange(Math.max(1, currentPage - 1))
                      }
                    />
                    {[...Array(totalPages).keys()].map((page) => (
                      <Pagination.Item
                        key={page + 1}
                        active={page + 1 === currentPage}
                        onClick={() => handlePageChange(page + 1)}
                      >
                        {page + 1}
                      </Pagination.Item>
                    ))}
                    <Pagination.Next
                      onClick={() =>
                        handlePageChange(Math.min(totalPages, currentPage + 1))
                      }
                    />
                    <Pagination.Last
                      onClick={() => handlePageChange(totalPages)}
                    />
                  </Pagination>
                </div>
                {/* <div className="pr_next_page">
                  <h4 className="mb-0">Go to page</h4>
                  <img
                    className={"pr_next_img mb-0"}
                    src="/build/img/Select.svg"
                    alt="zex pr wire Logo"
                    width={76}
                    height={32}
                    priority
                  />
                </div> */}
              </div>
            </caption>
          </Table>
        </div>
      </div>
    </>
  );
}
