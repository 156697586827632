import "./AffiliateCenter.css";
import { Modal, Button } from "react-bootstrap";
import { useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

function Wallet() {
  const [showWithdraw, setshowWithdraw] = useState(false);
  const [showBank, setshowBank] = useState(false);

  const hadnleShowWithdraw = () => {
    setshowWithdraw(true);
  };
  const hadnleNotShowWithdraw = () => {
    setshowWithdraw(false);
  };

  const hadnleShowBank = () => {
    setshowBank(true);
  };
  const hadnleNotShowBank = () => {
    setshowBank(false);
  };
  return (
    <>
      <div>
        <div className="d-flex gap-4 flex-wrap flex-lg-nowrap">
          <div className="wallet_balance">
            <img
              src="./build/Balance.svg"
              alt="zex pr"
              width={48}
              height={48}
            />
            <h4>$5000</h4>
            <p>Balance</p>
          </div>
          <div className="wallet_withdraw">
            <h4>Withdraw Amount </h4>
            <input
              type="text"
              placeholder="Enter amount here"
              className="withdraw_input"
            />
            <p>*lorem ipsum</p>
            <div>
              <button className="withdraw_btn" onClick={hadnleShowWithdraw}>
                Withdraw
              </button>
              <Modal
                show={showWithdraw}
                onHide={hadnleNotShowWithdraw}
                centered
                className=""
              >
                <Modal.Body className="p-0">
                  <div>
                    <h5 className="submit_modal_head">KYC</h5>
                    <p className="submit_modal_content">
                      Please complete your KYC to withdraw amount!
                    </p>
                  </div>
                  <div className="pr_modal_btn">
                    <Button
                      className="wallet_cancel_btn"
                      onClick={hadnleNotShowWithdraw}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="wallet_save_btn"
                      onClick={hadnleNotShowWithdraw}
                    >
                      Processed to KYC
                    </Button>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <p className="wallet_withdraw_title">Withdraw to</p>
          <div className="wallet_withdraw_to">
            <div className="withdraw_account">
              <div className="d-flex align-items-center">
                <img
                  src="./build/Axis.svg"
                  alt="zex pr"
                  width={75}
                  height={75}
                />
              </div>
              <div className="pt-2">
                <p className="withdraw_account_name">Axis Bank 8974</p>
                <p className="withdraw_account_type">Savings Account</p>
              </div>
            </div>
            <div className="withdraw_account">
              <div className="d-flex align-items-center">
                <img
                  src="./build/StandarcChartered.svg"
                  alt="zex pr"
                  width={75}
                  height={75}
                />
              </div>
              <div className="pt-2">
                <p className="withdraw_account_name">Standard Chartered 4423</p>
                <p className="withdraw_account_type">Savings Account</p>
              </div>
            </div>
            <div className="withdraw_account" onClick={hadnleShowBank}>
              <div className="d-flex align-items-center">
                <img
                  src="./build/Add_bank.svg"
                  alt="zex pr"
                  width={75}
                  height={75}
                />
              </div>
              <div className="pt-2">
                <p className="withdraw_account_name">Add New Bank Account</p>
                <p className="withdraw_account_type">
                  Savings or Current Account
                </p>
              </div>
            </div>

            <Modal
              show={showBank}
              onHide={hadnleNotShowBank}
              centered
              className="custom_modal_wallet"
            >
              <Modal.Body className="p-0 wallet_modal_body">
                <div>
                  <h5 className="wallet_modal_head">Add Bank Account</h5>
                  <div className="wallet_modal_detail">
                    <div className="wallet_modal_box1">
                      <Row>
                        <Col className="custom_col_wallet mb-2-lg">
                          <Form.Label>Country</Form.Label>
                          <Form.Select>
                            <option>United States</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                          </Form.Select>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="custom_col_wallet mb-2-lg">
                          <Form.Label>Choose Bank</Form.Label>
                          <Form.Select>
                            <option>Select from dropdown</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                          </Form.Select>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="custom_col_wallet mb-2-lg">
                          <Form.Label>Address</Form.Label>
                          <Form.Control placeholder="optional" />
                        </Col>
                      </Row>
                    </div>
                    <div className="wallet_modal_box2">
                      <Row>
                        <Col className="custom_col_wallet mb-1">
                          <Form.Label>Card type</Form.Label>
                          <Form.Select>
                            <option>Select from dropdown</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                          </Form.Select>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="custom_col_wallet mb-1">
                          <Form.Label>Card Number</Form.Label>
                          <Form.Control placeholder="1234 xxxx xxxx 7890" />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="custom_col_wallet mb-1">
                          <Form.Label>Expiry Date</Form.Label>
                          <Form.Control placeholder="2/6/2024" />
                        </Col>
                        <Col className="custom_col_wallet mb-1">
                          <Form.Label>CVV</Form.Label>
                          <Form.Control placeholder="***" />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="custom_col_wallet mb-1">
                          <Form.Label>First Name</Form.Label>
                          <Form.Control placeholder="Sam" />
                        </Col>
                        <Col className="custom_col_wallet mb-1">
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control placeholder="Maxwell" />
                        </Col>
                      </Row>
                      <p className="mb-1">Save Card Details?</p>
                      <div className="d-flex align-items-center">
                        <input
                          type="checkbox"
                          className="me-1 affiliate_checkbox_design"
                        />
                        <span className="me-3">Yes</span>
                        <input
                          type="checkbox"
                          className="me-1 affiliate_checkbox_design"
                        />
                        <span>No</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pr_modal_btn">
                  <Button
                    className="wallet_cancel_btn"
                    onClick={hadnleNotShowBank}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="wallet_save_btn"
                    onClick={hadnleNotShowBank}
                  >
                    Save
                  </Button>
                </div>
              </Modal.Body>
            </Modal>
          </div>
          <p className="wallet_withdraw_or">Or</p>
          <div className="withdraw_account">
            <div className="d-flex align-items-center">
              <img
                src="./build/Paypal.svg"
                alt="zex pr"
                width={75}
                height={75}
              />
            </div>
            <div className="pt-2">
              <p className="withdraw_account_name">Paypal</p>
              <p className="withdraw_account_type">Paypal account</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Wallet;
