import "./Pricing.css";
import { Link } from "react-router-dom";

const PricingFooter = () => {
  return (
    <>
      <div className="pricing_footer">
        Copyright 2024 © ZEXPRWIRE. All rights reserved.
        <Link> Privacy Policy</Link> |<Link> Terms and Conditions</Link>
      </div>
    </>
  );
};

export default PricingFooter;
