import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "../PRmanagerLayout/PRmanager.css";
import "./SubAccount.css";
import { useState, useEffect } from "react";
import Instance from "../../Utils/Axios";
import { Link, useNavigate } from "react-router-dom";
import { showToast } from "../../Utils/index";

const AddSubLayout = () => {
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    contactPerson: "",
    mobile: "",
    email: "",
    website: "",
    state: "",
    country: "",
    city: "",
    createdBy: "",
  });
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [countryList, setCountryList] = useState([]);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    for (let key in formData) {
      if (formData[key] === "") {
        console.log("all fields are required");
        showToast("All fields are required", "error", "error");
        return;
      }
    }
    const token = localStorage.getItem("tokenId");
    Instance.post("/user/usercompany/create", {
      tokenId: token,
      ...formData,
    })
      .then((response) => {
        if (response.data.resCode === "200") {
          setSuccessMessage(response.data.resMessage);
          setError("");
          showToast("Details submitted successfully", "success", "success");
          setTimeout(() => {
            navigate("/customer/sub-account");
          }, 2000);
        } else {
          setError("Failed to submit details");
          console.error("Error:", response.data.resMessage);
        }
      })
      .catch((error) => {
        setError("Failed to submit details");
        console.error("Error:", error);
      });
  };

  const handleCancel = () => {
    setFormData({
      name: "",
      address: "",
      contactPerson: "",
      mobile: "",
      email: "",
      website: "",
      state: "",
      country: "",
      city: "",
      createdBy: "",
    });
    setError("");
    setSuccessMessage("");
  };

  const fetchCountryList = () => {
    const token = localStorage.getItem("tokenId");
    Instance.post("/user/common/countryList", {
      tokenId: token,
      status: "",
    })
      .then((response) => {
        if (response.data.resCode === "200") {
          const filteredCountries = response.data.message.filter(
            (country) => country.countryName.trim() !== ""
          );
          setCountryList(filteredCountries);
          setError("");
        } else {
          setError("Failed to fetch countries list");
          console.error(
            "Error fetching countries list:",
            response.data.resMessage
          );
        }
      })
      .catch((error) => {
        setError("Failed to fetch countries list");
        console.error("Error fetching countries list:", error);
      });
  };

  useEffect(() => {
    fetchCountryList();
  }, []);

  return (
    <>
      <div className={`pr_secondary_nav`}>
        <Link to="/customer/sub-account">
          <button className="sub_back_btn">
            <img
              className={"sub_back_img mb-0"}
              src="/build/img/Icons/Chevron.svg"
              alt="zex pr wire Logo"
              width={20}
              height={20}
              priority
            />
            Back
          </button>
        </Link>
      </div>
      <Form onSubmit={handleSubmit}>
        <div className="pr_form_preview">
          <h4 className="sub_form_head mb-4">Add a new company profile</h4>

          <Row>
            <Col className="custom_col_sub_account mb-4">
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                placeholder=" Enter Company Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </Col>
            <Col className="custom_col_sub_account mb-4">
              <Form.Label>Contact Person Name</Form.Label>
              <Form.Control
                placeholder="Enter Contact Person Name"
                name="contactPerson"
                value={formData.contactPerson}
                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row>
            <Col className="custom_col_sub_account mb-4">
              <Form.Label>Email</Form.Label>
              <Form.Control
                placeholder="Enter Email"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </Col>
            <Col className="custom_col_sub_account mb-4">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                placeholder="Enter Phone Number"
                type="number"
                name="mobile"
                value={formData.mobile}
                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row>
            <Col className="custom_col_sub_account mb-4">
              <Form.Label>Company Website</Form.Label>
              <Form.Control
                placeholder="Enter Website"
                type="text"
                name="website"
                value={formData.website}
                onChange={handleChange}
              />
            </Col>
            <Col className="custom_col_sub_account mb-4">
              <Form.Label>Created By</Form.Label>
              <Form.Control
                placeholder="Enter Created By"
                type="text"
                name="createdBy"
                value={formData.createdBy}
                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row>
            <Col className="custom_col_sub_account mb-4">
              <Form.Label>Company Address</Form.Label>
              <Form.Control
                placeholder="Enter Address"
                type="text"
                name="address"
                value={formData.address}
                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row>
            <Col xs="3" className="custom_col_sub_account mb-4">
              <Form.Label>City</Form.Label>
              <Form.Control
                placeholder="Enter City"
                name="city"
                value={formData.city}
                onChange={handleChange}
              />
            </Col>
            <Col xs="3" className="custom_col_sub_account mb-4">
              <Form.Label>State</Form.Label>
              <Form.Control
                placeholder="Enter State"
                name="state"
                value={formData.state}
                onChange={handleChange}
              />
            </Col>
            <Col xs="6" className="custom_col_sub_account mb-4">
              <Form.Label>Country</Form.Label>
              <Form.Select
                name="country"
                value={formData.country}
                onChange={handleChange}
              >
                <option value="">Select Country</option>
                {countryList.map((country) =>
                  country.countryName ? (
                    <option key={country.countryId} value={country.countryCode}>
                      {country.countryName}
                    </option>
                  ) : (
                    ""
                  )
                )}
              </Form.Select>
            </Col>
          </Row>
          {/* <p className="pr_form_p">
            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
            sint. Velit officia consequat duis enim velit mollit. Exercitation
            veniam consequat sunt nostrud amet.
          </p> */}

          {error && <p className="text-danger">{error}</p>}
          {successMessage && <p className="text-success">{successMessage}</p>}
        </div>
        <div className="sub_account_buttons">
          <button
            className="sub_cancel_btn mb-4"
            onClick={handleCancel}
            type="button"
          >
            Cancel
          </button>
          <button className="sub_save_btn mb-4" type="submit">
            Save Changes
          </button>
        </div>
      </Form>
    </>
  );
};
export default AddSubLayout;
