import "./AffiliateCenter.css";
import { useState } from "react";

export default function Welcome({ onshowFirst }) {
  const [expanded, setExpanded] = useState(false);
  const [expandedBox, setExpandedBox] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const boxExpansion = () => {
    setExpanded(!expanded);
  };
  const box2Expansion = () => {
    setExpandedBox(!expandedBox);
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  return (
    <>
      <div className="welcome_layout">
        <h3>Welcome to Affiliate Center</h3>
        <div className="rules_ac">
          <ol>
            Rules:
            <li>
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do
              amet sint. Velit officia consequat duis enim velit mollit.
              Exercitation veniam consequat sunt nostrud amet.
            </li>
            <li>
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do
              amet sint. Velit officia consequat duis enim velit mollit.
              Exercitation veniam consequat sunt nostrud amet.
            </li>
            <li>
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do
              amet sint. Velit officia consequat duis enim velit mollit.
              Exercitation veniam consequat sunt nostrud amet.
            </li>
          </ol>
        </div>
        <div className="terms_ac">
          <ol>
            Terms and Conditions:
            <li>
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do
              amet sint. Velit officia consequat duis enim velit mollit.
              Exercitation veniam consequat sunt nostrud amet.
            </li>
            <li>
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do
              amet sint. Velit officia consequat duis enim velit mollit.
              Exercitation veniam consequat sunt nostrud amet.
            </li>
            <li>
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do
              amet sint. Velit officia consequat duis enim velit mollit.
              Exercitation veniam consequat sunt nostrud amet.
            </li>
          </ol>
        </div>
        <div className="ac_policies">
          <div
            className={`editorial_policy_ac ${expanded ? "expanded" : ""}`}
            onClick={boxExpansion}
          >
            <h5>
              Editorial Policy
              <img
                className="link_ac_img"
                src="./build/img/Icons/Link.svg"
                alt="zex pr"
                width={16}
                height={16}
              />
            </h5>
            <p>
              ZEX PR Wire's editorial team reviews all press releases before it
              is distributed to ensure that the content is newsworthy and
              accurate in a PR format. The guidelines that are written below
              help your PR get quick approval and distribution in our platform.
              The press releases should have a clear and newsworthy angle and be
              free from spam, direct address and ads hype. It should incorporate
              the following features:
            </p>
          </div>
          <div
            className={`privacy_policy_ac ${expandedBox ? "expanded" : ""}`}
            onClick={box2Expansion}
          >
            <h5>
              Privacy Policy
              <img
                className="link_ac_img"
                src="./build/img/Icons/Link.svg"
                alt="zex pr"
                width={16}
                height={16}
              />
            </h5>
            <p>
              ZEX PR Wire is committed to guarding the privacy of our visitors.
              We are one of the leading press release distribution services, and
              are committed to beginning and maintaining procedures and policies
              which protects the privacy of our personal information gained from
              our customers, visitors or other prospects on our website. ZEX PR
              Wire is a Press Release distribution service which distributes PR
              on behalf of clients. If you submit a press release for
              distribution through our system, the PR will be broadcasted in its
              entirety. It will include all personal information contained in
              our media contact information. Also, when you submit the PR
              through our platform, you agree to our policy and understand that
              your contact information will be circulated. This policy applies
              to all the websites, services and products offered by ZEX PR Wire.
            </p>
          </div>
        </div>
        <div className="ac_checkbox" onChange={handleCheckboxChange}>
          <input type="checkbox" className="affiliate_checkbox_design" />
          <span>I accept all the above rules and T&C.</span>
        </div>
        <div>
          <button
            onClick={() => isChecked && onshowFirst()}
            className="ac_next_btn mt-4 mb-4"
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
}
