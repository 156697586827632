import success from "./Icons/success";
import error from "./Icons/error";
import { toast, TypeOptions } from "react-toastify";

export const showToast = (
    message: string | any,
    type: TypeOptions,
    toastId?: any
) => {
    const icon = type === "success" ? success : error;
    if (type === "success") {
        toast.success(message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            className: `toast_${type}`,
            toastId: toastId || undefined,
        });
    } else {
        toast.error(message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            className: `toast_${type}`,
            toastId: toastId || undefined,
        });
    }

};