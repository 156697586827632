import "./AffiliateCenter.css";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Table, Pagination, Dropdown } from "react-bootstrap";

function AffiliateMemberProfile() {
  return (
    <>
      <div className="member_profile_layout">
        <div className="member_profile_detail">
          <h4>Affiliate Member Details</h4>
          <Row>
            <Col className="custom_col_ac mb-4">
              <Form.Label>First Name</Form.Label>
              <Form.Control placeholder="Jerome" />
            </Col>
            <Col className="custom_col_ac mb-4">
              <Form.Label>Last Name</Form.Label>
              <Form.Control placeholder="Bell" />
            </Col>
          </Row>
          <Row>
            <Col className="custom_col_ac mb-4">
              <Form.Label>Email id</Form.Label>
              <Form.Control placeholder="jerome.bell@fakemail.com" />
            </Col>
            <Col className="custom_col_ac mb-4">
              <Form.Label>Phone</Form.Label>
              <Form.Control placeholder="(671) 555-0110" />
            </Col>
          </Row>
          <Row>
            <Col className="custom_col_ac mb-4">
              <Form.Label>Address</Form.Label>
              <Form.Control placeholder="775 Rolling Green Rd." />
            </Col>
          </Row>
          <Row>
            <Col xs="3" className="custom_col_ac mb-4">
              <Form.Label>City</Form.Label>
              <Form.Control placeholder="Silicon Valley" />
            </Col>
            <Col xs="3" className="custom_col_ac mb-4">
              <Form.Label>State</Form.Label>
              <Form.Control placeholder="California" />
            </Col>
            <Col xs="6" className="custom_col_ac mb-4">
              <Form.Label>Country</Form.Label>
              <Form.Control placeholder="United States" />
            </Col>
          </Row>
        </div>
        <div className="affiliate_coupon_options">
          <h5>Affiliate Codes</h5>
          <button variant="primary" className="Payment_filter_btn">
            Filter
            <img
              className="filter_img"
              src="/build/img/Icons/down_vector.svg"
              alt="zex pr wire Logo"
              width={16}
              height={16}
              priority
            />
          </button>
        </div>
        <div className="affiliate_coupon_table_list">
          <Table hover className="mb-0">
            <thead className="affiliate_coupon_tables_head">
              <tr className="affiliate_coupon_tr">
                <th>
                  SR NO
                  <img
                    className={"mb-0"}
                    src="/build/img/down_arrow.svg"
                    alt="zex pr wire Logo"
                    width={26}
                    height={18}
                    priority
                  />
                </th>
                <th>COUPON CODE NAME</th>
                <th>NO. OF TIME USED</th>
                <th>COMMISION EARNED</th>
              </tr>
            </thead>
            <tbody className="affiliate_coupon_table_body">
              <tr>
                <td>1</td>
                <td>BLACKFRIDAYSALE</td>
                <td>6</td>
                <td>5</td>
              </tr>
              <tr>
                <td>2</td>
                <td>CYBERMONDAYSALE</td>
                <td>5</td>
                <td>0</td>
              </tr>
              <tr>
                <td>3</td>
                <td>BOXINGDAYSALE</td>
                <td>0</td>
                <td>3</td>
              </tr>
              <tr>
                <td>4</td>
                <td>MATCHDAYSALE</td>
                <td>2</td>
                <td>2</td>
              </tr>
              <tr>
                <td>5</td>
                <td>THANKSGIVINGSALE</td>
                <td>3</td>
                <td>1</td>
              </tr>
              <tr>
                <td>6</td>
                <td>CHRISTMASSALE</td>
                <td>3</td>
                <td>2</td>
              </tr>
              <tr>
                <td>7</td>
                <td>DIWALISALE</td>
                <td>0</td>
                <td>4</td>
              </tr>
              <tr>
                <td>8</td>
                <td>STPATRICKSDAYSALE</td>
                <td>2</td>
                <td>1</td>
              </tr>
              <tr>
                <td>9</td>
                <td>EASTERSALE</td>
                <td>1</td>
                <td>0</td>
              </tr>
            </tbody>
            <caption>
              <div className="member_profile_table_footer">
                <div className="member_profile_page_no">
                  <h4>Rows per page</h4>
                  <Dropdown className="member_profile_dropdown">
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                      10
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">1</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">2</Dropdown.Item>
                      <Dropdown.Item href="#/action-3">3</Dropdown.Item>
                      <Dropdown.Item href="#/action-3">4</Dropdown.Item>
                      <Dropdown.Item href="#/action-3">5</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="member_profile_pagination">
                  <Pagination className="mb-0">
                    <Pagination.Item>{1}</Pagination.Item>
                    <Pagination.Item>{2}</Pagination.Item>
                    <Pagination.Item>{3}</Pagination.Item>
                    <Pagination.Ellipsis />
                    <Pagination.Item>{10}</Pagination.Item>
                    <Pagination.Item>{11}</Pagination.Item>
                    <Pagination.Item>{12}</Pagination.Item>
                  </Pagination>
                </div>
                <div className="member_profile_pages">
                  <h4>Go to page</h4>
                  <img
                    className={"mb-0 payment_select_img"}
                    src="/build/img/Select.svg"
                    alt="zex pr wire Logo"
                    width={76}
                    height={32}
                    priority
                  />
                </div>
              </div>
            </caption>
          </Table>
        </div>
      </div>
    </>
  );
}

export default AffiliateMemberProfile;
