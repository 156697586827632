import "./PRmanager.css";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Modal, Button, Table } from "react-bootstrap";
import { useState, useEffect, useRef } from "react";
import Instance from "../../Utils/Axios";
import { Link, useNavigate } from "react-router-dom";
import { showToast } from "../../Utils/index";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import Select from "react-select";
import { id } from "date-fns/locale";

export default function PRpreview({ onShowPRLayout }) {
  const [editorData, setEditorData] = useState("");
  const [navshow, setnavshow] = useState(0);
  const [companyId, setCompanyId] = useState("");
  const [company, setCompany] = useState({
    name: "",
    contactPerson: "",
    address: "",
    mobile: "",
    email: "",
    website: "",
    state: "",
    country: "",
    city: "",
  });
  const [formData, setFormData] = useState({
    // tokenId: "",
    packageId: "",
    title: "",
    description: "",
    showContactDetails: "YES",
    image: "",
    addNote: "",
    status: "",
    categoryId: "",
  });
  const [error, setError] = useState("");
  const [packageList, setPackageList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [imagesList, setImagesList] = useState([]);
  const [imageInfo, setImageInfo] = useState([]);
  const [fileName, setFileName] = useState("");
  const [selectedFilePath, setSelectedFilePath] = useState("");
  const navigate = useNavigate();
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImagesData, setSelectedImagesData] = useState([]);
  const fileInputRef = useRef(null);
  const isReadOnly = navshow === 1;

  const getOptions = (imagesList) => {
    return imagesList.map((img) => ({
      value: img.galleryId,
      label: img.name,
    }));
  };

  const handleImageSelectionChange = (selectedOptions) => {
    const selectedIds = selectedOptions.map((option) => option.value);
    setSelectedImages(selectedIds);
    setFormData({
      ...formData,
      image: selectedIds.join("|"),
    });
    const selectedData = selectedOptions.map((option) => {
      const imgData = imagesList.find((img) => img.galleryId === option.value);
      return imgData;
    });
    setSelectedImagesData(selectedData);
  };

  const handleCompanyIdChange = (e) => {
    const value = e.target.value;
    setCompanyId(value);
    if (value) {
      setCompany({
        name: "",
        contactPerson: "",
        address: "",
        mobile: "",
        email: "",
        website: "",
        state: "",
        country: "",
        city: "",
      });
    }
  };

  const handleCompanyChange = (e) => {
    const { name, value } = e.target;
    setCompany({
      ...company,
      [name]: value,
    });

    if (value) {
      setCompanyId("");
    }
  };

  const handleFileChange = async (event) => {
    const token = localStorage.getItem("tokenId");
    const file = event.target.files[0];

    if (!file) {
      console.error("No file selected");
      return;
    }

    const formDataToSubmit = new FormData();
    formDataToSubmit.append("tokenId", token);
    formDataToSubmit.append("fileUpload", file);

    try {
      const uploadResponse = await axios.post(
        "https://pr.webandapi.com/site/upload/documents",
        formDataToSubmit,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      );

      if (uploadResponse?.data?.resCode === "200") {
        setFileName(file.name);
        setSelectedFilePath(uploadResponse.data.documentUrl);
        console.log("Upload image API response:", uploadResponse.data);
        handleUpload(uploadResponse.data.documentUrl, file.name);
      } else {
        console.log("Upload image API failed:", uploadResponse.data);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleUpload = async (documentUrl, fileName) => {
    const token = localStorage.getItem("tokenId");

    try {
      const createGalleryResponse = await Instance.post(
        "/customer/gallery/create",
        {
          tokenId: token,
          name: fileName,
          documentUrl: documentUrl,
          alternateText: "text",
        }
      );

      if (createGalleryResponse.data.resCode === "200") {
        showToast("Image Uploaded Successfully", "success", "success");
        const newImage = {
          galleryId: createGalleryResponse.data.id,
          name: fileName,
          documentUrl: documentUrl,
        };
        setImagesList((prevImagesList) => [...prevImagesList, newImage]);
        const newSelectedImages = [
          ...selectedImages,
          createGalleryResponse.data.id,
        ];
        setSelectedImages(newSelectedImages);
        setSelectedImagesData((prevSelectedImagesData) => [
          ...prevSelectedImagesData,
          newImage,
        ]);
        setFormData((prevFormData) => ({
          ...prevFormData,
          image: newSelectedImages.join("|"),
        }));
        setFileName("");
        setSelectedFilePath("");
      } else {
        showToast("Failed to create gallery entry.", "danger", "error");
      }
    } catch (error) {
      console.error("Error creating gallery entry:", error);
      showToast("Error creating gallery entry.", "danger", "error");
    }
  };

  const handleAddNewClick = (e) => {
    e.preventDefault();
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  //   const getOptions = (imagesList) => {
  //   return imagesList.map(img => ({
  //     value: img.galleryId,
  //     label: img.name,
  //   }));
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "image") {
      fetchImagesDetails(value);
    }
  };

  useEffect(() => {
    if (formData.image) {
      const selectedImage = imagesList.find(
        (img) => img.galleryId === formData.image
      );
      if (selectedImage) {
        setImageInfo([selectedImage]);
      }
    }
  }, [formData.image]);

  const handleSubmit = async (e, status) => {
    e.preventDefault();
    console.log("Submitting with status:", status);
    try {
      const token = localStorage.getItem("tokenId");
      const response = await Instance.post("/user/pressrelease/create", {
        tokenId: token,
        ...formData,
        description: editorData,
        status: status,
        companyId: companyId ? companyId : null,
        company: companyId ? null : company,
      });
      if (response.data.resCode === "200") {
        setError("");
        showToast("Details submitted successfully", "success", "success");
        setTimeout(() => {
          navigate("/customer/pr-manager");
        }, 2000);
      } else {
        setError("Failed to submit details");
        console.error("Error:", response.data.resMessage);
      }
    } catch (error) {
      setError("Failed to submit details");
      console.error("Error:", error);
    }
  };

  const fetchPackageList = () => {
    const token = localStorage.getItem("tokenId");
    Instance.post("/user/credit/list", {
      tokenId: token,
      status: "",
    })
      .then((response) => {
        if (response.data.resCode === "200") {
          setPackageList(response.data.message);
          setError("");
        } else {
          setError("Failed to fetch package list");
          console.error(
            "Error fetching package list:",
            response.data.resMessage
          );
        }
      })
      .catch((error) => {
        setError("Failed to fetch countries list");
        console.error("Error fetching countries list:", error);
      });
  };

  const fetchCountryList = () => {
    const token = localStorage.getItem("tokenId");
    Instance.post("/user/common/countryList", {
      tokenId: token,
      status: "",
    })
      .then((response) => {
        if (response.data.resCode === "200") {
          const filteredCountries = response.data.message.filter(
            (country) => country.countryName.trim() !== ""
          );
          setCountryList(filteredCountries);
          setError("");
        } else {
          setError("Failed to fetch countries list");
          console.error(
            "Error fetching countries list:",
            response.data.resMessage
          );
        }
      })
      .catch((error) => {
        setError("Failed to fetch countries list");
        console.error("Error fetching countries list:", error);
      });
  };

  const fetchCompanyList = () => {
    const token = localStorage.getItem("tokenId");
    Instance.post("/user/usercompany/getList", { tokenId: token })
      .then((response) => {
        if (response.data.resCode === "200") {
          setCompanyList(response.data.message);
          setError("");
        } else {
          setError("Failed to fetch company list");
          console.error(
            "Error fetching company list:",
            response.data.resMessage
          );
        }
      })
      .catch((error) => {
        setError("Failed to fetch company list");
        console.error("Error fetching company list:", error);
      });
  };

  const fetchCategoryList = () => {
    const token = localStorage.getItem("tokenId");
    Instance.post("/user/category/getList", {
      tokenId: token,
      status: "ACTIVE",
    })
      .then((response) => {
        if (response.data.resCode === "200") {
          setCategoryList(response.data.message);
          setError("");
        } else {
          setError("Failed to fetch company list");
          console.error(
            "Error fetching company list:",
            response.data.resMessage
          );
        }
      })
      .catch((error) => {
        setError("Failed to fetch company list");
        console.error("Error fetching company list:", error);
      });
  };

  const fetchImages = () => {
    const token = localStorage.getItem("tokenId");
    Instance.post("/user/gallery/getList", {
      tokenId: token,
    })
      .then((response) => {
        if (response.data.resCode === "200") {
          setImagesList(response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching images:", error);
      });
  };

  const fetchImagesDetails = (imageId) => {
    const token = localStorage.getItem("tokenId");
    axios
      .post("https://pr.webandapi.com/user/gallery/details", {
        tokenId: token,
        galleryId: imageId,
      })
      .then((response) => {
        if (response.data.resCode === "200") {
          setImageInfo([response.data.message]);
          console.log("Image details id fetched successfully");
        } else {
          console.error(
            "Failed to fetch image details:",
            response.data.resMessage
          );
          setImageInfo([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching image details:", error);
        setImageInfo([]);
      });
  };

  useEffect(() => {
    fetchPackageList();
    fetchCompanyList();
    fetchCountryList();
    fetchCategoryList();
    fetchImages();
  }, []);

  const copyURL = (url) => {
    navigator.clipboard
      .writeText(url)
      .then(() => showToast("URL copied!", "success", "success"));
  };

  const handleNavShow = () => {
    setnavshow(1);
  };
  const handleNotNavShow = () => {
    setnavshow(0);
  };

  class MyUploadAdapter {
    constructor(loader) {
      this.loader = loader;
      this.url = "https://pr.webandapi.com/site/upload/documents"; // Your upload URL
    }

    upload() {
      return this.loader.file.then(
        (file) =>
          new Promise((resolve, reject) => {
            const data = new FormData();
            const token = localStorage.getItem("tokenId");
            data.append("fileUpload", file);
            data.append("tokenId", token);

            fetch(this.url, {
              method: "POST",
              body: data,
            })
              .then((response) => response.json())
              .then((result) => {
                if (result?.resCode === "200") {
                  // Resolve with the URL of the uploaded image
                  resolve({
                    default: result.documentUrl,
                  });
                } else {
                  reject("Upload failed");
                }
              })
              .catch((error) => {
                reject(error.message);
              });
          })
      );
    }

    abort() {
      // Reject the promise returned from upload() method if the upload was aborted.
    }
  }

  function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return new MyUploadAdapter(loader);
    };
  }

  return (
    <>
      <div className={`pr_secondary_nav  ${navshow === 1 ? "" : "d-none"} `}>
        <button className="pr_edit_btn" onClick={handleNotNavShow}>
          <img
            className={"pr_edit_img"}
            src="/build/img/Edit.svg"
            alt="zex pr wire Logo"
            width={14}
            height={14}
            priority
          />
          Edit
        </button>
        <img
          className={"pr_group_img"}
          src="/build/img/Group.png"
          alt="zex pr wire Logo"
          width={620}
          height={60}
          priority
        />
      </div>
      <div className="pr_form_preview">
        <Form className="pr_form_text">
          <Form.Group className="mb-4">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter text here"
              className="submit_form_control"
              name="title"
              value={formData.title}
              onChange={handleChange}
              readOnly={isReadOnly}
            />
          </Form.Group>
          <Row>
            {/* <Col className="custom_col_submit mb-4">
                <Form.Label>Time of Release</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter text here"
                  className="submit_form_control"
                />
              </Col> */}
            <Col className="custom_col_submit mb-4">
              <Form.Label>Package</Form.Label>
              <Form.Select
                className="submit_form_select"
                name="packageId"
                value={formData.packageId}
                onChange={handleChange}
                disabled={isReadOnly}
              >
                <option value="">Select from dropdown</option>
                {packageList.map((list) => (
                  <option key={list.packageId} value={list.packageId}>
                    {list.packageName}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Row>
          <Form.Group className="mb-4">
            <Form.Label>PR Body</Form.Label>
            <CKEditor
              editor={ClassicEditor}
              data={editorData}
              onChange={(event, editor) => {
                setEditorData(editor.getData());
              }}
              config={{
                extraPlugins: [MyCustomUploadAdapterPlugin],
              }}
              disabled={isReadOnly}
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>Insert Image</Form.Label>
            <button
              className="pr_form_next_btn m-2"
              onClick={handleAddNewClick}
            >
              Add New
            </button>
            <Select
              isMulti
              options={getOptions(imagesList)}
              onChange={handleImageSelectionChange}
              value={getOptions(imagesList).filter((option) =>
                selectedImages.includes(option.value)
              )}
            />
            {/* <Form.Select
              className="submit_form_select"
              name="image"
              value={formData.image}
              onChange={handleChange}
              disabled={isReadOnly}
            >
              <option value="">Select Gallery</option>
              {imagesList.map((img, index) => (
                <option value={img.galleryId}>{img.name}</option>
              ))}
            </Form.Select> */}
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </Form.Group>
          {formData.image && (
            <Row className="mb-4 mx-1">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Image Name</th>
                    <th>Image</th>
                    <th>URL</th>
                    <th>Size</th>
                    <th>Copy</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedImagesData.map((detail, index) => (
                    <tr key={index}>
                      <td>{detail.name}</td>
                      <td>
                        <img
                          src={detail.documentUrl}
                          alt={detail.name}
                          width={100}
                        />
                      </td>
                      <td>{detail.documentUrl}</td>
                      <td>{detail.size}</td>
                      <td>
                        <button
                          className="pr_form_next_btn"
                          onClick={(e) => {
                            e.preventDefault();
                            copyURL(detail.documentUrl);
                          }}
                        >
                          Copy
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Row>
          )}
          <Form.Group className="mb-4">
            <Form.Label>Select Company</Form.Label>
            <Form.Select
              className="submit_form_select"
              name="company"
              value={formData.companyId}
              onChange={handleCompanyIdChange}
              disabled={isReadOnly}
            >
              <option value="">Select Company</option>
              {companyList.map((company) => (
                <option key={company.companyId} value={company.companyId}>
                  {company.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <h4 className="pr_or">OR</h4>
          <Row>
            <Col className="custom_col_submit mb-4">
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                placeholder=" Enter Company Name"
                className="submit_form_control"
                name="name"
                value={company.name}
                onChange={handleCompanyChange}
                disabled={!!companyId}
                readOnly={isReadOnly}
              />
            </Col>
            <Col className="custom_col_submit mb-4">
              <Form.Label>Contact Person Name</Form.Label>
              <Form.Control
                placeholder="Enter Person Name"
                className="submit_form_control"
                name="contactPerson"
                value={company.contactPerson}
                onChange={handleCompanyChange}
                disabled={!!companyId}
                readOnly={isReadOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col className="custom_col_submit mb-4">
              <Form.Label>Email</Form.Label>
              <Form.Control
                placeholder="Enter Email"
                className="submit_form_control"
                name="email"
                value={company.email}
                onChange={handleCompanyChange}
                disabled={!!companyId}
                readOnly={isReadOnly}
              />
            </Col>
            <Col className="custom_col_submit mb-4">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                placeholder="Enter Phone Number"
                className="submit_form_control"
                name="mobile"
                value={company.mobile}
                onChange={handleCompanyChange}
                disabled={!!companyId}
                readOnly={isReadOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col className="custom_col_submit mb-4">
              <Form.Label>Company Website</Form.Label>
              <Form.Control
                placeholder="Enter Website"
                className="submit_form_control"
                name="website"
                value={company.website}
                onChange={handleCompanyChange}
                disabled={!!companyId}
                readOnly={isReadOnly}
              />
            </Col>
            <Col className="custom_col_submit mb-4">
              <Form.Label>Company Address</Form.Label>
              <Form.Control
                placeholder="Enter Address"
                className="submit_form_control"
                name="address"
                value={company.address}
                onChange={handleCompanyChange}
                disabled={!!companyId}
                readOnly={isReadOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col xs="3" className="custom_col_submit mb-4">
              <Form.Label>City</Form.Label>
              <Form.Control
                placeholder="Enter City"
                className="submit_form_control"
                name="city"
                value={company.city}
                onChange={handleCompanyChange}
                disabled={!!companyId}
                readOnly={isReadOnly}
              />
            </Col>
            <Col xs="3" className="custom_col_submit mb-4">
              <Form.Label>State</Form.Label>
              <Form.Control
                placeholder="Enter State"
                className="submit_form_control"
                name="state"
                value={company.state}
                onChange={handleCompanyChange}
                disabled={!!companyId}
                readOnly={isReadOnly}
              />
            </Col>
            <Col xs="6" className="custom_col_submit mb-4">
              <Form.Label>Country</Form.Label>
              <Form.Select
                className="submit_form_select"
                name="country"
                value={company.country}
                onChange={handleCompanyChange}
                disabled={!!companyId || isReadOnly}
              >
                <option value="">Select from dropdown</option>
                {countryList.map((country) => (
                  <option key={country.countryId} value={country.countryCode}>
                    {country.countryName}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Row>
          <Form.Group className="mb-4">
            <Form.Label>Select Category</Form.Label>
            <Form.Select
              className="submit_form_select"
              name="categoryId"
              value={formData.categoryId}
              onChange={handleChange}
              disabled={isReadOnly}
            >
              <option value="">Select from dropdown</option>
              {categoryList.map((list) => (
                <option key={list.categoryId} value={list.categoryId}>
                  {list.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Row>
            <Col xs="6">
              {" "}
              <Form.Group className="mb-4">
                <Form.Label>Write note for editor ?</Form.Label>
                <Form.Control
                  className="submit_form_select"
                  name="addNote"
                  placeholder="Enter note for editor"
                  value={formData.addNote}
                  onChange={handleChange}
                  disabled={isReadOnly}
                  as="textarea"
                  rows={4}
                />
              </Form.Group>
            </Col>
          </Row>
          {/* <p className="pr_form_p">
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
              sint. Velit officia consequat duis enim velit mollit. Exercitation
              veniam consequat sunt nostrud amet.
            </p> */}
        </Form>
      </div>
      <div className="pr_form_btns">
        {navshow === 0 && (
          <>
            <button
              className="pr_form_save_btn"
              onClick={(e) => {
                handleSubmit(e, "DRAFT");
              }}
            >
              Save as Draft
            </button>
            <button className="pr_form_next_btn" onClick={handleNavShow}>
              Next
            </button>
          </>
        )}
        {navshow === 1 && (
          <>
            <button
              className="pr_form_save_btn"
              onClick={(e) => {
                handleSubmit(e, "DRAFT");
              }}
            >
              Save as Draft
            </button>
            <button
              className="pr_form_submit_btn"
              onClick={(e) => {
                if (formData.addNote && formData.addNote.trim() !== "") {
                  handleSubmit(e, "OPEN");
                } else {
                  handleSubmit(e, "PENDING");
                }
              }}
            >
              Submit PR
            </button>
          </>
        )}
      </div>
    </>
  );
}
