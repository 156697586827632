import { Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import "./NewsroomPage.css";
import Pagination from "react-bootstrap/Pagination";
import Instance from "../../Utils/Axios";
import error from "../../Utils/Icons/error";

const NewsRoomPage = () => {
  const [newsroomList, setNewsroomList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  useEffect(() => {
    const token = localStorage.getItem("tokenId");
    const newsroomData = () => {
      Instance.post("/site/newsroom", {
      }).then((response) => {
        setNewsroomList(response?.data?.message);
      })
        .catch((error) => {
          console.error("Error fetching the news room data", error);
        })
    }
    newsroomData();
  }, [])

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const indexOfLastNews = currentPage * rowsPerPage;
  const indexOfFirstNews = indexOfLastNews - rowsPerPage;
  const currentNews = Array.isArray(newsroomList)
    ? newsroomList.slice(indexOfFirstNews, indexOfLastNews)
    : [];

  const totalPages = Math.ceil((newsroomList?.length || 0) / rowsPerPage);

  return (
    <>
      <div className="newsroom_layout">
        <div className="newsroom_details">
          <h4 className="newsroom_title">Newsroom</h4>
          <div className="newsroom_news">
            {currentNews.length === 0 ? (
              <h5>No News Available</h5>
            ) : (
              currentNews.map((news, index) => (
                <div className="news_info" key={index}>
                  <h5>{news.title}</h5>
                  <p>{news.createdOn}</p>
                </div>
              ))
            )}
          </div>
          <Pagination style={{ marginTop: "24px" }}>
            <Pagination.First onClick={() => handlePageChange(1)} />
            <Pagination.Prev onClick={() =>
              handlePageChange(Math.max(1, currentPage - 1))
            } />
            {[...Array(totalPages).keys()].map((page) => (
              <Pagination.Item
                key={page + 1}
                active={page + 1 === currentPage}
                onClick={() => handlePageChange(page + 1)}
              >
                {page + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next onClick={() =>
              handlePageChange(
                Math.min(totalPages, currentPage + 1)
              )
            } />
            <Pagination.Last onClick={() => handlePageChange(totalPages)} />
          </Pagination>
        </div>
        <div className="pr_details">
          <h4 className="newsroom_title">Top PR</h4>
          <div className="newsroom_pr">
            <div className="pr_info">
              <h5>Real Estate in Turkey</h5>
              <p>
                Blockchain-based social network BSocial arrives with a gathering
                of distinctive features.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsRoomPage;