import Sidebar from "../../components/SidebarNew/Sidebar";
import Navbar from "../../components/NavbarProfile/NavbarProfile";
import SubAccountEdit from "../../components/SubAccountLayout/SubAccountEdit";
import "../SubAccount/SubAccount.css";

const SubAccountUpdate = () => {
  const activeSide = 3;
  return (
    <>
      <div className="row gx-0 sub_account_layout">
        <Sidebar activeSide={activeSide} />
        <div className={`main col`}>
          <Navbar name={"Sub-Account"} />
          <SubAccountEdit />
        </div>
      </div>
    </>
  );
}

export default SubAccountUpdate;