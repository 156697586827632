"use client";
import "../../Pages/Login/Login.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Instance from "../../Utils/Axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Header() {
  const [headerData, setHeaderData] = useState(null);
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    Instance.get("/site/header")
      .then((response) => {
        setHeaderData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setError("Error fetching header data");
        setLoading(false);
        console.error("Error fetching header data:", error);
      });
  }, []);

  // Fetch profile data if the token exists
  useEffect(() => {
    const token = localStorage.getItem("tokenId");
    if (token) {
      Instance.post("/user/profile", { tokenId: token })
        .then((response) => {
          setProfile(response.data.userProfile); // Store the user profile
        })
        .catch((error) => {
          console.error("Error fetching profile data", error);
        });
    }
  }, []);

  if (error) return <div style={{ margin: "auto" }}>{error}</div>;

  if (!headerData || !headerData.logo) {
    return <div>No logo found</div>;
  }

  const renderNavItems = () => {
    return headerData.module.map((item, index) => {
      // Check for "Sign In" and "Sign Up"
      if (item.label === "SIGN UP" || item.label === "SIGN IN") {
        if (!profile) {
          return (
            <Link
              key={index}
              to={item.rUrl}
              className={item.label === "SIGN IN" ? "fill_btn" : "outline_btn"}
            >
              {item.label}
            </Link>
          );
        } else {
          return null;
        }
      } else if (item.suboptions && item.suboptions.length > 0) {
        return (
          <NavDropdown
            key={index}
            title={item.label}
            id={`nav-dropdown-${index}`}
          >
            {item.suboptions.map((subitem, subindex) => (
              <NavDropdown.Item key={subindex} href={subitem.rUrl}>
                {subitem.label}
              </NavDropdown.Item>
            ))}
          </NavDropdown>
        );
      } else {
        return (
          <Nav.Link key={index} href={item.rUrl}>
            {item.label}
          </Nav.Link>
        );
      }
    });
  };

  return (
    <>
      <Navbar className="header_nav">
        <Container className="header_container">
          <div className="my-2">
            <img
              className="logo"
              src={"/build/Logo.svg"}
              alt="zex pr wire Logo"
              width={147}
              height={67}
              onClick={() => {
                navigate("/");
              }}
            />
          </div>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto nav_items_container_login">
              {renderNavItems()}
              {profile && (
                <Link to="/customer/profile" className="user-name">
                  {profile.firstName} {profile.lastName}
                </Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <ToastContainer theme="colored" />
    </>
  );
}
