import "./NotificationLayout.css";
import { useState, useEffect } from "react";
import Instance from "../../Utils/Axios";
import { formatDistanceToNow } from "date-fns";
import parse from "html-react-parser";

export default function NotificationLayout() {
  const [activeTab, setActiveTab] = useState(1);
  const [notificationData, setNotificationData] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchDashboardData = () => {
      const token = localStorage.getItem("tokenId");
      Instance.post("/user/dashboard", {
        tokenId: token,
      })
        .then((response) => {
          setNotificationData(response.data.message.pressRelease.notification);
          setError("");
        })
        .catch((error) => {
          setError("Cannot fetch the data");
          console.error("Cannot fetch the dashboard data", error);
        });
    };
    fetchDashboardData();
  }, []);

  const timeAgo = (dateString) => {
    const date = new Date(dateString);
    return formatDistanceToNow(date, { addSuffix: true });
  };
  return (
    <>
      <div className="notification_layout">
        <div className="notification_tabs">
          <h4
            className={`all_tab ${activeTab === 1 ? "tab_active" : ""}`}
            onClick={() => setActiveTab(1)}
            style={{ cursor: "pointer" }}
          >
            All
          </h4>
          <h4
            className={`read_tab ${activeTab === 2 ? "tab_active" : ""}`}
            onClick={() => setActiveTab(2)}
            style={{ cursor: "pointer" }}
          >
            Read
          </h4>
          <h4
            className={`unread_tab ${activeTab === 3 ? "tab_active" : ""}`}
            onClick={() => setActiveTab(3)}
            style={{ cursor: "pointer" }}
          >
            Unread
          </h4>
        </div>
        <div>
          <div className="notifications_list">
            {notificationData.length > 0 ? (
              notificationData
                .slice()
                .reverse()
                .map((data, index) => (
                  <div key={index} className="notification">
                    <div
                      className={
                        data.status === "REJECTED"
                          ? "status_rejected"
                          : data.status === "PENDING"
                            ? "status_pending"
                            : data.status === "PUBLISHED"
                              ? "status_published"
                              : data.status === "UPDATED"
                                ? "status_updated"
                                : data.status === "OPEN"
                                  ? "status_action"
                                  : data.status === "DELETED"
                                    ? "status_deleted"
                                    : "status_updated"
                      }
                    ></div>
                    <div className="content">
                      <h3>
                        PR ID: {data.id} - PR {data.status}
                      </h3>
                      <p>{parse(data.description)}</p>
                      <span>{timeAgo(data.updatedOn)}</span>
                    </div>
                  </div>
                ))
            ) : (
              <div className="d-flex gap-2">
                <div
                  className="color_box"
                  style={{ backgroundColor: "#595FDE" }}
                ></div>
                <div className="notification_actions_info">
                  <p className="notification_action">No Notifications yet</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
