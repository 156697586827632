import "./ContactLayout.css";
import { useState } from "react";
import Instance from "../../Utils/Axios";
import { showToast } from "../../Utils/index";

export default function ContactLayout() {
  const [contactForm, setContactForm] = useState({
    name: "",
    email: "",
    website: "",
    phone: "",
    requirement: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const token = localStorage.getItem("tokenId");
    Instance.post("/user/enquiry/create", {
      tokenId: token,
      name: contactForm.name,
      email: contactForm.email,
      website: contactForm.website,
      phone: contactForm.phone,
      requirement: contactForm.requirement,
    })
      .then((response) => {
        if (response.data.resCode === "200") {
          showToast("Your Details are Submitted !", "success", "success");
          setContactForm({
            name: "",
            email: "",
            website: "",
            phone: "",
            requirement: "",
          });
        } else {
          showToast(response.data.resMessage, "error", "error");
        }
      })
      .catch((error) => {
        console.error("Error in contact form", error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContactForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };
  return (
    <>
      <div className="contact_layout">
        <div className="contact_content">
          <div className="contact_text">
            <p className="contact_heading">
              Drop by anytime, we endeavor to answer all inquiries within 24
              hours on business days.
            </p>
            <p className="contact_heading_2">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo
              consectetur felis fermentum eget turpis quis.
            </p>
          </div>
          <div className="contact_card">
            <p className="card_text_1">Contact & Reach Us!</p>
            <p className="card_text_2">We are excited to work with you!</p>
            <form className="contact_form" onSubmit={handleSubmit}>
              <input
                type="text"
                placeholder="Your Name"
                name="name"
                value={contactForm.name}
                onChange={handleChange}
                required
              />
              <input
                type="email"
                placeholder="Your Email"
                name="email"
                value={contactForm.email}
                onChange={handleChange}
                required
              />
              <input
                type="number"
                placeholder="Your Phone Number"
                name="phone"
                value={contactForm.phone}
                onChange={handleChange}
                required
              />
              <input
                type="text"
                placeholder="Website"
                name="website"
                value={contactForm.website}
                onChange={handleChange}
                required
              />
              <input
                type="text"
                placeholder="Your Requirements"
                name="requirement"
                value={contactForm.requirement}
                onChange={handleChange}
                required
              />
              <button className="contact_form_btn" type="submit">
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
