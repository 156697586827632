import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "../PRmanagerLayout/PRmanager.css";
import "./SubAccount.css";
import { useState, useEffect } from "react";
import Instance from "../../Utils/Axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { showToast } from "../../Utils/index";

const SubAccountEdit = () => {
  const { subID } = useParams();
  const navigate = useNavigate();
  const [companyDetail, setCompanyDetail] = useState({
    country: "",
    website: "",
    createdBy: "",
    status: "",
    name: "",
    mobile: "",
    state: "",
    contactPerson: "",
    email: "",
    city: "",
    address: "",
    createdOn: "",
  });
  const [error, setError] = useState("");
  const [countryList, setCountryList] = useState([]);

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        const token = localStorage.getItem("tokenId");
        const response = await Instance.post("/user/usercompany/details", {
          tokenId: token,
          companyId: subID,
        });
        console.log("Response from getStaff API:", response.data);
        setCompanyDetail({
          country: response.data.message.country,
          website: response.data.message.website,
          createdBy: response.data.message.createdBy,
          status: response.data.message.status,
          name: response.data.message.name,
          mobile: response.data.message.mobile,
          state: response.data.message.state,
          contactPerson: response.data.message.contactPerson,
          email: response.data.message.email,
          city: response.data.message.city,
          address: response.data.message.address,
          createdOn: response.data.message.createdOn,
        });
        setError("");
      } catch (error) {
        setError("Failed to fetch company details");
        console.error("Failed to fetch company detail", error);
      }
    };

    fetchCompanyDetails();
  }, [subID]);

  const handleChange = (e) => {
    setCompanyDetail({
      ...companyDetail,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("tokenId");
      const response = await Instance.post(`/customer/usercompany/update`, {
        tokenId: token,
        companyId: subID,
        country: companyDetail.country,
        website: companyDetail.website,
        createdBy: companyDetail.createdBy,
        status: companyDetail.status,
        name: companyDetail.name,
        mobile: companyDetail.mobile,
        state: companyDetail.state,
        contactPerson: companyDetail.contactPerson,
        email: companyDetail.email,
        city: companyDetail.city,
        address: companyDetail.address,
        createdOn: companyDetail.createdOn,
      });
      console.log("Response from updateStaff API:", response.data);
      if (response.data.resCode === "200") {
        showToast("Updated Succesfully!", "success", "success");
        setTimeout(() => {
          navigate("/customer/sub-account");
        }, 1000);
      } else {
        setError(response.data.resMessage);
      }
    } catch (error) {
      setError("Error updating the company details");
      console.error("Error updating the company details:", error);
    }
  };
  const fetchCountryList = () => {
    const token = localStorage.getItem("tokenId");
    Instance.post("/user/common/countryList", {
      tokenId: token,
      status: "",
    })
      .then((response) => {
        if (response.data.resCode === "200") {
          const filteredCountries = response.data.message.filter(
            (country) => country.countryName.trim() !== ""
          );
          setCountryList(filteredCountries);
          setError("");
        } else {
          setError("Failed to fetch countries list");
          console.error(
            "Error fetching countries list:",
            response.data.resMessage
          );
        }
      })
      .catch((error) => {
        setError("Failed to fetch countries list");
        console.error("Error fetching countries list:", error);
      });
  };

  useEffect(() => {
    fetchCountryList();
  }, []);

  return (
    <>
      <div className={`pr_secondary_nav`}>
        <Link to="/customer/sub-account">
          <button className="sub_back_btn">
            <img
              className={"sub_back_img mb-0"}
              src="/build/img/Icons/Chevron.svg"
              alt="zex pr wire Logo"
              width={20}
              height={20}
              priority
            />
            Back
          </button>
        </Link>
      </div>
      <Form onSubmit={handleSubmit}>
        <div className="pr_form_preview">
          <h4 className="sub_form_head mb-4">Edit company profile</h4>

          <Row>
            <Col className="custom_col_sub_account mb-4">
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                placeholder=" Enter text here"
                name="name"
                value={companyDetail.name}
                onChange={handleChange}
              />
            </Col>
            <Col className="custom_col_sub_account mb-4">
              <Form.Label>Contact Person Name</Form.Label>
              <Form.Control
                placeholder="Enter text here"
                name="contactPerson"
                value={companyDetail.contactPerson}
                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row>
            <Col className="custom_col_sub_account mb-4">
              <Form.Label>Email</Form.Label>
              <Form.Control
                placeholder="Enter text here"
                type="email"
                name="email"
                value={companyDetail.email}
                onChange={handleChange}
              />
            </Col>
            <Col className="custom_col_sub_account mb-4">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                placeholder="Enter text here"
                type="number"
                name="mobile"
                value={companyDetail.mobile}
                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row>
            <Col className="custom_col_sub_account mb-4">
              <Form.Label>Company Website</Form.Label>
              <Form.Control
                placeholder="Enter text here"
                type="text"
                name="website"
                value={companyDetail.website}
                onChange={handleChange}
              />
            </Col>
            <Col className="custom_col_sub_account mb-4">
              <Form.Label>Created By</Form.Label>
              <Form.Control
                placeholder="Enter text here"
                type="text"
                name="createdBy"
                value={companyDetail.createdBy}
                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row>
            <Col className="custom_col_sub_account mb-4">
              <Form.Label>Status</Form.Label>
              <Form.Select
                name="status"
                value={companyDetail.status}
                onChange={handleChange}
              >
                <option value="ACTIVE">Active</option>
                <option value="INACTIVE">Inactive</option>
              </Form.Select>
            </Col>
            <Col className="custom_col_sub_account mb-4">
              <Form.Label>Company Address</Form.Label>
              <Form.Control
                placeholder="Enter text here"
                type="text"
                name="address"
                value={companyDetail.address}
                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row>
            <Col xs="3" className="custom_col_sub_account mb-4">
              <Form.Label>City</Form.Label>
              <Form.Control
                placeholder="Enter text here"
                name="city"
                value={companyDetail.city}
                onChange={handleChange}
              />
            </Col>
            <Col xs="3" className="custom_col_sub_account mb-4">
              <Form.Label>State</Form.Label>
              <Form.Control
                placeholder="Enter text here"
                name="state"
                value={companyDetail.state}
                onChange={handleChange}
              />
            </Col>
            <Col xs="6" className="custom_col_sub_account mb-4">
              <Form.Label>Country</Form.Label>
              <Form.Select
                name="country"
                value={companyDetail.country}
                onChange={handleChange}
              >
                <option value="">Select from dropdown</option>
                {countryList.map((country) => (
                  <option key={country.countryId} value={country.countryCode}>
                    {country.countryName}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Row>
          {/* <p className="pr_form_p">
            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
            sint. Velit officia consequat duis enim velit mollit. Exercitation
            veniam consequat sunt nostrud amet.
          </p> */}

          {/* {error && <p className="text-danger">{error}</p>}
          {successMessage && <p className="text-success">{successMessage}</p>} */}
        </div>
        <div className="sub_account_buttons">
          <button className="sub_cancel_btn mb-4" type="button">
            Cancel
          </button>
          <button className="sub_save_btn mb-4" type="submit">
            Save Changes
          </button>
        </div>
      </Form>
    </>
  );
};

export default SubAccountEdit;
