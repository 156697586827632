import { useState } from "react";
import "./GalleryLayout.css";

export default function GridView({
  setSelectedPhoto,
  updateSelectedCheckboxes,
  images,
  checkedState,
  onPhotoClick,
  onSelectionCountChange,
}) {
  const handleDivClick = (index) => {
    const clickedGalleryId = images[index].galleryId;

    // Uncheck all checkboxes and only check the clicked one
    const updatedCheckedState = checkedState.map((checked, i) => 
      images[i].galleryId === clickedGalleryId ? !checked : false
    );
    updateSelectedCheckboxes(updatedCheckedState);

    const selectedImages = updatedCheckedState.filter((isChecked) => isChecked);
    onSelectionCountChange(selectedImages.length);
    if (selectedImages.length === 1) {
      setSelectedPhoto(images[index].documentUrl);
      onPhotoClick(images[index].galleryId);
    } else if (selectedImages.length > 1) {
      setSelectedPhoto(images[index].documentUrl);
    } else {
      setSelectedPhoto("");
    }
  };

  return (
    <>
      <div className="grid_view_layout">
        {images.map((image, index) => (
          <div
            key={image.galleryId}
            className="grid_images"
            onClick={() => handleDivClick(index)}
          >
            <img
              className="mb-0"
              src={image.documentUrl}
              alt={image.name}
              width={200}
              height={200}
              priority
            />
            <input type="checkbox" checked={checkedState[index]} readOnly />
          </div>
        ))}
      </div>
    </>
  );
}
