import Sidebar from "../../components/SidebarNew/Sidebar";
import Navbar from "../../components/NavbarProfile/NavbarProfile";
import AffiliateCenterLayout from "../../components/AffiliateCenter/AffiliateCenter";
import Welcome from "../../components/AffiliateCenter/Welcome";
import FirstLogin from "../../components/AffiliateCenter/Login";
import AffiliateMember from "../../components/AffiliateCenter/AffiliateMember";
import "./AffiliateCenter.css";
import { useState } from "react";

export default function AffiliateCenter() {
  const [showWelcome, setShowWelcome] = useState(true);
  const [showLogin, setShowLogin] = useState(true);

  const handleClickFirst = () => {
    setShowWelcome(false);
  };

  const handleClickMember = () => {
    setShowLogin(false);
  };
  const activeSide = 7;
  return (
    <>
      <div className="row gx-0 affiliate_center">
        <Sidebar activeSide={activeSide} />
        <div className={`main col`}>
          <Navbar name={"Affiliate Center"} />
          {showWelcome ? (
            <Welcome onshowFirst={handleClickFirst} />
          ) : showLogin ? (
            <FirstLogin onshowMember={handleClickMember} />
          ) : (
            <AffiliateMember />
          )}
        </div>
      </div>
    </>
  );
}
