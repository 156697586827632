"use client";
import { Table, Pagination, Dropdown, Modal, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import "./PRmanager.css";
import Cards from "./Cards";
import { Link } from "react-router-dom";
import Instance from "../../Utils/Axios";
import PRtable from "./PRtable";

export default function Pending() {
  const [prPending, setPrPending] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchPRList = () => {
      const token = localStorage.getItem("tokenId");
      Instance.post("/user/pressrelease/list", {
        tokenId: token,
        status: "PENDING",
      })
        .then((response) => {
          const data = response.data?.message || [];
          setPrPending(Array.isArray(data) ? data : []);
          setError("");
          console.log("check stats", response.data?.message);
        })
        .catch((error) => {
          setError("Failed to fetch the details");
          console.error("failed to fetch card details", error);
        });
    };
    fetchPRList();
  }, []);

  return (
    <>
      <Cards />
      <div className="my_press">
        <h4 className="my_press_text">My Press Releases</h4>
        <div className="my_press_btns">
          <Link to="/customer/pr-manager">
            <button className="my_view_btn">View all</button>
          </Link>
          <Link to="/customer/submit-pr">
            <button className="my_add_btn">
              <img
                className={"pr_add_img mb-0"}
                src="/build/img/Icons/Vector.png"
                alt="zex pr wire Logo"
                width={13}
                height={13}
                priority
              />
              Add new
            </button>
          </Link>
        </div>
      </div>
      <PRtable prStatus={prPending} />
    </>
  );
}
