import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useState } from "react";
import "./AffiliateCenter.css";

export default function FirstLogin({ onshowMember }) {
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);

  const handleCheckbox1Change = (event) => {
    setIsChecked1(event.target.checked);
  };

  const handleCheckbox2Change = (event) => {
    setIsChecked2(event.target.checked);
  };
  return (
    <>
      <div className="ac_login_layout">
        <div className="ac_login_title">Become an Affiliate Member</div>
        <div>
          <Row>
            <Col className="custom_col_ac mb-4">
              <Form.Label>First Name</Form.Label>
              <Form.Control placeholder=" Enter text here" />
            </Col>
            <Col className="custom_col_ac mb-4">
              <Form.Label>Last Name</Form.Label>
              <Form.Control placeholder=" Enter text here" />
            </Col>
          </Row>
          <Row>
            <Col className="custom_col_ac mb-4">
              <Form.Label>Email id</Form.Label>
              <Form.Control placeholder=" Enter text here" />
            </Col>
            <Col className="custom_col_ac mb-4">
              <Form.Label>Phone</Form.Label>
              <Form.Control placeholder=" Enter text here" />
            </Col>
          </Row>
          <Row>
            <Col className="custom_col_ac mb-4">
              <Form.Label>Address</Form.Label>
              <Form.Control placeholder=" Enter text here" />
              <span>
                (Kindly Provide Address as per your legal document only, so as
                to verify your identity while payment withdrawal.)
              </span>
            </Col>
          </Row>
          <Row>
            <Col xs="3" className="custom_col_ac mb-4">
              <Form.Label>City</Form.Label>
              <Form.Control placeholder="Enter text here" />
            </Col>
            <Col xs="3" className="custom_col_ac mb-4">
              <Form.Label>State</Form.Label>
              <Form.Control placeholder="Enter text here" />
            </Col>
            <Col xs="6" className="custom_col_ac mb-4">
              <Form.Label>Country</Form.Label>
              <Form.Control placeholder="Enter text here" />
            </Col>
          </Row>
        </div>
        <div className="ac_login_inputs">
          <div className="ac_login_input_1">
            <input
              type="checkbox"
              checked={isChecked1}
              onChange={handleCheckbox1Change}
              className="affiliate_checkbox_design"
            />
            <span>
              In order to complete my withdrawal, I have to complete my KYC to
              place the request for withdrawl.
            </span>
          </div>
          <div className="ac_login_input_2">
            <input
              type="checkbox"
              checked={isChecked2}
              onChange={handleCheckbox2Change}
              className="affiliate_checkbox_design"
            />
            <span>
              I agree that all the above information provided is true.
            </span>
          </div>
        </div>
        <div>
          Disclaimer: Amet minim mollit non deserunt ullamco est sit aliqua
          dolor do amet sint. Velit officia consequat duis enim velit mollit.
          Exercitation veniam consequat sunt nostrud amet.
        </div>
        <div className="mt-4">
          <button
            className="ac_next_btn"
            disabled={!isChecked1 || !isChecked2}
            onClick={onshowMember}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
}
