"use client";
import "./Dashboard.css";
import { Dropdown, Form, Pagination, Table } from "react-bootstrap";
import Sidebar from "../SidebarNew/Sidebar";
import Navbar from "../NavbarProfile/NavbarProfile";
import Fillbutton from "../FillButtonNew/Fillbutton";
import { Arrowsvg } from "../../../public/svgs/svg";
import { useState } from "react";

export default function Dashboard1() {
  const [showResMenu, setShowResMenu] = useState(false);
  const activeSide = 1;
  return (
    <div className="dashboard_container row gx-0">
      <Sidebar activeSide={activeSide} />
      <div className={`main col`}>
        <Navbar name={"Dashboard"} />

        <navbar className={`dash_navbar`}>
          <div className={`title`}>Dashboard</div>
          <div className={` nav_btn_container`}>
            <div className={` d-none d-lg-flex nav_btns`}>
              <div className={`search_btn`}>
                <img
                  className={""}
                  src="/build/img/search.svg"
                  alt="zex pr wire Logo"
                  width={24}
                  height={24}
                  priority
                />
                <input placeholder="search here" />
              </div>
              <button className={`outline_btn`}>Buy Credits</button>
              <a href="/submit" className={`fill_btn`}>
                Submit PR
              </a>
            </div>

            <div className={`nav_profile `}>
              <div className="resposive_search d-lg-none d-flex align-item-center">
                <img
                  className={""}
                  src="/build/img/search.svg"
                  alt="zex pr wire Logo"
                  width={24}
                  height={24}
                  priority
                />
              </div>
              <div>
                <img
                  className={""}
                  src="/build/img/notification.svg"
                  alt="zex pr wire Logo"
                  width={18}
                  height={21}
                  priority
                />
              </div>
              <div>
                <div className="d-none d-lg-flex gap-2 align-items-center">
                  <a href="/customer/profile">
                    <img
                      className={""}
                      src="/build/img/profile_img.svg"
                      alt="zex pr wire Logo"
                      width={50}
                      height={50}
                      priority
                    />
                  </a>
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                      <img
                        className={""}
                        src="/build/img/downarrow.svg"
                        alt="zex pr wire Logo"
                        width={24}
                        height={24}
                        priority
                      />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">
                        <img
                          className={"me-2"}
                          src="/build/img/setting.svg"
                          alt="zex pr wire Logo"
                          width={24}
                          height={24}
                          priority
                        />
                        Settings
                      </Dropdown.Item>
                      <Dropdown.Item href="/">
                        <img
                          className={"me-2"}
                          src="/build/img/log_out.svg"
                          alt="zex pr wire Logo"
                          width={24}
                          height={24}
                          priority
                        />
                        Logout
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div
                  className=" d-lg-none nav-responsive "
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowResMenu(true)}
                >
                  <img
                    className={""}
                    src="/build/img/hamburger.svg"
                    alt="hamburger"
                    width={30}
                    height={30}
                    priority
                  />
                </div>
              </div>
            </div>
            <div className={` ${showResMenu ? "responsive_menu" : "d-none"} `}>
              <div className=" res-profile bg-white d-flex justify-content-between align-items-center mb-2  ">
                <img
                  className={""}
                  src="/build/img/profile_img.svg"
                  alt="zex pr wire Logo"
                  width={50}
                  height={50}
                  priority
                />
                <img
                  className={"pointer"}
                  style={{ cursor: "pointer" }}
                  src="/build/img/close-menu.svg"
                  alt="hamburger"
                  width={30}
                  height={30}
                  onClick={() => setShowResMenu(false)}
                  priority
                />
              </div>
              <div className="menu_item p-3 ">Buy Credits </div>
              <div className="menu_item p-3 ">
                <a href="/submit">Submit PR</a>
              </div>
              <div className="menu_item p-3 ">Settings</div>
              <div className="menu_item p-3 ">
                <a href="/">Logout</a>
              </div>
            </div>
          </div>
        </navbar>

        <div className={`row gx-0 main_container`}>
          <div className={` left_main col-12 col-md-8`}>
            <div className="package_info">
              <div className="articles_info">
                <img
                  className={"d_article_img mb-0"}
                  src="/build/img/Newspaper.svg"
                  alt="zex pr wire Logo"
                  width={48}
                  height={48}
                  priority
                />
                <p className="d_article_num ">0</p>
                <p className="d_article_text mb-0">Articles Published</p>
              </div>
              <div className="package_owned">
                <img
                  className={"d_article_img mb-0"}
                  src="/build/img/Package.svg"
                  alt="zex pr wire Logo"
                  width={48}
                  height={48}
                  priority
                />
                <p className="d_article_num ">0</p>
                <p className="d_article_text mb-0">Packages Owned</p>
              </div>
              <div className="pr_status_container">
                {false ? (
                  <>
                    <div className="dash_expire_info ">
                      <div className="dash_expire_child">
                        <p className="dash_expire_title mb-0">
                          package expiring on
                        </p>
                        <p className="dash_expire_date mb-0">12 Mar 2024</p>
                        <p className="dash_expire_time mb-0">
                          About to expire in a 3 days
                        </p>
                      </div>
                      <div className="dash_expire_child">
                        <p className="dash_expire_title mb-0">
                          package expiring on
                        </p>
                        <p className="dash_expire_date mb-0">16 Mar 2024</p>
                        <p className="dash_expire_time mb-0">
                          About to expire in a week
                        </p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between pr_text_wrapper">
                      <p className="dash_prstatus_text mb-0">PR Status</p>
                      <div className="dash_viewall mb-0">View all</div>
                    </div>
                  </>
                ) : (
                  <div
                    className="d-flex justify-content-center align-items-center m-auto"
                    style={{ height: "100%" }}
                  >
                    <div className=" first_pack_btn_cont">
                      <p className="first_package_text">
                        Let’s buy your first package
                      </p>
                      <Fillbutton
                        text="Buy Now"
                        className="mx-auto first_pack_btn"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="package_details_text">
              <p className="dash_pack_text mb-0">Package Details</p>
              <p className="dash_pack_view mb-0">View all</p>
            </div>
            <div className="table-responsive">
              <Table className="table_container">
                <thead className="t_head_wrapper">
                  <tr className="t_head_row">
                    <th className="t_head_item">NAME</th>
                    <th className="t_head_item">PR LIMIT</th>
                    <th className="t_head_item">USER PR</th>
                    <th className="t_head_item">CREDITS LEFT</th>
                    <th className="t_head_item">EXPIRY</th>
                    <th className="t_head_item">BUY AGAIN</th>
                  </tr>
                </thead>
                {false ? (
                  <tbody>
                    <tr>
                      <td>Boomerang</td>
                      <td>11</td>
                      <td>11</td>
                      <td>0</td>
                      <td>Aug 27,2019</td>
                      <td>
                        <Fillbutton
                          text="Buy Now"
                          className="mx-auto small_btn"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Boomerang</td>
                      <td>11</td>
                      <td>11</td>
                      <td>0</td>
                      <td>Aug 27,2019</td>
                      <td>
                        <Fillbutton
                          text="Buy Now"
                          className="mx-auto small_btn"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Bloomberg</td>
                      <td>11</td>
                      <td>11</td>
                      <td>0</td>
                      <td>Aug 27,2019</td>
                      <td>
                        <Fillbutton
                          text="Buy Now"
                          className="mx-auto small_btn"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Bloomberg</td>
                      <td>11</td>
                      <td>11</td>
                      <td>0</td>
                      <td>Aug 27,2019</td>
                      <td>
                        <Fillbutton
                          text="Buy Now"
                          className="mx-auto small_btn"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Bloomberg</td>
                      <td>11</td>
                      <td>11</td>
                      <td>0</td>
                      <td>Aug 27,2019</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Bloomberg</td>
                      <td>11</td>
                      <td>11</td>
                      <td>0</td>
                      <td>Aug 27,2019</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Bloomberg</td>
                      <td>11</td>
                      <td>11</td>
                      <td>0</td>
                      <td>Aug 27,2019</td>
                      <td>
                        <Fillbutton
                          text="Buy Now"
                          className="mx-auto small_btn"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Bloomberg</td>
                      <td>11</td>
                      <td>11</td>
                      <td>0</td>
                      <td>Aug 27,2019</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Bloomberg</td>
                      <td>11</td>
                      <td>11</td>
                      <td>0</td>
                      <td>Aug 27,2019</td>
                      <td>
                        <Fillbutton
                          text="Buy Now"
                          className="mx-auto small_btn"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Bloomberg</td>
                      <td>11</td>
                      <td>11</td>
                      <td>0</td>
                      <td>Aug 27,2019</td>
                      <td>
                        <Fillbutton
                          text="Buy Now"
                          className="mx-auto small_btn"
                        />
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    <tr className=" ">
                      <td className="px-3 pt-3 buy_first_pack" colSpan={5}>
                        Buy your first PR package
                      </td>
                      <td>
                        <Fillbutton
                          text="Buy Now"
                          className="mx-auto small_btn"
                        />
                      </td>
                    </tr>
                  </tbody>
                )}
              </Table>
              <div className="table_footer">
                <div className="rows_per_page">
                  Rows per page
                  <Form.Select
                    aria-label="Default select example"
                    bsPrefix="dash_select"
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="1">4</option>
                    <option value="2">5</option>
                    <option value="3">6</option>
                    <option value="1">7</option>
                    <option value="2">8</option>
                    <option value="3">9</option>
                    <option selected>10</option>
                  </Form.Select>
                </div>
                <div className="rows_page">
                  <Pagination
                    className="mb-0 ps-0"
                    bsPrefix="custom_pagination"
                  >
                    <Pagination.Item>{1}</Pagination.Item>
                    <Pagination.Item>{2}</Pagination.Item>
                    <Pagination.Item className="d-none d-lg-block">
                      {3}
                    </Pagination.Item>
                    <Pagination.Ellipsis />

                    <Pagination.Item className="d-none d-lg-block">
                      {10}
                    </Pagination.Item>
                    <Pagination.Item>{11}</Pagination.Item>
                    <Pagination.Item>{12}</Pagination.Item>
                  </Pagination>
                </div>
                <div className="go_to_page">
                  <p className="mb-0">Go to page</p>
                  <input />
                  <Arrowsvg rotate="0deg" />
                </div>
              </div>
            </div>
          </div>
          <div className={`col-12  col-md-4 right_main`}>
            <p className="mb-0 right_main_title">My Last Pr</p>
            {false ? (
              <div className="lastpr_main_cont">
                <div className="d-flex gap-2">
                  <img
                    className={""}
                    src="/build/img/notify.svg"
                    alt="zex pr wire Logo"
                    width={48}
                    height={48}
                    priority
                  />
                  <div className="lastpr_info">
                    <p className="lastpr_text">
                      World Blockchain Summit Set the Stage for a Decentralized
                      Future with Ground-breaking Insights and Collaborations
                    </p>
                    <p className="lastpr_time mb-0">Aug 30, 2023, 4:00 PM</p>
                  </div>
                </div>
                <div className="d-flex gap-2">
                  <img
                    className={""}
                    src="/build/img/notify.svg"
                    alt="zex pr wire Logo"
                    width={48}
                    height={48}
                    priority
                  />
                  <div className="lastpr_info">
                    <p className="lastpr_text">
                      ZEX PR WIRE Join Forces with Blockchain Life to be the
                      Official PR Partner for the 10th Edition of Blockchain
                      Life 2023 in Dubai
                    </p>
                    <p className="lastpr_time mb-0 ">Aug 30, 2023, 4:00 PM</p>
                  </div>
                </div>
                <div className="d-flex gap-2">
                  <img
                    className={""}
                    src="/build/img/notify.svg"
                    alt="zex pr wire Logo"
                    width={48}
                    height={48}
                    priority
                  />
                  <div className="lastpr_info">
                    <p className="lastpr_text">
                      UAE’s Future Blockchain Summit to address all things
                      Blockchain in Dubai from tomorrow
                    </p>
                    <p className="lastpr_time mb-0">Aug 30, 2023, 4:00 PM</p>
                  </div>
                </div>
                <div className="d-flex gap-2">
                  <img
                    className={""}
                    src="/build/img/notify.svg"
                    alt="zex pr wire Logo"
                    width={48}
                    height={48}
                    priority
                  />
                  <div className="lastpr_info">
                    <p className="lastpr_text">
                      ZEX PR WIRE World Best PR NewsWire now joins Token2049 To
                      Exhibit at  Singapore Edition Sept 2023
                    </p>
                    <p className="lastpr_time mb-0">Aug 30, 2023, 4:00 PM</p>
                  </div>
                </div>
                <div className="d-flex gap-2">
                  <img
                    className={""}
                    src="/build/img/notify.svg"
                    alt="zex pr wire Logo"
                    width={48}
                    height={48}
                    priority
                  />
                  <div className="lastpr_info">
                    <p className="lastpr_text">
                      Middle East & North Africa’s Fintech community to gather
                      tomorrow at Fintech Surge 2022
                    </p>
                    <p className="lastpr_time mb-0">Aug 30, 2023, 4:00 PM</p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-center my-4">
                <div>
                  <p className="submit_first_pr">Let’s Submit your first PR</p>
                  <Fillbutton
                    text="Submit Now"
                    className="mx-auto first_pack_btn mb-4"
                  />
                </div>
              </div>
            )}
            <div className="notification_info">
              <div className=" notification_title">
                <p className="notification_text">Notification</p>
                <p className="notification_viewall">View all</p>
              </div>
              {false ? (
                <div>
                  <div className="notification_actions">
                    <div className="color_box"></div>
                    <div className="notification_actions_info">
                      <p className="notification_action">
                        PR ID: 7284 - Rejected
                      </p>
                      <p className="notification_action_desc">
                        Make some small changes to submit again
                      </p>
                      <p className="notification_action_time">1 Hours Ago</p>
                    </div>
                  </div>
                  <div className="notification_actions">
                    <div className="color_box"></div>
                    <div className="notification_actions_info">
                      <p className="notification_action">
                        PR ID: 7284 - Rejected
                      </p>
                      <p className="notification_action_desc">
                        Make some small changes to submit again
                      </p>
                      <p className="notification_action_time">1 Hours Ago</p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="d-flex gap-2">
                  <div
                    className="color_box"
                    style={{ backgroundColor: "#595FDE" }}
                  ></div>
                  <div className="notification_actions_info">
                    <p className="notification_action">No Notifications yet</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
