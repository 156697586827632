import Sidebar from "../../components/SidebarNew/Sidebar";
import Navbar from "../../components/NavbarProfile/NavbarProfile";
import GalleryLayout from "../../components/GalleryLayout/GalleryLayout";
import "./Gallery.css";

export default function Gallery() {
  const activeSide = 4;
  return (
    <>
      <div className="row gx-0 gallery_content">
        <Sidebar activeSide={activeSide} />
        <div className={`main col`}>
          <Navbar name={"Gallery"} />
          <GalleryLayout />
        </div>
      </div>
    </>
  );
}
