import "./Fillbutton.css";

const Fillbutton = ({ text = "", className = "", style = "" }) => {
  return (
    <button
      className={`common_fill_btn ${className}`}
      style={style ? style : {}}
    >
      {text}
    </button>
  );
};

export default Fillbutton;
