import "./AffiliateCenter.css";
import { Table, Pagination, Dropdown } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

function AffiliateCoupon() {
  return (
    <>
      <div className="affiliate_coupon_layout">
        <div className="affiliate_coupon_table">
          <div className="affiliate_coupon_options">
            <h5>Affiliate Codes</h5>
            <div className="affiliate_coupon_buttons">
              <div className="affilite_search_btn">
                <img
                  className="affiliate_search_img"
                  src="/build/search.svg"
                  alt="zex pr wire Logo"
                  width={24}
                  height={24}
                  priority
                />
                <input placeholder="Search"></input>
              </div>
              <button variant="primary" className="Payment_filter_btn">
                Filter
                <img
                  className="filter_img"
                  src="/build/img/Icons/down_vector.svg"
                  alt="zex pr wire Logo"
                  width={16}
                  height={16}
                  priority
                />
              </button>
            </div>
          </div>
          <div className="affiliate_coupon_table_list">
            <Table hover className="mb-0">
              <thead className="affiliate_coupon_tables_head">
                <tr className="affiliate_coupon_tr">
                  <th>
                    SR NO
                    <img
                      className={"mb-0"}
                      src="/build/img/down_arrow.svg"
                      alt="zex pr wire Logo"
                      width={26}
                      height={18}
                      priority
                    />
                  </th>
                  <th>COUPON CODE NAME</th>
                  <th>NO. OF TIME USED</th>
                  <th>COMMISION EARNED</th>
                </tr>
              </thead>
              <tbody className="affiliate_coupon_table_body">
                <tr>
                  <td>1</td>
                  <td>BLACKFRIDAYSALE</td>
                  <td>5</td>
                  <td>5</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>CYBERMONDAYSALE</td>
                  <td>5</td>
                  <td>5</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>BOXINGDAYSALE</td>
                  <td>5</td>
                  <td>5</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>MATCHDAYSALE</td>
                  <td>5</td>
                  <td>5</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>THANKSGIVINGSALE</td>
                  <td>5</td>
                  <td>5</td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>CHRISTMASSALE</td>
                  <td>5</td>
                  <td>5</td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>DIWALISALE</td>
                  <td>5</td>
                  <td>5</td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>STPATRICKSDAYSALE</td>
                  <td>5</td>
                  <td>5</td>
                </tr>
                <tr>
                  <td>9</td>
                  <td>EASTERSALE</td>
                  <td>5</td>
                  <td>5</td>
                </tr>
              </tbody>
              <caption>
                <div className="affiliate_coupon_table_footer">
                  <div className="affiliate_coupon_page_no">
                    <h4>Rows per page</h4>
                    <Dropdown className="affiliate_coupon_dropdown">
                      <Dropdown.Toggle variant="light" id="dropdown-basic">
                        10
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">1</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">2</Dropdown.Item>
                        <Dropdown.Item href="#/action-3">3</Dropdown.Item>
                        <Dropdown.Item href="#/action-3">4</Dropdown.Item>
                        <Dropdown.Item href="#/action-3">5</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="affiliate_coupon_pagination">
                    <Pagination className="mb-0">
                      <Pagination.Item>{1}</Pagination.Item>
                      <Pagination.Item>{2}</Pagination.Item>
                      <Pagination.Item>{3}</Pagination.Item>
                      <Pagination.Ellipsis />
                      <Pagination.Item>{10}</Pagination.Item>
                      <Pagination.Item>{11}</Pagination.Item>
                      <Pagination.Item>{12}</Pagination.Item>
                    </Pagination>
                  </div>
                  <div className="affiliate_coupon_pages">
                    <h4>Go to page</h4>
                    <img
                      className={"mb-0 payment_select_img"}
                      src="/build/img/Select.svg"
                      alt="zex pr wire Logo"
                      width={50}
                      height={32}
                      priority
                    />
                  </div>
                </div>
              </caption>
            </Table>
          </div>
        </div>
        <div className="affiliate_create_coupon">
          <h4>Create a Coupon Code</h4>
          <div className="affiliate_coupon_details">
            <Row>
              <Col className="mb-4 custom_col_affiliate_coupon">
                <Form.Label>Promo Code</Form.Label>
                <Form.Control placeholder="Enter text here" />
              </Col>
            </Row>
            <Row>
              <Col xs="6" className="mb-4 custom_col_affiliate_coupon">
                <Form.Label>Expiry Date</Form.Label>
                <Form.Control placeholder="Enter text here" />
              </Col>
            </Row>
            <Row>
              <Col xs="7" className="mb-4 custom_col_affiliate_coupon">
                <Form.Label>Number of Instances</Form.Label>
                <Form.Control placeholder="20" />
              </Col>
              <Col xs="5" className="custom_col_affiliate_coupon mb-4 p-0">
                <div className="affiliate_coupon_check_limit">
                  <h6 className="p-0">Unlimited</h6>
                  <input
                    type="checkbox"
                    className="affiliate_checkbox_design"
                  />
                </div>
                <p className="">No Limit on number of instances</p>
              </Col>
            </Row>
            <p className="mb-2">Same customer can redeem multiple times?</p>
            <div className="d-flex align-items-center">
              <input
                type="checkbox"
                className="me-1 affiliate_checkbox_design"
              />
              <span className="me-3">Yes</span>
              <input
                type="checkbox"
                className="me-1 affiliate_checkbox_design"
              />
              <span>No</span>
            </div>
            <div className="affiliate_create_coupon_text">
              <p className="m-0">
                *The coupon code will allow the affiliate to earn 5% on the
                purchase.
              </p>
              <p className="m-0">
                *The Client will also get 5% discount irrespective of the amount
                of package they opt for.
              </p>
            </div>
            <div className="affiliate_coupon_buttons">
              <button className="ac_cancel_btn"> Cancel</button>
              <button className="ac_next_btn">Create Coupon</button>
            </div>
          </div>
        </div>
      </div>
      <div className="affiliate_coupon_member">
        <div className="d-flex justify-content-between flex-wrap">
          <h4>Affiliate Member</h4>
          <button variant="primary" className="Payment_filter_btn">
            Filter
            <img
              className="filter_img"
              src="/build/img/Icons/down_vector.svg"
              alt="zex pr wire Logo"
              width={16}
              height={16}
              priority
            />
          </button>
        </div>
        <div className="affiliate_coupoun_member_table">
          <Table hover className="mb-0">
            <thead className="affiliate_coupon_tables_head">
              <tr className="affiliate_coupon_tr">
                <th>
                  SR NO
                  <img
                    className={"mb-0"}
                    src="/build/img/down_arrow.svg"
                    alt="zex pr wire Logo"
                    width={26}
                    height={18}
                    priority
                  />
                </th>
                <th>
                  TX NO.
                  <img
                    className={"mb-0"}
                    src="/build/img/down_arrow.svg"
                    alt="zex pr wire Logo"
                    width={26}
                    height={18}
                    priority
                  />
                </th>
                <th>
                  STATUS
                  <img
                    className={"mb-0"}
                    src="/build/img/down_arrow.svg"
                    alt="zex pr wire Logo"
                    width={26}
                    height={18}
                    priority
                  />
                </th>
                <th>
                  AMOUNT(IN $)
                  <img
                    className={"mb-0"}
                    src="/build/img/down_arrow.svg"
                    alt="zex pr wire Logo"
                    width={26}
                    height={18}
                    priority
                  />
                </th>
              </tr>
            </thead>
            <tbody className="affiliate_coupon_table_body">
              <tr>
                <td>1</td>
                <td>pz_razorpay_101</td>
                <td>
                  <span className="status_processing">Processing</span>
                </td>
                <td>10</td>
              </tr>
              <tr>
                <td>2</td>
                <td>pz_razorpay_102</td>
                <td>
                  <span className="status_processing">Processing</span>
                </td>
                <td>5</td>
              </tr>
              <tr>
                <td>3</td>
                <td>pz_razorpay_103</td>
                <td>
                  <span className="status_confirmed">Confirmed</span>
                </td>
                <td>15</td>
              </tr>
              <tr>
                <td>4</td>
                <td>pz_razorpay_104</td>
                <td>
                  <span className="status_cancelled">Cancelled</span>
                </td>
                <td>10</td>
              </tr>
              <tr>
                <td>5</td>
                <td>pz_razorpay_105</td>
                <td>
                  <span className="status_confirmed">Confirmed</span>
                </td>
                <td>5</td>
              </tr>
              <tr>
                <td>6</td>
                <td>pz_razorpay_106</td>
                <td>
                  <span className="status_confirmed">Confirmed</span>
                </td>
                <td>5</td>
              </tr>
              <tr>
                <td>7</td>
                <td>pz_razorpay_107</td>
                <td>
                  <span className="status_cancelled">Cancelled</span>
                </td>
                <td>5</td>
              </tr>
              <tr>
                <td>8</td>
                <td>pz_razorpay_108</td>
                <td>
                  <span className="status_cancelled">Cancelled</span>
                </td>
                <td>10</td>
              </tr>
              <tr>
                <td>9</td>
                <td>pz_razorpay_109</td>
                <td>
                  <span className="status_confirmed">Confirmed</span>
                </td>
                <td>15</td>
              </tr>
            </tbody>
            <caption>
              <div className="affiliate_coupon_table_footer">
                <div className="affiliate_coupon_page_no">
                  <h4>Rows per page</h4>
                  <Dropdown className="affiliate_coupon_dropdown">
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                      10
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">1</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">2</Dropdown.Item>
                      <Dropdown.Item href="#/action-3">3</Dropdown.Item>
                      <Dropdown.Item href="#/action-3">4</Dropdown.Item>
                      <Dropdown.Item href="#/action-3">5</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="affiliate_coupon_pagination">
                  <Pagination className="mb-0">
                    <Pagination.Item>{1}</Pagination.Item>
                    <Pagination.Item>{2}</Pagination.Item>
                    <Pagination.Item>{3}</Pagination.Item>
                    <Pagination.Ellipsis />
                    <Pagination.Item>{10}</Pagination.Item>
                    <Pagination.Item>{11}</Pagination.Item>
                    <Pagination.Item>{12}</Pagination.Item>
                  </Pagination>
                </div>
                <div className="affiliate_coupon_pages">
                  <h4>Go to page</h4>
                  <img
                    className={"mb-0 payment_select_img"}
                    src="/build/img/Select.svg"
                    alt="zex pr wire Logo"
                    width={50}
                    height={32}
                    priority
                  />
                </div>
              </div>
            </caption>
          </Table>
        </div>
      </div>
    </>
  );
}
export default AffiliateCoupon;
