import Sidebar from "../../components/SidebarNew/Sidebar";
import Navbar from "../../components/NavbarProfile/NavbarProfile";
import PRlayout from "../../components/PRmanagerLayout/PRlayout";
import PRpreview from "../../components/PRmanagerLayout/PRpreview";
import "./PR.css";
import { useState } from "react";

export default function PRmanger() {
  // const [showPRLayout, setShowPRLayout] = useState(true);

  // const handleShowMyProfile = () => {
  //   setShowPRLayout(false);
  // };

  // const handleShowPRLayout = () => {
  //   setShowPRLayout(true);
  // };

  const activeSide = 2;
  return (
    <>
      <div className="row gx-0 pr_manager_layout">
        <Sidebar activeSide={activeSide} />
        <div className={`main col`}>
          {/* {showPRLayout ? ( */}
          <Navbar name={"PR Manager"} />
          {/* ) : (
            <Navbar name={"Press release"} />
          )} */}
          {/* {showPRLayout ? ( */}
          <PRlayout />
          {/* ) : (
            <PRpreview onShowPRLayout={handleShowPRLayout} />
          )} */}
        </div>
      </div>
    </>
  );
}
