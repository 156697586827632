"use client";
import "../Dashboard/Dashboard.css";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Instance from "../../Utils/Axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showToast } from "../../Utils/index";
import error from "../../Utils/Icons/error";

export default function Navbar({ name }) {
  const [showResMenu, setShowResMenu] = useState(false);
  const [profileImage, setProfileImage] = useState([]);
  const navigate = useNavigate();

  const handleLogout = () => {
    Instance.post("/user/logout", { tokenId: "" })
      .then((response) => {
        localStorage.removeItem("tokenId");
        showToast("Logged Out Succesfully", "success", "success");
        setTimeout(() => {
          navigate("/");
        }, 2000);
      })
      .catch((error) => {
        console.error("Error logging out:", error);
      });
  };

  useEffect(() => {
    const fetchProfileImage = () => {
      const token = localStorage.getItem("tokenId");
      Instance.post("/user/profile", {
        tokenId: token,
      })
        .then((response) => {
          setProfileImage(response.data.userProfile || []);
        })
        .catch((error) => {
          console.error("cannot fetch profile image", error);
        });
    };
    fetchProfileImage();
  }, []);

  return (
    <>
      <navbar className={`dash_navbar`}>
        <div className={`title`}>{name}</div>
        <div className={` nav_btn_container`}>
          <div className={` d-none d-lg-flex nav_btns`}>
            <div className={`search_btn`}>
              <img
                className={""}
                src="/build/img/search.svg"
                alt="zex pr wire Logo"
                width={24}
                height={24}
                priority
              />
              <input placeholder="search here" />
            </div>
            {/* <button className={`outline_btn`}>Buy Credits</button> */}
            <Link to="/customer/pricing" className={`outline_btn`}>
              Buy Credits
            </Link>
            <Link to="/customer/submit-pr" className={`fill_btn`}>
              Submit PR
            </Link>
          </div>

          <div className={`nav_profile `}>
            <div className="resposive_search d-lg-none d-flex align-item-center">
              <img
                className={""}
                src="/build/img/search.svg"
                alt="zex pr wire Logo"
                width={24}
                height={24}
                priority
              />
            </div>
            <div>
              <img
                className={""}
                src="/build/img/notification.svg"
                alt="zex pr wire Logo"
                width={18}
                height={21}
                priority
              />
            </div>
            <div>
              <div className="d-none d-lg-flex gap-2 align-items-center">
                {/* <Link to="/customer/profile"> */}
                <img
                  className={""}
                  src={profileImage.imageUrl}
                  alt="icon"
                  width={50}
                  height={50}
                  priority
                />
                {/* </Link> */}
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                    <img
                      className={""}
                      src="/build/img/downarrow.svg"
                      alt="zex pr wire Logo"
                      width={24}
                      height={24}
                      priority
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="/customer/profile">
                      <img
                        className={"me-2"}
                        src="/build/img/setting.svg"
                        alt="zex pr wire Logo"
                        width={24}
                        height={24}
                        priority
                      />
                      Settings
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleLogout}>
                      <img
                        className={"me-2"}
                        src="/build/img/log_out.svg"
                        alt="zex pr wire Logo"
                        width={24}
                        height={24}
                        priority
                      />
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div
                className=" d-lg-none nav-responsive "
                style={{ cursor: "pointer" }}
                onClick={() => setShowResMenu(true)}
              >
                <img
                  className={""}
                  src="/build/img/hamburger.svg"
                  alt="hamburger"
                  width={30}
                  height={30}
                  priority
                />
              </div>
            </div>
          </div>
          <div className={` ${showResMenu ? "responsive_menu" : "d-none"} `}>
            <div className=" res-profile bg-white d-flex justify-content-between align-items-center mb-2  ">
              {/* <Link to="/customer/profile"> */}
              <img
                className={""}
                src={profileImage.imageUrl}
                alt="icon"
                width={50}
                height={50}
                priority
              />
              {/* </Link> */}
              <img
                className={"pointer"}
                style={{ cursor: "pointer" }}
                src="/build/img/close-menu.svg"
                alt="hamburger"
                width={30}
                height={30}
                onClick={() => setShowResMenu(false)}
                priority
              />
            </div>
            <Link to="/customer/pricing">
              <div className="menu_item p-3 ">Buy Credits </div>
            </Link>
            <div className="menu_item p-3 ">
              <Link to="/customer/submit-pr">Submit PR</Link>
            </div>
            <Link to="/customer/pricing">
              <div className="menu_item p-3 ">Settings</div>
            </Link>
            <div className="menu_item p-3 ">
              <div onClick={handleLogout}>Logout</div>
            </div>
          </div>
        </div>
      </navbar>
      <ToastContainer theme="colored" />
    </>
  );
}
