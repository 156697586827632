import Sidebar from "../../components/SidebarNew/Sidebar";
import Navbar from "../../components/NavbarProfile/NavbarProfile";
import Reject from "../../components/PRmanagerLayout/Reject";
import "../PRmanager/PR.css";

export default function PRreject() {
  const activeSide = 2;
  return (
    <>
      <div className="row gx-0 pr_manager_layout">
        <Sidebar activeSide={activeSide} />
        <div className={`main col`}>
          <Navbar name={"PR Manager"} />
          <Reject />
        </div>
      </div>
    </>
  );
}
