import Sidebar from "../../components/SidebarNew/Sidebar";
import Navbar from "../../components/NavbarProfile/NavbarProfile";
import AddSubLayout from "../../components/SubAccountLayout/AddSubLayout";
import "../SubAccount/SubAccount.css";

const SubAccountCreate = () => {
  const activeSide = 3;
  return (
    <>
      <div className="row gx-0 sub_account_layout">
        <Sidebar activeSide={activeSide} />
        <div className={`main col`}>
          <Navbar name={"Sub-Account"} />
          <AddSubLayout />
        </div>
      </div>
    </>
  );
}

export default SubAccountCreate;
