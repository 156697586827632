import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import React from "react";
import { useState, useEffect, useRef } from "react";
import Instance from "../../Utils/Axios";
import "./ProfileLayout.css";
import { showToast } from "../../Utils";
import axios from "axios";

export default function MyProfile() {
  const [activeTab, setActiveTab] = useState(1);
  // const [profileData, setProfileData] = useState([]);
  const [error, setError] = useState("");
  const [formData, setFormData] = useState({});
  const [selectedImage, setSelectedImage] = useState("/build/Profile_Pic.svg");
  const fileInputRef = useRef(null);
  const [fileData, setFile] = useState(null);

  useEffect(() => {
    const fetchProfileData = () => {
      const token = localStorage.getItem("tokenId");
      Instance.post("/user/profile", {
        tokenId: token,
      })
        .then((response) => {
          // setProfileData(response.data.userProfile);
          setFormData(response.data.userProfile);
          setSelectedImage(response.data.userProfile.imageUrl);
          setError("");
        })
        .catch((error) => {
          setError("Cannot fetch the data");
          console.error("Cannot fetch the profile data", error);
        });
    };
    fetchProfileData();
  }, []);

  const handleFileChangeLogo = (event) => {
    const token = localStorage.getItem("tokenId");
    const file = event.target.files[0];
    const formDataToSubmit = new FormData();
    formDataToSubmit.append("tokenId", token);
    formDataToSubmit.append("fileUpload", file);
    // setSelectedImage(file.name);
    axios
      .post(
        "https://pr.webandapi.com/site/upload/documents",
        formDataToSubmit,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        if (response?.data?.resCode == "200") {
          // setFormData((prevData) => ({
          //   ...prevData,
          //   // imageUrl: selectedImage,
          //   imageUrl: response?.data?.documentUrl,
          // }));
          setSelectedImage(response?.data?.documentUrl);
          console.log("upload image api ", response?.data);
          // setSelectedImage(file.name);
        } else {
          console.log("upload image api failed ", response?.data);
        }
      })
      .catch((error) => {
        setError("Failed to fetch the data");
        console.error("Error fetching network list:", error);
      });
    console.log("file details", file);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
        // setFormData((prevData) => ({
        //   ...prevData,
        //   imageUrl: reader.result,
        // }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const token = localStorage.getItem("tokenId");
    Instance.post("/user/updateUser", {
      tokenId: token,
      ...formData,
      imageUrl: selectedImage,
    })
      .then((response) => {
        if (response.data.resCode === "200") {
          showToast("Updated Succesfully!", "success", "success");
          console.log(response.data);
        } else {
          setError(response.data.resMessage);
        }
      })
      .catch((error) => {
        setError("Cannot update the profile");
        console.error("Update profile error", error);
      });
  };

  return (
    <>
      <div className="secondary_nav">
        <div
          className={`secondary_tabs ${activeTab === 1 ? "path_active" : ""}`}
          onClick={() => setActiveTab(1)}
        >
          My Profile
        </div>
      </div>

      <div className="content_container">
        <div className="edit_container">
          <h5>Profile Picture</h5>
          <div className="upload">
            <img
              className={"profile_picture"}
              src={selectedImage}
              alt="zex pr wire Logo"
              width={170}
              height={170}
              priority
            // value={formData.imageUrl}
            />
            <div className="browse_image">
              <img
                className={"add_picture"}
                src="/build/Addimage.svg"
                alt="zex pr wire Logo"
                width={150}
                height={68}
                priority
                style={{ cursor: "pointer" }}
                onClick={() => fileInputRef.current.click()}
              />
              <h6
                style={{ cursor: "pointer" }}
                onClick={() => fileInputRef.current.click()}
              >
                Click to upload{" "}
                <span className="light_text">
                  or drag and drop PNG or JPG here.
                </span>
                Max size 2MB.
              </h6>
              <button
                className="browse_btn"
                onClick={() => fileInputRef.current.click()}
              >
                Browse
              </button>
              <input
                ref={fileInputRef}
                type="file"
                accept="image/png, image/jpeg, image/jpg"
                style={{ display: "none" }}
                //onChange={handleImageUpload}
                onChange={handleFileChangeLogo}
              />
            </div>
          </div>
        </div>
        <Form className="custom-form">
          <Row className="custom-row mb-4">
            <Form.Group as={Col} className="custom-col">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                name="firstName"
                placeholder="First Name"
                value={formData.firstName}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group as={Col} className="custom-col">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Last Name"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
              />
            </Form.Group>
          </Row>
          <Row className="custom-row">
            <Col xs={6} className="custom-col mb-4">
              <Form.Label>Email Id</Form.Label>
              <Form.Control
                type="email"
                name="email"
                readOnly
                placeholder="wadewaren@fakemail.com"
                value={formData.email}
                onChange={handleChange}
              />
            </Col>
            <Col xs={2} className="custom-col mb-4">
              <Form.Label>Country Code</Form.Label>
              <Form.Control
                type="text"
                name="isdCode"
                placeholder="IND | +91"
                value={formData.isdCode}
                onChange={handleChange}
              />
            </Col>
            <Col xs={4} className="custom-col mb-4">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="number"
                placeholder="9876543210"
                name="mobile"
                value={formData.mobile}
                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row className="custom-row mb-4">
            <Form.Group as={Col} className="custom-col">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="20 Gardener Rd India 208652"
                name="address"
                value={formData.address}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group as={Col} className="custom-col">
              <Form.Label>Country</Form.Label>
              <Form.Control
                type="text"
                placeholder="India"
                name="country"
                value={formData.country}
                onChange={handleChange}
              />
            </Form.Group>
          </Row>
          <Row className="custom-row mb-4">
            <Form.Group as={Col} className="custom-col">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="pwugla9fod1r"
                name="pwd"
                value={formData.pwd}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group as={Col} className="custom-col">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                name="confirmpwd"
                placeholder="************"
                value={formData.confirmpwd}
                onChange={handleChange}
              />
            </Form.Group>
          </Row>
          <Row className="custom-row mb-4">
            <Form.Group as={Col} className="custom-col">
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Acme Corporation"
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group as={Col} className="custom-col">
              <Form.Label>Company Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="180 Kitchener Rd India 208539"
                name="companyAddress"
                value={formData.companyAddress}
                onChange={handleChange}
              />
            </Form.Group>
          </Row>
          <Row className="custom-row mb-4">
            <Form.Group as={Col} className="custom-col">
              <Form.Label>Company Website</Form.Label>
              <Form.Control
                type="text"
                placeholder="www.acmecorporation.com"
                name="companyWebsite"
                value={formData.companyWebsite}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group as={Col} className="custom-col">
              <Form.Label>Other Details</Form.Label>
              <Form.Control
                type="text"
                placeholder="Lorem Ipsum Lorem Ipsum"
                name="otherDetails"
                value={formData.otherDetails}
                onChange={handleChange}
              />
            </Form.Group>
          </Row>
        </Form>
        <div className="change_buttons">
          <button className="save_btn mb-4" onClick={handleSubmit}>
            Save Changes
          </button>
        </div>
      </div>
    </>
  );
}
