import { useState } from "react";
import "./AffiliateCenter.css";
import AffiliateProfile from "./AffiliateProfile";
import AffiliateMemberProfile from "./AffiliateMemberProfile";
import AffiliateCoupon from "./AffiliateCoupon";
import Wallet from "./Wallet";

export default function AffiliateMember() {
  const [activeTab, setActiveTab] = useState(1);
  const [showProfile, setShowProfile] = useState(true);

  const handleShowMembers = () => {
    setShowProfile(false);    
  }

  return (
    <>
      <div className="ac_navbar">
        <div
          className={`ac_navbar_options ${activeTab === 1 ? "active_tab" : ""}`}
          onClick={() => setActiveTab(1)}
        >
          My Affiliate Profile
        </div>
        <div
          className={`ac_navbar_options ${activeTab === 2 ? "active_tab" : ""}`}
          onClick={() => setActiveTab(2)}
        >
          Affilliate Coupon Codes
        </div>
        <div
          className={`ac_navbar_options ${activeTab === 3 ? "active_tab" : ""}`}
          onClick={() => setActiveTab(3)}
        >
          Wallet
        </div>
      </div>
      <div className="ac_member_layout">
        {activeTab === 1 ? (
         showProfile ? (
          <AffiliateProfile onShowMembers={handleShowMembers} />
        ) : (
          <AffiliateMemberProfile />
        )
        ) : activeTab === 2 ? (
          <AffiliateCoupon />
        ) : (
          <Wallet />
        )}
      </div>
    </>
  );
}
