import Sidebar from "../../components/SidebarNew/Sidebar";
import Navbar from "../../components/NavbarProfile/NavbarProfile";
import ContactLayout from "../../components/ContactLayout/ContactLayout";
import "./Contact.css";

export default function Contact() {
  const activeSide = 10;
  return (
    <>
      <div className="row gx-0 sub_account_layout">
        <Sidebar activeSide={activeSide} />
        <div className={`main col`}>
          <Navbar name={"Contact"} />
          <ContactLayout />
        </div>
      </div>
    </>
  );
}
