import { Col, Row } from "react-bootstrap";
import "./Pricing.css";

const BottomBar = () => {
  return (
    <>
      <div className="bottom_bar">
        <Row>
          <Col xs={12} md={6} className="m-0">
            <div className="bottom_bar_text">
              <p>Sign Up to Newsletter </p>
              <span>to get emails regarding latest offers and discounts</span>
            </div>
          </Col>
          <Col xs={12} md={6} className="m-0">
            <div className="bottom_bar_submit">
              <input type="email" placeholder="Your Email" />
              <button>submit</button>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default BottomBar;
