import { useState } from "react";
import "./AffiliateCenter.css";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Modal, Button } from "react-bootstrap";

function AffiliateProfile({ onShowMembers }) {
  const [selectedSource, setSelectedSource] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [showMember, setShowMember] = useState(false);

  const hadnleShowMember = () => {
    setShowMember(true);
  };
  const hadnleNotShowMember = () => {
    setShowMember(false);
  };

  const handleSourceChange = (event) => {
    setSelectedSource(event.target.value);
  };

  const handleCheckBox = (event) => {
    setIsChecked(event.target.checked);
  };

  return (
    <>
      <div className="affiliate_profile_layout">
        <h4>Market Coupon Code</h4>
        <Row>
          <Col xs="6" className="custom_col_affiliate_memeber mb-4">
            <Form.Label>Add Coupon Code</Form.Label>
            <Form.Control placeholder="Enter text here" />
          </Col>
        </Row>
        <Row>
          <Col xs="6" className="custom_col_affiliate_memeber mb-0">
            <Form.Label>Source</Form.Label>
            <Form.Select value={selectedSource} onChange={handleSourceChange}>
              <option>Select from dropdown</option>
              <option value="1">Others</option>
              <option value="2">Multiple Sources</option>
              <option value="3">Three</option>
            </Form.Select>
          </Col>
        </Row>
        {selectedSource === "1" && (
          <div className="others_info">
            <p>Other Sources </p>
            <Row>
              <Col className="custom_col_affiliate_memeber mb-4">
                <Form.Label>Name of Source</Form.Label>
                <Form.Control placeholder="Name" />
              </Col>
            </Row>
            <Row>
              <Col className="custom_col_affiliate_memeber mb-4">
                <Form.Label>URL</Form.Label>
                <Form.Control placeholder="Enter URL here" />
              </Col>
            </Row>
          </div>
        )}
        <div className="ac_checkbox">
          <input
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckBox}
            className="affiliate_checkbox_design"
          />
          <span>
            I hereby agree that I own, or have rights to all the above sources.
          </span>
        </div>
        <div>
          Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
          sint. Velit officia consequat duis enim velit mollit. Exercitation
          veniam consequat sunt nostrud amet.
        </div>
        <div className="mt-4">
          <button
            className="ac_next_btn"
            disabled={!isChecked}
            onClick={hadnleShowMember}
          >
            Become an Afflilate Member
          </button>
        </div>
        <Modal
          show={showMember}
          onHide={hadnleNotShowMember}
          centered
          className="custom_modal"
        >
          <Modal.Body className="p-0">
            <div className="affiliate_modal_img">
              <img
                className={"submit_done_img"}
                src="/build/congratulation.svg"
                alt="zex pr wire Logo"
                width={140}
                height={140}
                priority
              />
            </div>
            <div>
              <h5 className="affiliate_modal_head">Congratulations</h5>
              <p className="affiliate_modal_content">
                You have successfully become an Affiliate member!
              </p>
            </div>
            <div className="pr_modal_btn">
              <Button
                className="affiliate_cancel_btn"
                onClick={hadnleNotShowMember}
              >
                Cancel
              </Button>
              <Button className="affiliate_save_btn" onClick={onShowMembers}>
                Go To Dashboard
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default AffiliateProfile;
