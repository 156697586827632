import Sidebar from "../../components/SidebarNew/Sidebar";
import Navbar from "../../components/NavbarProfile/NavbarProfile";
import PaymentLayout from "../../components/PaymentLayout/PaymentLayout";
import "./Payment.css";

export default function Payment() {
  const activeSide = 5;
  return (
    <>
      <div className="row gx-0 payment_layout">
        <Sidebar activeSide={activeSide} />
        <div className={`main col`}>
          <Navbar name={"Payment"} />
          <PaymentLayout />
        </div>
      </div>
    </>
  );
}
