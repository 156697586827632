import "./GalleryLayout.css";
import { useState, useEffect, useRef } from "react";
import { Button, Accordion, Offcanvas, Modal, Form } from "react-bootstrap";
import { DateRange, DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import ListView from "./ListView";
import GridView from "./GridView";
import Instance from "../../Utils/Axios";
import { showToast } from "../../Utils/index";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import axios from "axios";

export default function GalleryLayout() {
  const [show, setShow] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [filtersApplied, setFiltersApplied] = useState(0);
  const [filters, setFilters] = useState({
    createdOn: "",
  });
  const [gridView, setGridView] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [checkedState, setCheckedState] = useState([]);
  const [images, setImages] = useState([]);
  const [imageDescription, setImageDescription] = useState({});
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [numSelectedCheckboxes, setNumSelectedCheckboxes] = useState(0);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [selectedFilePath, setSelectedFilePath] = useState("");
  const fileInputRef = useRef(null);
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: addDays(new Date(), 2),
    key: "selection",
  });

  const handleSelect = (ranges) => {
    setSelectionRange(ranges.selection);
  };

  const fetchImages = () => {
    const token = localStorage.getItem("tokenId");
    Instance.post("/user/gallery/getList", {
      tokenId: token,
    })
      .then((response) => {
        if (response.data.resCode === "200") {
          setImages(response.data.message);
          setCheckedState(new Array(response.data.message.length).fill(false));
        }
      })
      .catch((error) => {
        console.error("Error fetching images:", error);
      });
  };

  useEffect(() => {
    fetchImages();
  }, []);
  const fetchImagesDetails = (galleryId) => {
    const token = localStorage.getItem("tokenId");
    Instance.post("/user/gallery/details", {
      tokenId: token,
      galleryId,
    })
      .then((response) => {
        if (response.data.resCode === "200") {
          setImageDescription(response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching image details:", error);
      });
  };

  const handlePhotoClick = (galleryId) => {
    fetchImagesDetails(galleryId);
  };

  const handleDelete = (galleryId) => {
    const token = localStorage.getItem("tokenId");
    Instance.post("/user/gallery/update", {
      tokenId: token,
      galleryId: galleryId,
      status: "INACTIVE",
    })
      .then((response) => {
        if (response.data.resCode === "200") {
          console.log("deleted");
          showToast("Image Deleted successfully!", "success", "succes");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          console.log("could delete");
        }
      })
      .catch((error) => {
        console.error("Error fetching image details:", error);
      });
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleShowUpload = () => {
    setShowUpload(true);
  };
  const handleNotShowUpload = () => {
    setShowUpload(false);
  };

  const handleShowDelete = () => {
    setShowDelete(true);
  };
  const hadnleNotShowDelete = () => {
    setShowDelete(false);
  };

  const handleShowPreview = () => {
    setShowPreview(true);
  };
  const handleNotShowPreview = () => {
    setShowPreview(false);
  };

  const handleListView = () => {
    setGridView(false);
  };
  const handleGridView = () => setGridView(true);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const calculateFiltersApplied = () => {
    let count = 0;
    if (selectionRange) count++;
    setFiltersApplied(count);
  };
  // const handleApplyFilters = () => {
  //   calculateFiltersApplied();
  //   handleClose();
  // };

  const handleApplyFilters = async () => {
    calculateFiltersApplied();
    handleClose();

    const token = localStorage.getItem("tokenId");
    const filterData = {
      tokenId: token,
      createdOn: {
        startDate: selectionRange.startDate.toISOString().split("T")[0],
        endDate: selectionRange.endDate.toISOString().split("T")[0],
      },
    };

    try {
      const response = await Instance.post("/user/gallery/getList", filterData);
      if (response.data.resCode === "200") {
        const filteredImages = response.data.message.filter((image) => {
          const ImageDate = image.createdOn.split(" ")[0];
          return (
            ImageDate >= filterData.createdOn.startDate &&
            ImageDate <= filterData.createdOn.endDate
          );
        });
        setImages(filteredImages);
      } else {
        console.error(
          "Failed to fetch filtered images:",
          response.data.resMessage
        );
      }
    } catch (error) {
      console.error("Error fetching filtered images:", error);
    }
  };

  const handleClearFilters = async () => {
    setFiltersApplied(0);
    setSelectionRange({
      startDate: new Date(),
      endDate: addDays(new Date(), 2),
      key: "selection",
    });
    fetchImages();
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  // const handleClearFilters = () => {
  //   setSelectedDate(null);
  //   setFiltersApplied(0);
  // };

  const handleSelectionCountChange = (count) => {
    setNumSelectedCheckboxes(count);
  };

  const updateSelectedCheckboxes = (updatedCheckedState) => {
    // setNumSelectedCheckboxes((prevCount) => prevCount + 1);
    setCheckedState(updatedCheckedState);
  };

  const copyURL = (url) => {
    navigator.clipboard
      .writeText(url)
      .then(() => showToast("URL copied!", "success", "success"));
  };

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     setSelectedFileName(file.name);
  //     setSelectedFilePath(URL.createObjectURL(file));
  //     console.log(URL.createObjectURL(file));
  //   }
  // };

  const handleFileChange = (event) => {
    const token = localStorage.getItem("tokenId");
    const file = event.target.files[0];
    const formDataToSubmit = new FormData();
    formDataToSubmit.append("tokenId", token);
    formDataToSubmit.append("fileUpload", file);
    // setSelectedImage(file.name);
    axios
      .post(
        "https://pr.webandapi.com/site/upload/documents",
        formDataToSubmit,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        if (response?.data?.resCode == "200") {
          // setImages((prevData) => ({
          //   ...prevData,
          //   // imageUrl: selectedImage,
          //   documentUrl: response?.data?.documentUrl,
          // }));
          setSelectedFilePath(response.data.documentUrl);
          console.log("upload image api ", response?.data);
          // setSelectedImage(file.name);
        } else {
          console.log("upload image api failed ", response?.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching network list:", error);
      });
    console.log("file details", file);
  };

  const handleUpload = () => {
    const token = localStorage.getItem("tokenId");
    Instance.post("/user/gallery/create", {
      tokenId: token,
      name: images.name,
      documentUrl: selectedFilePath,
      alternateText: imageDescription.alternateText,
    })
      .then((response) => {
        if (response.data.resCode === "200") {
          showToast(response.data.resMessage, "success", "success");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          showToast("Failed to upload image.", "danger", "error");
        }
      })
      .catch((error) => {
        console.error("Error uploading image:", error);
        showToast("Error uploading image.", "danger", "error");
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setImages({
      ...images,
      [name]: value,
    });
  };

  return (
    <>
      <div className="gallery_layout">
        <div className="all_images">
          <div className="gallery_options_btns">
            <div>
              <button
                className="list_view_btn"
                onClick={handleListView}
                style={{
                  backgroundColor: gridView === false ? "#585EDB" : "#5D6B82",
                }}
              >
                <img
                  className=""
                  src="/build/img/Icons/Grid.svg"
                  alt="zex pr wire Logo"
                  width={16}
                  height={16}
                  priority
                />
              </button>
              <button
                className="grid_view_btn"
                onClick={handleGridView}
                style={{
                  backgroundColor: gridView === true ? "#585EDB" : "#5D6B82",
                }}
              >
                <img
                  className=""
                  src="/build/img/Icons/List.svg"
                  alt="zex pr wire Logo"
                  width={16}
                  height={16}
                  priority
                />
              </button>

              <Button
                variant="primary"
                className="Payment_filter_btn"
                onClick={handleShow}
              >
                Filter
                <img
                  className="filter_img"
                  src="/build/img/Icons/down_vector.svg"
                  alt="zex pr wire Logo"
                  width={16}
                  height={16}
                  priority
                />
              </Button>
              <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header
                  closeButton
                  className="filter_offcanvas_header"
                >
                  <Offcanvas.Title className="filter_offcanvas_title">
                    {filtersApplied} filters applied
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="filter_offcanavas_body">
                  <Accordion alwaysOpen flush>
                    <Accordion.Item eventKey="0" className="accordian_item">
                      <Accordion.Header className="filter_accordian">
                        Date Range
                      </Accordion.Header>
                      <Accordion.Body style={{ backgroundColor: "#FAFBFB" }}>
                        <div>
                          <DateRangePicker
                            ranges={[selectionRange]}
                            onChange={handleSelect}
                            months={1}
                            direction="horizontal"
                          // value={filters.createdOn}
                          />
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <div className="mt-4 mb-4 filter_btns">
                    <button
                      className="filter_clear_btn"
                      onClick={handleClearFilters}
                    >
                      Clear
                    </button>
                    <button
                      className="filter_apply_btn"
                      onClick={handleApplyFilters}
                    >
                      Apply
                    </button>
                  </div>
                </Offcanvas.Body>
              </Offcanvas>
            </div>
            <div>
              <button className="upload_gallery_btn" onClick={handleShowUpload}>
                <img
                  className="upload_gallery_img"
                  src="/build/img/Icons/Upload.svg"
                  alt="zex pr wire Logo"
                  width={16}
                  height={16}
                  priority
                />
                Upload
              </button>
              <Modal
                show={showUpload}
                onHide={handleNotShowUpload}
                centered
                className="custom_modal custom_modal_upload"
              >
                <Modal.Body className="p-0">
                  <div className="m-auto">
                    <img
                      className={"mb-3"}
                      src="/build/Addimageframe.svg"
                      alt="zex pr wire Logo"
                      width={150}
                      height={150}
                      priority
                    />
                    <h5 className="submit_modal_head">Add Image</h5>
                  </div>
                  <div className="modal_gallery_inputs">
                    <Form.Control
                      placeholder="Attach Files"
                      className="mb-3"
                      type="file"
                      ref={fileInputRef}
                      value={images.documentUrl}
                      onChange={handleFileChange}
                    />
                    <Form.Label>Image Title</Form.Label>
                    <Form.Control
                      placeholder="Enter text here"
                      className="mb-3"
                      name="name"
                      value={images.name}
                      onChange={handleChange}
                    />
                    <Form.Label>Alternate Text</Form.Label>
                    <Form.Control
                      placeholder="Enter text here"
                      name="text"
                      value={images.alternateText}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="pr_modal_btn">
                    <Button
                      className="modal_gallery_btn1"
                      onClick={handleNotShowUpload}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="modal_gallery_btn2"
                      onClick={handleUpload}
                    >
                      Upload
                    </Button>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </div>

          {gridView === false ? (
            <ListView
              setSelectedPhoto={setSelectedPhoto}
              updateSelectedCheckboxes={updateSelectedCheckboxes}
              images={images}
              checkedState={checkedState}
              onPhotoClick={handlePhotoClick}
              onSelectionCountChange={handleSelectionCountChange}
            />
          ) : (
            <GridView
              setSelectedPhoto={setSelectedPhoto}
              updateSelectedCheckboxes={updateSelectedCheckboxes}
              images={images}
              checkedState={checkedState}
              onPhotoClick={handlePhotoClick}
              onSelectionCountChange={handleSelectionCountChange}
            />
          )}
        </div>
        <div className="image_description">
          {!selectedPhoto && !numSelectedCheckboxes && (
            <>
              <div className="no_image">No Image to display </div>
            </>
          )}
          {selectedPhoto && numSelectedCheckboxes === 1 && (
            <>
              <h4 className="image_name">{imageDescription.name} </h4>
              <img
                src={imageDescription.documentUrl}
                alt={imageDescription.name}
                style={{ width: "100%", height: "auto" }}
              />

              <div className="image_detail">
                <p className="mb-4 image_detail_input">URL</p>
                <p className="mb-4 image_detail_output">
                  {" "}
                  {imageDescription.documentUrl}
                </p>
                <p className="mb-4 image_detail_input"> Date Uploaded</p>
                <p className="mb-4 image_detail_output">
                  {imageDescription.createdOn}
                </p>
                <p className="mb-0 image_detail_input">Size</p>
                <p className="mb-0 image_detail_output">
                  {(imageDescription.size / 1024).toFixed(0)} KB
                </p>
              </div>
              <div className="gallery_details_btns">
                <button
                  className="image_preview_btn"
                  onClick={handleShowPreview}
                >
                  Preview
                </button>
                <Modal
                  show={showPreview}
                  onHide={handleNotShowPreview}
                  centered
                  className="custom_modal_preview"
                >
                  <Modal.Body className="p-0">
                    <div className="">
                      <img
                        className={"gallery_preview_img"}
                        src={selectedPhoto}
                        alt="zex pr wire Logo"
                        width={550}
                        height={300}
                        priority
                      />
                    </div>
                    <div
                      className="gallery_details_btns m-auto"
                      style={{ gap: "12px" }}
                    >
                      <button
                        className="image_preview_btn"
                        onClick={handleNotShowPreview}
                      >
                        Back
                      </button>
                      <button
                        className="image_url_btn"
                        onClick={() => {
                          copyURL(imageDescription.documentUrl);
                        }}
                      >
                        Copy URL
                      </button>
                      <button
                        className="image_delete_btn"
                        onClick={() => {
                          handleShowDelete();
                          handleNotShowPreview();
                        }}
                      >
                        <img
                          className={"image_delete_img"}
                          src="/build/img/Icons/Delete.png"
                          alt="zex pr wire Logo"
                          width={16}
                          height={16}
                          priority
                        />
                        Delete
                      </button>
                    </div>
                  </Modal.Body>
                </Modal>
                <button
                  className="image_url_btn"
                  onClick={() => {
                    copyURL(imageDescription.documentUrl);
                  }}
                >
                  Copy URL
                </button>
                <button className="image_delete_btn" onClick={handleShowDelete}>
                  <img
                    className={"image_delete_img"}
                    src="/build/img/Icons/Delete.png"
                    alt="zex pr wire Logo"
                    width={16}
                    height={16}
                    priority
                  />
                  Delete
                </button>
                <Modal
                  show={showDelete}
                  onHide={hadnleNotShowDelete}
                  centered
                  className="custom_modal"
                >
                  <Modal.Body>
                    <div className="submit_modal_img">
                      <img
                        className={"submit_done_img"}
                        src="/build/imageDelete.svg"
                        alt="zex pr wire Logo"
                        width={140}
                        height={140}
                        priority
                      />
                    </div>
                    <div>
                      <h5 className="submit_modal_head">Delete Image</h5>
                      <p className="submit_modal_content">
                        Are you sure you want to delete this image?
                      </p>
                    </div>
                    <div className="pr_modal_btn">
                      <Button
                        className="modal_gallery_btn1"
                        onClick={hadnleNotShowDelete}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="image_delete_btn"
                        onClick={() => handleDelete(imageDescription.galleryId)}
                      >
                        <img
                          className={"image_delete_img"}
                          src="/build/img/Icons/Delete.png"
                          alt="zex pr wire Logo"
                          width={16}
                          height={16}
                          priority
                        />
                        Delete
                      </Button>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            </>
          )}
          {/* {selectedPhoto && numSelectedCheckboxes > 1 && (
            <>
              <div>
                <h4 className="image_name">Multiple Images Selected</h4>
                <div className="multiple_images">
                  <img
                    className="image_display"
                    src={selectedPhoto}
                    alt="zex pr wire Logo"
                    width={400}
                    height={300}
                    style={{ filter: "blur(10px)" }}
                    priority
                  />
                  <div className="select_image_number">
                    {numSelectedCheckboxes + "+"}
                  </div>
                </div>
              </div>
              <div
                className="gallery_details_btns"
                style={{ justifyContent: "start", gap: "12px" }}
              >
                <button className="image_preview_btn">Preview</button>
                <button className="image_delete_btn" onClick={handleShowDelete}>
                  <img
                    className={"image_delete_img"}
                    src="/build/img/Icons/Delete.png"
                    alt="zex pr wire Logo"
                    width={16}
                    height={16}
                    priority
                  />
                  Delete
                </button>
                <Modal
                  show={showDelete}
                  onHide={hadnleNotShowDelete}
                  centered
                  className="custom_modal"
                >
                  <Modal.Body>
                    <div className="submit_modal_img">
                      <img
                        className={"submit_done_img"}
                        src="/build/imageDelete.svg"
                        alt="zex pr wire Logo"
                        width={140}
                        height={140}
                        priority
                      />
                    </div>
                    <div>
                      <h5 className="submit_modal_head">Delete Image</h5>
                      <p className="submit_modal_content">
                        Are you sure you want to delete multiple images?
                      </p>
                    </div>
                    <div className="pr_modal_btn">
                      <Button
                        className="modal_gallery_btn1"
                        onClick={hadnleNotShowDelete}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="image_delete_btn"
                        onClick={hadnleNotShowDelete}
                      >
                        <img
                          className={"image_delete_img"}
                          src="/build/img/Icons/Delete.png"
                          alt="zex pr wire Logo"
                          width={16}
                          height={16}
                          priority
                        />
                        Delete
                      </Button>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            </> 
          )}*/}
        </div>
      </div>
    </>
  );
}
