import "./Pricing.css";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Instance from "../../Utils/Axios";
import error from "../../Utils/Icons/error";
import { showToast } from "../../Utils";

const PaymentMethodPage = () => {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  // const { superPNR } = location.state;

  useEffect(() => {
    const fetchPaymentOptions = () => {
      const token = localStorage.getItem("tokenId");
      const PNR = localStorage.getItem("superPNR");
      Instance.post("/pay/getOption", {
        tokenId: token,
        superPNR: PNR,
      })
        .then((response) => {
          if (response.data.resCode === "200") {
            const uniqueGateways = Object.values(
              response.data.paymentMethod.reduce((acc, method) => {
                if (!acc[method.gateway]) {
                  acc[method.gateway] = {
                    gateway: method.gateway,
                    options: [],
                  };
                }
                acc[method.gateway].options.push({
                  code: method.code,
                  label: method.label,
                  iconClass: method.iconClass,
                });
                return acc;
              }, {})
            );
            setPaymentMethods(uniqueGateways);
          } else {
            console.error(
              "Failed to fetch payment options:",
              response.data.resMessage
            );
          }
        })
        .catch((error) => {
          console.error("Error fetching payment options:", error);
        });
    };

    fetchPaymentOptions();
  }, []);

  const handlePaymentMethodChange = (gateway) => {
    setSelectedPaymentMethod(gateway);
  };

  const handlePurchase = () => {
    const token = localStorage.getItem("tokenId");
    const PNR = localStorage.getItem("superPNR");
    const selectedGateway = paymentMethods.find(
      (method) => method.gateway === selectedPaymentMethod
    );

    if (!selectedGateway) {
      showToast("Please select a payment method", "error", "error");
      return;
    }
    const selectedOption = document.querySelector(
      'input[name="paymentMethodOption"]:checked'
    )?.value;

    if (!selectedOption) {
      showToast("Please select a payment option", "error", "error");
      return;
    }
    Instance.post("/pay/paymentRequest", {
      tokenId: token,
      superPNR: PNR,
      gateway: selectedGateway.gateway,
      paymentMode: selectedOption,
    })
      .then((response) => {
        if (response.data) {
          // const newWindow = window.open();
          document.write(response.data);
          document.close();
        }
      })
      .catch((error) => {
        showToast("Payment initiation failed", "error", "error");
        console.error("Payment initiation failed", error);
      });
  };

  return (
    <>
      <div className="payment_method_layout">
        <div className="payment_steps">
          <div className="d-flex ">
            <div className="paymeny_step_img">
              <img src="/build/greycart.png" width={80} height={80} />
            </div>
            <div className="steps_detail">
              <h5>Browse Packages</h5>
              <p>Step 1</p>
            </div>
          </div>
          <div className="d-flex ">
            <div className="paymeny_step_img p-0 ">
              <img src="/build/greytruck.png" width={80} height={80} />
            </div>
            <div className="steps_detail">
              <h5>Billing Info</h5>
              <p>Step 2</p>
            </div>
          </div>

          <div className="d-flex ">
            <div className="paymeny_step_img active_step p-4">
              <img src="/build/whitecard.png" width={30} height={30} />
            </div>
            <div className="steps_detail">
              <h5>Payment Methods</h5>
              <p>Step 3</p>
            </div>
          </div>
          <div className="d-flex ">
            <div className="paymeny_step_img">
              <img src="/build/greyaward.png" width={80} height={80} />
            </div>
            <div className="steps_detail">
              <h5>Confirmation</h5>
              <p>Step 4</p>
            </div>
          </div>
        </div>
        <div className="payment_mode">
          <h5>Payment</h5>
          <p>All transactions are secure and encrypted.</p>
          {paymentMethods.map((method, index) => (
            <div className="payment_options" key={index}>
              <div className="payment_option_name">
                <input
                  type="radio"
                  checked={selectedPaymentMethod === method.gateway}
                  onChange={() => handlePaymentMethodChange(method.gateway)}
                />
                <img
                  src={
                    method.gateway === "RAZORPAY"
                      ? "/build/razorpaylogo.png"
                      : "/build/paypallogo.png"
                  }
                  width={method.gateway === "RAZORPAY" ? 100 : 100}
                  height={method.gateway === "RAZORPAY" ? 21 : 56}
                  alt={method.gateway}
                />
              </div>
              {selectedPaymentMethod === method.gateway && (
                <div className="gateway-options">
                  {method.options.map((option, optionIndex) => (
                    <div
                      key={optionIndex}
                      className="d-flex align-items-center"
                    >
                      <input
                        type="radio"
                        name="paymentMethodOption"
                        value={option.code}
                      />
                      <i
                        className={option.iconClass}
                        style={{ fontSize: "24px", marginRight: "10px" }}
                      ></i>
                      <span>{option.label}</span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
          <div className="payment_confirm_btn">
            <button
              className="payment_cancel_btn"
              onClick={() => navigate("/customer/pricing")}
            >
              Cancel
            </button>
            <button className="payment_pay_btn" onClick={handlePurchase}>
              Confirm
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentMethodPage;
