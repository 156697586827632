import Sidebar from "../../components/SidebarNew/Sidebar";
import Navbar from "../../components/NavbarProfile/NavbarProfile";
import NotificationLayout from "../../components/Notification/NotificationLayout";
import "./Notification.css";

export default function Notification() {
  const activeSide = 1;
  return (
    <>
      <div className="row gx-0 notification_center">
        <Sidebar activeSide={activeSide} />
        <div className={`main col`}>
          <Navbar name={"Notification Center"} />
          <NotificationLayout />
        </div>
      </div>
    </>
  );
}
