import PricingHeader from "../../components/Layouts/PricingHeader";
import Header from "../../components/Layouts/Header";
import Pricing from "../../components/BuyCredit/Pricing";
import BottomBar from "../../components/Layouts/BottomBar";
import PricingFooter from "../../components/Layouts/PricingFooter";

const BuyCredits = () => {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <div>
        <Header />
        <Pricing />
      </div>
      <div style={{ marginTop: "auto" }}>
        <BottomBar />
        <PricingFooter />
      </div>
    </div>
  );
};

export default BuyCredits;
