import { useState, useEffect } from "react";
import "react-calendar/dist/Calendar.css";
import Slider from "react-slider";
import {
  Table,
  Button,
  Pagination,
  Dropdown,
  Modal,
  Accordion,
  Offcanvas,
  Form,
  Row,
  Col,
} from "react-bootstrap";
import "./Payment.css";
import { DateRange, DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";

import Instance from "../../Utils/Axios";
import error from "../../Utils/Icons/error";

export default function PaymentLayout() {
  const [show, setShow] = useState(false);
  const [showView, setShowView] = useState(false);
  const [showDownload, setShowDownload] = useState(false);
  const [paymentData, setPaymentData] = useState([]);
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [selectedPaymentList, setSelectedPaymentList] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedMethod, setSelectedMethod] = useState([]);
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [methodsApplied, setMethodsApplied] = useState(0);
  const [packagesApplied, setPackagesApplied] = useState(0);
  const [filtersApplied, setFiltersApplied] = useState(0);
  const [amountRange, setAmountRange] = useState([0, 100000]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });

  const [filters, setFilters] = useState({
    packageSubType: "",
    createdOn: "",
    paymentMethod: "",
  });
  const [selectionRange, setSelectionRange] = useState({
    startDate: null,
    endDate: null,
    key: "selection",
  });

  const handleSelect = (ranges) => {
    setSelectionRange(ranges.selection);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseView = () => setShowView(false);
  const handleShowView = () => setShowView(true);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleCloseDonwload = () => setShowDownload(false);
  const handleShowDownload = () => setShowDownload(true);

  const handleMethodChange = (newMethod, isChecked) => {
    if (isChecked) {
      setSelectedMethod([newMethod]);
    } else {
      setSelectedMethod([]);
    }
  };

  const handlePackageChange = (newPackage, isChecked) => {
    if (isChecked) {
      setSelectedPackages([newPackage]);
    } else {
      setSelectedPackages([]);
    }
  };

  const handleAmountRangeChange = (value) => {
    setAmountRange(value);
  };

  const calculateFiltersApplied = () => {
    let count = 0;
    if (selectionRange) count++;
    count += selectedPackages.length;
    count += selectedMethod.length;
    if (amountRange[0] !== 0 || amountRange[1] !== 100000) count++;
    setFiltersApplied(count);
  };

  const calculatePackagesApplied = () => {
    setPackagesApplied(selectedPackages.length);
    setMethodsApplied(selectedMethod.length);
  };

  // const handleApplyFilters = () => {
  //   calculateFiltersApplied();
  //   calculatePackagesApplied();
  //   handleClose();
  // };
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const handleApplyFilters = async () => {
    calculateFiltersApplied();
    calculatePackagesApplied();
    handleClose();

    const token = localStorage.getItem("tokenId");

    const filterData = {
      tokenId: token,
      createdOn:
        selectionRange.startDate && selectionRange.endDate
          ? {
            startDate: selectionRange.startDate.toISOString().split("T")[0],
            endDate: selectionRange.endDate.toISOString().split("T")[0],
          }
          : null,
      paymentMethod: selectedMethod.length ? selectedMethod[0] : "",
      packageSubType: selectedPackages.length ? selectedPackages[0] : "",
      amountRange,
    };

    try {
      const response = await Instance.post("/user/paymentHistory", filterData);
      if (response.data.resCode === "200") {
        const filteredData = response.data.message.filter((payment) => {
          let matches = true;

          // Apply date range filter
          if (filterData.createdOn) {
            const paymentDate = payment.createdOn.split(" ")[0];
            matches =
              matches &&
              paymentDate >= filterData.createdOn.startDate &&
              paymentDate <= filterData.createdOn.endDate;
          }
          const paymentAmount = payment.amount;
          matches =
            matches &&
            paymentAmount >= amountRange[0] &&
            paymentAmount <= amountRange[1];

          return matches;
        });

        console.log("Fetched Payment Data:", filteredData);
        setPaymentData(filteredData);
      } else if (response.data.resCode === "120") {
        setPaymentData([]);
      } else {
        console.error(
          "Failed to fetch filtered payment data:",
          response.data.resMessage
        );
      }
    } catch (error) {
      console.error("Error fetching filtered payment data:", error);
    }
  };

  const handleClearFilters = async () => {
    setFiltersApplied(0);
    setSelectionRange({
      startDate: null,
      endDate: null,
      key: "selection",
    });
    setSelectedPackages([]);
    setSelectedMethod([]);
    setMethodsApplied(0);
    setPackagesApplied(0);
    setAmountRange([0, 100000]);

    fetchPaymentsData();
  };

  const fetchPaymentsData = () => {
    const token = localStorage.getItem("tokenId");
    Instance.post("/user/paymentHistory", {
      tokenId: token,
    })
      .then((response) => {
        setPaymentData(response.data.message || []);
      })
      .catch((error) => {
        console.error("error fetching the payments data", error);
      });
  };

  const fetchPaymentDetail = (id) => {
    const token = localStorage.getItem("tokenId");
    Instance.post("/user/paymentHistory", {
      tokenId: token,
      id: id,
    })
      .then((response) => {
        setPaymentDetails(response.data.message[0]);

        console.log("Payment details fetched:", response.data.message);
      })
      .catch((error) => {
        console.error("error fetching the payments data", error);
      });
  };

  useEffect(() => {
    fetchPaymentsData();
  }, []);

  useEffect(() => {
    if (paymentData.length > 0) {
      fetchPaymentDetail(paymentData[0].id);
    }
  }, [paymentData.length]);

  // const handleSelectId = (id) => {
  //   fetchPaymentDetail(id);
  //   setSelectedPaymentList(id);
  // };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleRowsPerPageChange = (rows) => {
    setRowsPerPage(rows);
    setCurrentPage(1);
  };

  const indexOfLastPayment = currentPage * rowsPerPage;
  const indexOfFirstPayment = indexOfLastPayment - rowsPerPage;
  const currentPaymentData = Array.isArray(paymentData)
    ? paymentData.slice(indexOfFirstPayment, indexOfLastPayment)
    : [];

  const totalPages = Math.ceil((paymentData?.length || 0) / rowsPerPage);

  const sortedPayment = [...paymentData].sort((a, b) => {
    if (sortConfig.key) {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
    }
    return 0;
  });

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const getSortIconClass = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "ascending" ? "rotate-180" : "rotate-0";
    }
    return "rotate-0";
  };

  const currentSortedPayment = sortedPayment.slice(
    indexOfFirstPayment,
    indexOfLastPayment
  );

  return (
    <>
      <div className="paymemt_content">
        <div className="payment_list">
          <div className="payment_filter">
            <Button
              variant="primary"
              className="Payment_filter_btn"
              onClick={handleShow}
            >
              Filter
              <img
                className="filter_img"
                src="/build/img/Icons/down_vector.svg"
                alt="zex pr wire Logo"
                width={16}
                height={16}
                priority
              />
            </Button>
            <Offcanvas show={show} onHide={handleClose}>
              <Offcanvas.Header closeButton className="filter_offcanvas_header">
                <Offcanvas.Title className="filter_offcanvas_title">
                  {filtersApplied} filters applied
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className="filter_offcanavas_body">
                <Accordion alwaysOpen flush>
                  <Accordion.Item eventKey="0" className="accordian_item">
                    <Accordion.Header className="filter_accordian">
                      Date Range
                    </Accordion.Header>
                    <Accordion.Body style={{ backgroundColor: "#FAFBFB" }}>
                      <div>
                        <DateRangePicker
                          ranges={[selectionRange]}
                          onChange={handleSelect}
                          months={1}
                          direction="horizontal"
                          value={filters.createdOn}
                        />
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1" className="accordian_item">
                    <Accordion.Header className="filter_accordian">
                      Payment methods
                    </Accordion.Header>
                    <Accordion.Body style={{ backgroundColor: "#FAFBFB" }}>
                      <Form
                        value={filters.paymentMethod}
                        onChange={handleFilterChange}
                      >
                        {["checkbox"].map((type) => (
                          <div
                            key={`inline-${type}`}
                            className="filter_select_package"
                          >
                            <Form.Check
                              label="All"
                              name="paymentMethod"
                              type={type}
                              id={`default-method-${type}`}
                              checked={selectedMethod.includes("")}
                              onChange={(e) =>
                                handleMethodChange("", e.target.checked)
                              }
                            />
                            <Form.Check
                              label="Net Banking"
                              name="paymentMethod"
                              type={type}
                              id={`default-method-${type}-2`}
                              checked={selectedMethod.includes("netbanking")}
                              onChange={(e) =>
                                handleMethodChange(
                                  "netbanking",
                                  e.target.checked
                                )
                              }
                            />
                            <Form.Check
                              label="OPTNBK"
                              name="paymentMethod"
                              type={type}
                              id={`default-method-${type}-3`}
                              checked={selectedMethod.includes("OPTNBK")}
                              onChange={(e) =>
                                handleMethodChange("OPTNBK", e.target.checked)
                              }
                            />
                            <Form.Check
                              label="Card"
                              name="paymentMethod"
                              type={type}
                              id={`default-method-${type}-4`}
                              checked={selectedMethod.includes("card")}
                              onChange={(e) =>
                                handleMethodChange("card", e.target.checked)
                              }
                            />
                          </div>
                        ))}
                      </Form>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2" className="accordian_item">
                    <Accordion.Header className="filter_accordian">
                      <span>Packages</span>
                      <span className="filter_accordian_package">
                        {packagesApplied} selected
                      </span>
                    </Accordion.Header>
                    <Accordion.Body
                      style={{ backgroundColor: "#FAFBFB" }}
                      className="filter_package_body"
                    >
                      <div className="filter_search-wrapper mb-3">
                        <input
                          type="search"
                          placeholder="Search Packages"
                          className="filter_search"
                        />
                        <img
                          className="filter_search_img"
                          src="/build/img/Icons/search.svg"
                          alt="zex pr wire Logo"
                          width={12}
                          height={12}
                          priority
                        />
                      </div>
                      <Form
                        value={filters.packageSubType}
                        onChange={handleFilterChange}
                      >
                        {["checkbox"].map((type) => (
                          <div
                            key={`inline-${type}`}
                            className="filter_select_package"
                          >
                            <Form.Check
                              label="All packages"
                              name="packageSubType"
                              type={type}
                              id={`default-${type}`}
                              checked={selectedPackages.includes("")}
                              onChange={(e) =>
                                handlePackageChange("", e.target.checked)
                              }
                            />
                            <Form.Check
                              label="Normal Package "
                              name="packageSubType"
                              type={type}
                              id={`default-${type}-2`}
                              checked={selectedPackages.includes("NORMAL")}
                              onChange={(e) =>
                                handlePackageChange("NORMAL", e.target.checked)
                              }
                            />
                            <Form.Check
                              label="Reseller Package "
                              name="packageSubType"
                              type={type}
                              id={`default-${type}-3`}
                              checked={selectedPackages.includes("RESELLER")}
                              onChange={(e) =>
                                handlePackageChange(
                                  "RESELLER",
                                  e.target.checked
                                )
                              }
                            />
                            <Form.Check
                              label="Crypto Package "
                              name="packageSubType"
                              type={type}
                              id={`default-${type}-4`}
                              checked={selectedPackages.includes("crypto")}
                              onChange={(e) =>
                                handlePackageChange("crypto", e.target.checked)
                              }
                            />
                            <Form.Check
                              label="Indian PR Package "
                              name="packageSubType"
                              type={type}
                              id={`default-${type}-5`}
                              checked={selectedPackages.includes("indianPR")}
                              onChange={(e) =>
                                handlePackageChange(
                                  "indianPR",
                                  e.target.checked
                                )
                              }
                            />
                            <Form.Check
                              label="White Label PR Package "
                              name="packageSubType"
                              type={type}
                              id={`default-${type}-6`}
                              checked={selectedPackages.includes("whitelabel")}
                              onChange={(e) =>
                                handlePackageChange(
                                  "whitelabel",
                                  e.target.checked
                                )
                              }
                            />
                          </div>
                        ))}
                      </Form>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3" className="accordian_item">
                    <Accordion.Header className="filter_accordian">
                      Amount range
                    </Accordion.Header>
                    <Accordion.Body style={{ backgroundColor: "#FAFBFB" }}>
                      <Slider
                        onChange={handleAmountRangeChange}
                        className="amount_slider mt-4"
                        value={amountRange}
                        min={0}
                        max={100000}
                      />
                      <div className={"mt-4 amount_value"}>
                        <div className="value_min">{amountRange[0]}</div>
                        <div className="value_max"> {amountRange[1]}</div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <div className="mt-4 mb-4 filter_btns">
                  <button
                    className="filter_clear_btn"
                    onClick={handleClearFilters}
                  >
                    Clear
                  </button>
                  <button
                    className="filter_apply_btn"
                    onClick={handleApplyFilters}
                  >
                    Apply
                  </button>
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          </div>
          <div className="payment_table">
            <Table hover className="mb-0">
              <thead className="payments_tables_head">
                <tr className="payment_tr">
                  <th
                    onClick={() => {
                      handleSort("packageName");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    PACKAGE NAME
                    <img
                      className={`mb-0 ${getSortIconClass("packageName")}`}
                      src="/build/img/down_arrow.svg"
                      alt="zex pr wire Logo"
                      width={26}
                      height={18}
                      priority
                    />
                  </th>
                  <th
                    onClick={() => {
                      handleSort("transactionId");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    TRANSACTION ID
                    <img
                      className={`mb-0 ${getSortIconClass("transactionId")}`}
                      src="/build/img/down_arrow.svg"
                      alt="zex pr wire Logo"
                      width={26}
                      height={18}
                      priority
                    />
                  </th>
                  <th
                    onClick={() => {
                      handleSort("createdOn");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    DATE OF PURCHASE
                    <img
                      className={`mb-0 ${getSortIconClass("createdOn")}`}
                      src="/build/img/down_arrow.svg"
                      alt="zex pr wire Logo"
                      width={26}
                      height={18}
                      priority
                    />
                  </th>
                  <th>PAYMENT METHOD</th>
                </tr>
              </thead>
              <tbody className="payments_table_body">
                {currentSortedPayment.length === 0 ? (
                  <tr>
                    <td colSpan={4} className="text-center">
                      NO DATA AVAILABLE
                    </td>
                  </tr>
                ) : (
                  currentSortedPayment.map((payment, index) => (
                    <tr
                      key={index}
                      onClick={() => {
                        // handleSelectId(payment.id);
                        fetchPaymentDetail(payment.id);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <td className="d-flex align-items-center gap-2">
                        <input
                          type="checkbox"
                          className="ticket_checkbox"
                          checked={paymentDetails.id === payment.id}
                        />
                        {payment.packageName}
                      </td>
                      <td>{payment.transactionId}</td>
                      <td>{payment.createdOn}</td>
                      <td className="payment_option">
                        <div
                          className={
                            payment.paymentMethod === "netbanking"
                              ? "method_ib"
                              : payment.paymentMethod === "OPTNBK"
                                ? "method_upi"
                                : "method_cc"
                          }
                        >
                          {payment.paymentMethod}
                        </div>
                      </td>
                    </tr>
                  ))
                )}
                {/* <tr>
                  <td>appendageducktail</td>
                  <td>ASD12344</td>
                  <td>Dec 18, 2020</td>
                  <td className="payments_option">
                    <div className="method_ib">Internet Banking</div>
                  </td>
                </tr>
                <tr>
                  <td>blotstatue</td>
                  <td>MNX12312</td>
                  <td>Dec 18, 2020</td>
                  <td className="payments_option">
                    <div className="method_cc">Credit Card</div>
                  </td>
                </tr>
                <tr>
                  <td>blockedlunation</td>
                  <td>NXC289371</td>
                  <td>Dec 18, 2020</td>
                  <td className="payments_option">
                    <div className="method_dc">Debit Card</div>
                  </td>
                </tr>
                <tr>
                  <td>digitsscarf</td>
                  <td>IOW09123</td>
                  <td>Dec 18, 2020</td>
                  <td className="payments_option">
                    <div className="method_ib">Internet Banking</div>
                  </td>
                </tr>
                <tr>
                  <td>jumblecitation</td>
                  <td>EAS982317</td>
                  <td>Dec 18, 2020</td>
                  <td className="payments_option">
                    <div className="method_upi">UPI</div>
                  </td>
                </tr>
                <tr>
                  <td>luggagecramp</td>
                  <td>RTA131123</td>
                  <td>Dec 18, 2020</td>
                  <td className="payments_option">
                    <div className="method_dc">Debit Card</div>
                  </td>
                </tr>
                <tr>
                  <td>sketchburkitt</td>
                  <td>AJS93120</td>
                  <td>Dec 18, 2020</td>
                  <td className="payments_option">
                    <div className="method_dc">Debit Card</div>
                  </td>
                </tr>
                <tr>
                  <td>dizzinessextensions</td>
                  <td>POU198628</td>
                  <td>Dec 18, 2020</td>
                  <td className="payments_option">
                    <div className="method_upi">UPI</div>
                  </td>
                </tr>
                <tr>
                  <td>detestpunchbowl</td>
                  <td>VXC192131</td>
                  <td>Dec 18, 2020</td>
                  <td className="payments_option">
                    <div className="method_cc">Credit Card</div>
                  </td>
                </tr>
                <tr>
                  <td>wiredalluvial</td>
                  <td>CJB12371</td>
                  <td>Dec 18, 2020</td>
                  <td className="payments_option">
                    <div className="method_upi">UPI</div>
                  </td>
                </tr> */}
              </tbody>
              <caption>
                <div className="payment_table_footer">
                  <div className="payment_page_no">
                    <h4>Rows per page</h4>
                    <Dropdown className="payment_dropdown">
                      <Dropdown.Toggle variant="light" id="dropdown-basic">
                        {rowsPerPage}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {[5, 10, 15, 20].map((rows) => (
                          <Dropdown.Item
                            key={rows}
                            onClick={() => handleRowsPerPageChange(rows)}
                          >
                            {rows}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="payment_pagination">
                    <Pagination className="mb-0">
                      <Pagination.First onClick={() => handlePageChange(1)} />
                      <Pagination.Prev
                        onClick={() =>
                          handlePageChange(Math.max(1, currentPage - 1))
                        }
                      />
                      {[...Array(totalPages).keys()].map((page) => (
                        <Pagination.Item
                          key={page + 1}
                          active={page + 1 === currentPage}
                          onClick={() => handlePageChange(page + 1)}
                        >
                          {page + 1}
                        </Pagination.Item>
                      ))}
                      <Pagination.Next
                        onClick={() =>
                          handlePageChange(
                            Math.min(totalPages, currentPage + 1)
                          )
                        }
                      />
                      <Pagination.Last
                        onClick={() => handlePageChange(totalPages)}
                      />
                    </Pagination>
                  </div>
                  {/* <div className="payment_pages">
                    <h4>Go to page</h4>
                    <img
                      className={"mb-0 payment_select_img"}
                      src="/build/img/Select.svg"
                      alt="zex pr wire Logo"
                      width={50}
                      height={32}
                      priority
                    />
                  </div> */}
                </div>
              </caption>
            </Table>
          </div>
        </div>
        <div className="payment_invoice">
          {currentPaymentData.length === 0 ? (
            ""
          ) : (
            <>
              <div className="invoice_details">
                <h4 className="mb-4">Invoice</h4>
                <div className="package_detail">
                  <p className="mb-4 invoice_detail_input">Package Name</p>
                  <p className="mb-4 invoice_detail_output">
                    {paymentDetails.packageName}
                  </p>
                  <p className="mb-4 invoice_detail_input"> Transaction Id</p>
                  <p className="mb-4 invoice_detail_output">
                    {" "}
                    {paymentDetails.transactionId}
                  </p>
                  <p className="mb-4 invoice_detail_input">Date of Purchase</p>
                  <p className="mb-4 invoice_detail_output">
                    {paymentDetails.createdOn}
                  </p>
                </div>
                <div className="mt-4 amount_details">
                  <p className="mb-4 invoice_detail_input"> Coupon discount</p>
                  <p className="mb-4 invoice_detail_output">
                    {" "}
                    Rs. {paymentDetails.discountValue || "0"}
                  </p>
                  <p className="mb-4 invoice_detail_input"> Payment method</p>
                  <p
                    className={` mb-4  ${paymentDetails.paymentMethod === "netbanking"
                      ? "method_ib"
                      : paymentDetails.paymentMethod === "OPTNBK"
                        ? "method_upi"
                        : "method_cc"
                      }
                      `}
                  >
                    {paymentDetails.paymentMethod}
                  </p>
                  <p className="mb-4 invoice_detail_input">Amount</p>
                  <p className="mb-4 invoice_detail_output">
                    Rs. {paymentDetails.amount}
                  </p>
                </div>
                <div className="mt-4 invoice_total">
                  <h4 className="mb-0">Total:</h4>
                  <p className="mb-0">
                    Rs. {paymentDetails.amount - paymentDetails.discountValue}
                  </p>
                </div>
              </div>
              <div className="invoice_btns">
                <button className="view_invoice" onClick={handleShowView}>
                  View
                </button>
                <Modal
                  show={showView}
                  onHide={handleCloseView}
                  centered
                // className="custom_modal"
                >
                  <Modal.Body>
                    <Row>
                      <Col>
                        <Form.Label>Package Name</Form.Label>
                        <Form.Control
                          name="packageName"
                          value={paymentDetails.packageName}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Label>Transaction Id</Form.Label>
                        <Form.Control
                          name="transactionId"
                          value={paymentDetails.transactionId}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="6">
                        <Form.Label>Date Of Purchase</Form.Label>
                        <Form.Control
                          name="createdOn"
                          value={paymentDetails.createdOn}
                        />
                      </Col>
                      <Col xs="6">
                        <Form.Label>Payment Method</Form.Label>
                        <Form.Control
                          name="paymentMethod"
                          value={paymentDetails.paymentMethod}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="6">
                        <Form.Label>Coupon Code</Form.Label>
                        <Form.Control
                          name="couponCode"
                          value={paymentDetails.couponCode}
                        />
                      </Col>
                      <Col xs="6">
                        <Form.Label>Coupon Discount</Form.Label>
                        <Form.Control
                          name="discountValue"
                          value={`Rs. ${paymentDetails.discountValue || 0}`}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="6">
                        <Form.Label>Payable Amount</Form.Label>
                        <Form.Control
                          name="amount"
                          value={`Rs. ${paymentDetails.amount || 0} `}
                        />
                      </Col>
                      <Col xs="6">
                        <Form.Label>Total Amount</Form.Label>
                        <Form.Control
                          name="total"
                          value={`Rs. ${(paymentDetails.amount || 0) -
                            (paymentDetails.discountValue || 0)
                            }`}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="6">
                        <Form.Label>Package Validity</Form.Label>
                        <Form.Control
                          name="amount"
                          value={paymentDetails.packageValidity}
                        />
                      </Col>
                      <Col xs="6">
                        <Form.Label>Package Start Date</Form.Label>
                        <Form.Control
                          name="total"
                          value={paymentDetails.start_date}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="6">
                        <Form.Label>Package End Date</Form.Label>
                        <Form.Control
                          name="amount"
                          value={paymentDetails.endDate}
                        />
                      </Col>
                    </Row>
                    <div className="pr_modal_btn">
                      <Button
                        className="view_invoice"
                        onClick={handleCloseView}
                      >
                        Close
                      </Button>
                    </div>
                  </Modal.Body>
                </Modal>
                <button
                  className="download_invoice"
                  onClick={handleShowDownload}
                >
                  Download
                </button>
                <Modal
                  show={showDownload}
                  onHide={handleCloseDonwload}
                  centered
                  className="custom_modal"
                >
                  <Modal.Body>
                    <div>
                      <img
                        className="payment_invoice_img"
                        src="/build/bill.png"
                        alt="zex pr wire Logo"
                        width={480}
                        height={480}
                        priority
                      />
                    </div>
                    <div className="pr_modal_btn">
                      <Button
                        className="modal_cancel_btn"
                        variant="secondary"
                        onClick={handleCloseDonwload}
                      >
                        Close
                      </Button>
                      <Button
                        className="modal_save_btn"
                        variant="primary"
                        onClick={handleCloseDonwload}
                      >
                        Download
                      </Button>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
