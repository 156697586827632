import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Container from "react-bootstrap/Container";
import "./Pricing.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Instance from "../../Utils/Axios";
import error from "../../Utils/Icons/error";

const PricingHeader = () => {
  const [profile, setProfile] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfileData = () => {
      const token = localStorage.getItem("tokenId");
      Instance.post("/user/profile", {
        tokenId: token,
      })
        .then((response) => {
          setProfile(response.data.userProfile);
        })
        .catch((error) => {
          console.error("error fetching profile data", error);
        });
    };
    fetchProfileData();
  }, []);
  return (
    <>
      <Navbar className="pricing_header" expand="lg">
        <Container className="pricing_nav_container">
          <div className="my-2">
            <img
              className="logo"
              src="/build/img/imglogowhite.svg"
              alt="zex pr wire Logo"
              width={147}
              height={67}
              onClick={() => {
                navigate("/customer/newsroom");
              }}
              style={{ cursor: "pointer" }}
            />
          </div>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="pricing_nav_options"
          >
            <Nav className="pricing_nav_links">
              <Nav.Link href="/customer/newsroom">Newsroom</Nav.Link>
              <Nav.Link href="/customer/pricing">Pricing</Nav.Link>
              <Nav.Link href="/customer/support">Support</Nav.Link>
              <Nav.Link href="#home">Contact Us</Nav.Link>
              <Nav.Link href="/customer/pricing" className="pricing_nav_btn">
                Submit PR
              </Nav.Link>
              <Nav.Link href="/customer/dashboard">
                {profile.firstName} {profile.lastName}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <ToastContainer theme="colored" />
    </>
  );
};

export default PricingHeader;
