import { useState, useEffect } from "react";
import "./PRmanager.css";
import Instance from "../../Utils/Axios";
import error from "../../Utils/Icons/error";
import { Link, useLocation } from "react-router-dom";

const Cards = () => {
  const [cardData, setCardData] = useState([]);
  const location = useLocation();

  useEffect(() => {
    const fetchCardsDetail = () => {
      const token = localStorage.getItem("tokenId");
      Instance.post("/user/pressrelease/list", {
        tokenId: token,
      })
        .then((response) => {
          setCardData(response.data.stats);
          console.log("check stats", response.data.stats);
        })
        .catch((error) => {
          console.error("failed to fetch card details", error);
        });
    };
    fetchCardsDetail();
  }, []);

  const getCardClass = (path) => {
    return location.pathname === path ? "pr_card active_card" : "pr_card";
  };
  return (
    <>
      <div className="pr_menu">
        <Link to="/customer/pr-manager">
          <div className={getCardClass("/customer/pr-manager")}>
            <img
              className={"pr_img mb-0"}
              src="/build/Newspaper.svg"
              alt="zex pr wire Logo"
              width={48}
              height={48}
              priority
            />
            <p className="pr_num ">{cardData?.All || 0}</p>
            <p className="pr_text mb-0">All PR</p>
          </div>
        </Link>
        <Link to="/customer/pr-manager/publish">
          <div className={getCardClass("/customer/pr-manager/publish")}>
            <img
              className={"pr_img mb-0"}
              src="/build/Package.svg"
              alt="zex pr wire Logo"
              width={48}
              height={48}
              priority
            />
            <p className="pr_num ">{cardData?.PUBLISHED?.Published || 0}</p>
            <p className="pr_text mb-0">Published</p>
          </div>
        </Link>
        <Link to="/customer/pr-manager/pending">
          <div className={getCardClass("/customer/pr-manager/pending")}>
            <img
              className={"pr_img mb-0"}
              src="/build/Pending.svg"
              alt="zex pr wire Logo"
              width={48}
              height={48}
              priority
            />
            <p className="pr_num ">{cardData?.PENDING?.Pending || 0}</p>
            <p className="pr_text mb-0">Pending</p>
          </div>
        </Link>
        <Link to="/customer/pr-manager/action">
          <div className={getCardClass("/customer/pr-manager/action")}>
            <img
              className={"pr_img mb-0"}
              src="/build/Rejected.svg"
              alt="zex pr wire Logo"
              width={48}
              height={48}
              priority
            />
            <p className="pr_num ">
              {cardData?.OPEN?.["Action Required"] || 0}
            </p>
            <p className="pr_text mb-0">Action Required</p>
          </div>
        </Link>
        <Link to="/customer/pr-manager/draft">
          <div className={getCardClass("/customer/pr-manager/draft")}>
            <img
              className={"pr_img mb-0"}
              src="/build/Drafts.svg"
              alt="zex pr wire Logo"
              width={48}
              height={48}
              priority
            />
            <p className="pr_num ">{cardData?.DRAFT?.Drafts || 0}</p>
            <p className="pr_text mb-0">Drafts</p>
          </div>
        </Link>
        <Link to="/customer/pr-manager/reject">
          <div className={getCardClass("/customer/pr-manager/reject")}>
            <img
              className={"pr_img mb-0"}
              src="/build/Rejected.svg"
              alt="zex pr wire Logo"
              width={48}
              height={48}
              priority
            />
            <p className="pr_num ">{cardData?.REJECT?.Rejected || 0}</p>
            <p className="pr_text mb-0">Rejected</p>
          </div>
        </Link>
        <Link to="/customer/pr-manager/report">
          <div className={getCardClass("/customer/pr-manager/report")}>
            <img
              className={"pr_img mb-0"}
              src="/build/Reports.svg"
              alt="zex pr wire Logo"
              width={48}
              height={48}
              priority
            />
            <p className="pr_num ">{cardData?.REPORT?.Reports || 0}</p>
            <p className="pr_text mb-0">Reports</p>
          </div>
        </Link>
      </div>
    </>
  );
};

export default Cards;
